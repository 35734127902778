@charset "UTF-8";
@keyframes -fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes -fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes -blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes -blink-double {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.x0y0 {
  position: absolute;
  left: 0;
  top: 0;
}

.unset-input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
}

#DevRulers #x {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  width: 100vw;
  height: 2px;
  background: red;
  z-index: 9999999;
}
#DevRulers #y {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: 2px;
  height: 100vh;
  background: blue;
  z-index: 9999999;
}
#DevRulers #custom {
  border-left: 1px solid limegreen;
  border-right: 1px solid limegreen;
}
#DevRulers #drag-me {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0.5rem;
  top: 0;
  height: 100vh;
  width: 22px;
}
#DevRulers #drag-me .rule {
  height: 100%;
  width: 2px;
  background: yellow;
}

.cursor-default {
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

body {
  margin: 0;
}

img, svg {
  display: block;
}

.inline-block {
  display: inline-block;
}

.posrel {
  position: relative;
}

.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

[class^="--f"] {
  display: flex;
  flex-direction: column;
}
[class^="--f"][class*="--f-a"] {
  align-items: center;
}
[class^="--f"][class*="--f-j"] {
  justify-content: center;
}
[class^="--f"][class*="--f-j"][class*=-j-sb] {
  justify-content: space-between;
}
[class^="--f"][class*="--f-ja"] {
  justify-content: center;
  align-items: center;
}

[class^=-f-] {
  display: flex;
}
[class^=-f-][class*=-f-a] {
  align-items: center;
}
[class^=-f-][class*=-f-j] {
  justify-content: center;
}
[class^=-f-][class*=-f-j][class*=-j-sb] {
  justify-content: space-between;
}
[class^=-f-][class*=-f-ja] {
  justify-content: center;
  align-items: center;
}

[class*=-m-] {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
[class*=-m-] .back {
  width: 100%;
  height: 100%;
}
[class*=-m-] .back.dark {
  background: rgba(0, 0, 0, 0.2);
}

[class*=-p-] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
[class*=-p-][class*=-p-block] {
  width: 100%;
  height: 100%;
  z-index: 1;
}
[class*=-p-][class*=-p-back] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.inherit-w {
  width: inherit;
}

.black {
  color: black !important;
}

.m0 {
  margin: 0;
}

.mt0, .firstelm-mt0 {
  margin-top: 0;
}

.mt0IMP {
  margin-top: 0 !important;
}

.mb0, .lastelm-mb0 {
  margin-bottom: 0;
}

.mtb0, .nomarj {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  display: contents;
}

.hyperlink, .hlink, .h-link {
  font-weight: normal;
  color: blue !important;
  cursor: pointer;
}
.hyperlink:hover, .hlink:hover, .h-link:hover {
  text-decoration: underline;
}

.rlink {
  cursor: pointer;
}
.rlink:hover {
  text-decoration: underline;
}

.txt-u, .underline {
  text-decoration: underline;
}

.txt-i, .italic {
  font-style: italic;
}

.txt-b, .bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.txt-u {
  text-decoration: underline;
}

.pre {
  white-space: pre;
}

.nowrap {
  white-space: nowrap;
}

[class*=ps-]:hover::after {
  visibility: visible;
}
[class*=ps-]::after {
  visibility: hidden;
  width: -moz-max-content;
  width: max-content;
  white-space: nowrap;
  z-index: 999;
}
[class*=ps-][class*=-static]::after {
  border-radius: 4px !important;
  transition: none !important;
}
[class*=ps-][class*=ps-left]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 1.5rem);
  transition: right 0.3s ease-out;
}
[class*=ps-][class*=ps-left]:hover::after {
  right: calc(100% + 1rem);
}
[class*=ps-][class*=ps-right]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 1.5rem);
  transition: left 0.3s ease-out;
}
[class*=ps-][class*=ps-right]:hover::after {
  left: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top]:hover::after {
  bottom: calc(100% + 1rem);
}
[class*=ps-][class*=ps-bottom]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom]:hover::after {
  top: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top-mini]:hover::after {
  bottom: calc(100% + 0.5rem);
}
[class*=ps-][class*=ps-bottom-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom-mini]:hover::after {
  top: calc(100% + 0.5rem);
}

[class*=pseudo-]:hover .pseudo {
  visibility: visible;
}
[class*=pseudo-][class*=pseudo-top]:hover .pseudo {
  bottom: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-top] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-top-mini]:hover .pseudo {
  bottom: calc(100% + 0.5rem);
}
[class*=pseudo-][class*=pseudo-top-mini] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-bottom]:hover .pseudo {
  top: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-bottom] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=pseudo-] .pseudo {
  visibility: hidden;
  white-space: nowrap;
}

.ps-opacity:hover .ps-elem {
  opacity: 1;
}
.ps-opacity .ps-elem {
  opacity: 0;
}

.no-overflow {
  overflow: hidden;
}

.devices-no-tap, .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}
@keyframes bzz-login {
  0% {
    left: -3px;
  }
  50% {
    left: 3px;
  }
  100% {
    left: 0;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pop-up {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
@keyframes spin-y {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
.color-login {
  color: ghostwhite;
}

.bigger {
  font-size: 17px;
}

p {
  color: color-mix(in srgb, white 66%, #1e2a43);
}

#intro p {
  font-family: pixel;
  font-size: 3.5rem;
  font-weight: bold;
  color: color-mix(in srgb, white 66%, #1e2a43);
  margin: 0;
}
#intro p.input {
  position: absolute;
  left: 0;
  top: 0;
}
#intro p.shadow {
  visibility: hidden;
}

.Login, #Join {
  background: #171717;
  overflow: hidden;
}

#Join[progress=_3] .-tick svg {
  height: 3rem;
  color: palegreen;
}
#Join .panel {
  display: flex;
  flex-direction: column;
}
#Join .panel .click-me {
  position: absolute;
  left: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
}
#Join label, #Join .label {
  color: grey;
}
#Join .label {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}
#Join .label#input-image {
  margin-bottom: 2rem;
}
#Join #profileImage {
  width: 166px;
  height: 166px;
  margin: 1rem auto 4rem auto;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
#Join #profileImage:hover {
  opacity: 0.8;
}
#Join .-input {
  position: relative;
}
#Join .-input.incorrect input {
  color: red !important;
}
#Join .-input img.flag {
  width: 1.4rem;
  height: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
}
#Join .-input span.flag {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1rem;
  min-height: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  line-height: 1em;
}
#Join .-input span.flag::before {
  content: " ";
}
#Join .-input .err {
  font-size: 14px;
  margin: 0;
  color: red;
}
#Join .-input .shadow {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 0 10px 12px;
  z-index: -1;
}
#Join .-input .shadow p#p-shadow {
  visibility: hidden;
  font-family: "Segoe UI";
  font-size: 20px;
  margin: 0;
}
#Join .-input .shadow .-check {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 2px));
  left: calc(100% + 8px);
}
#Join .-input .shadow .-check .-spinner svg {
  height: 12px;
  fill: lightgrey;
  animation: spin 0.66s linear infinite;
}
#Join .-input .shadow .-check .-tick.tick-fill svg {
  height: 10px;
  fill: palegreen;
}
#Join .-input .shadow .-check .-tick.tick-stroke svg {
  height: 18px;
  color: palegreen;
}
#Join .-input .shadow .-check p#unavailable {
  font-size: 14px;
  margin: 0;
  color: salmon;
  white-space: nowrap;
}
#Join input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  font-family: "Segoe UI";
  font-size: 1rem;
  display: block;
  box-sizing: border-box;
  width: 100%;
  color: lightgrey;
}
#Join input:not([type=file]) {
  font-size: 20px;
  padding: 10px;
}
#Join .button {
  box-sizing: border-box;
  font-family: Consolas;
  font-size: 18px;
  color: #171717;
  background: salmon;
  padding: 12px 0;
  margin-top: auto;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

@media (orientation: landscape) {
  #logo-svg {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    height: 1.5rem;
    fill: rgba(255, 255, 255, 0.34);
    z-index: 9;
  }
  #signIn {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-family: Consolas;
    width: -moz-fit-content;
    width: fit-content;
    color: rgba(255, 255, 255, 0.34);
    z-index: 9;
    cursor: pointer;
  }
  #signIn:hover {
    opacity: 0.8;
  }
  .-spinner.loggingIn svg {
    fill: palegreen;
  }
  .-spinner svg {
    height: 1rem;
    fill: ghostwhite;
    animation: spin 0.66s linear infinite;
  }
  .Login, #Join {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  .Login #-login.awaiting span.usr {
    opacity: 0.5;
  }
  .Login #-login.incorrect span.usr, .Login #-login.incorrect input {
    color: red;
    font-weight: bold;
    animation: bzz-login 0.2s linear forwards;
  }
  .Login #-login.incorrect #cursor {
    background: red;
  }
  .Login #-login .-spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 8px);
  }
  .Login p {
    max-width: 500px;
    height: 22.4px;
    font-family: Fira Code;
    font-size: 19px;
    margin: 0;
    color: color-mix(in srgb, white 66%, #1e2a43);
    white-space: pre;
  }
  .Login p span {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
  }
  .Login p span.sys {
    color: ghostwhite;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  .Login p span.usr {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  .Login p span.rlink {
    -webkit-user-select: unset !important;
       -moz-user-select: unset !important;
            user-select: unset !important;
    color: ghostwhite;
    cursor: pointer;
  }
  .Login p span.rlink:hover {
    text-decoration: underline;
  }
  .Login p span.new-line {
    display: block;
    height: 22.4px;
  }
  .Login #cursor {
    position: absolute;
    width: 10.45px;
    height: 22.4px;
    background: ghostwhite;
    top: 0;
  }
  .Login input {
    border: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    background: none;
    position: relative;
    font-size: 19px;
    color: color-mix(in srgb, white 66%, #1e2a43);
  }
  .Login input::-moz-placeholder {
    color: ghostwhite;
  }
  .Login input::placeholder {
    color: ghostwhite;
  }
  #Join .panel {
    width: 350px;
    height: 466px;
    padding: 2rem;
    border-radius: 2rem;
    background: rgba(128, 128, 128, 0.1);
  }
  #Join .panel #entryMethod {
    margin: 1em auto 3rem auto;
  }
  #Join .panel #entryMethod p {
    margin: 0;
    padding: 8px;
    border-bottom: 3px solid transparent;
    color: grey;
    cursor: pointer;
  }
  #Join .panel #entryMethod p.selected {
    color: dodgerblue;
    border-color: dodgerblue;
  }
  #Join .panel p.progressHandle {
    margin: 0;
    padding: 12px;
    color: dodgerblue;
    text-align: center;
    cursor: pointer;
  }
  #Join .panel p.progressHandle#fin {
    margin-top: auto;
  }
  #Join .panel .sign-in-api {
    border-radius: 999px;
    overflow: hidden;
    cursor: pointer;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: #f2f2f2;
    background-image: none;
    border: none;
    border-radius: 20px;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: "Roboto", arial, sans-serif;
    font-size: 14px;
    width: 100%;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: -moz-min-content;
    min-width: min-content;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button .gsi-material-button-content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button .gsi-material-button-contents {
    flex-grow: 1;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button .gsi-material-button-state {
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:disabled {
    cursor: default;
    background-color: rgba(255, 255, 255, 0.3803921569);
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:disabled .gsi-material-button-state {
    background-color: rgba(31, 31, 31, 0.1215686275);
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:not(:disabled):active .gsi-material-button-state,
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #001d35;
    opacity: 12%;
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:not(:disabled):hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }
  #Join .panel .sign-in-api.Google.html-button .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #001d35;
    opacity: 8%;
  }
  #Join .panel .sign-in-api.Google div {
    margin: 0;
  }
  #Join .panel .sign-in-api.Google .nsm7Bb-HzV7m-LgbsSe {
    border-color: white;
  }
  #Join .panel .sign-in-api.Facebook {
    height: 40px;
    margin-top: 1rem;
    padding: 0 12px;
    background: #3578e5;
  }
  #Join .panel .sign-in-api.Facebook .-icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
  }
  #Join .panel .sign-in-api.Facebook .-icon img, #Join .panel .sign-in-api.Facebook .-icon svg {
    height: 20px;
  }
  #Join .panel .sign-in-api.Facebook p {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0 auto;
  }
  #Join .panel .sign-in-email {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.34);
  }
  #Join .panel .sign-in-email input {
    border: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    background: none;
    height: 50px;
    font-size: 1rem;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.0509803922);
    border-radius: 4px;
  }
  #Join .panel .sign-in-email input.error {
    color: red;
  }
  #Join .panel .sign-in-email input::-ms-reveal {
    filter: invert(100%);
  }
  #Join .panel .sign-in-email .sub {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 14px;
    color: red;
    margin: 0.5em 0;
  }
  #Join .panel .sign-in-email .sub.forgot {
    color: dodgerblue;
    left: unset;
    right: 0;
  }
  #Join .panel #wrapper-sign-in-up {
    margin: 1rem auto 0 auto;
  }
  #Join .panel #wrapper-sign-in-up .progressHandle#sign-in-up {
    width: -moz-fit-content;
    width: fit-content;
  }
  #Join .panel #wrapper-sign-in-up .-spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(calc(-50% + 1px));
    left: 100%;
  }
  #Join .panel #wrapper-sign-in-up .-spinner svg {
    height: 15px;
  }
  #Join .panel .-input input:not([type=file]), #Join .panel .-input .shadow {
    padding: 10px 0;
  }
  #Join .panel .-input .drop-menu {
    position: absolute;
    left: 0;
    top: 100%;
    box-sizing: border-box;
    height: 150px;
    min-width: 100%;
    border-radius: 8px;
    background: #171717;
    overflow: hidden scroll;
  }
  #Join .panel .-input .drop-menu::-webkit-scrollbar {
    display: none;
  }
  #Join .panel .-input .drop-menu p {
    padding: 1em;
    margin: 0;
    color: grey;
    white-space: nowrap;
  }
  #Join .panel .-input .drop-menu p.menu-item {
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
  }
  #Join .panel .-input .drop-menu p.menu-item:hover, #Join .panel .-input .drop-menu p.menu-item.highlight {
    border-color: salmon;
  }
  #Join .panel .-input .drop-menu p.menu-item span {
    opacity: 0.5;
  }
}
@media (orientation: portrait) {
  .Login, #Join {
    background: #171717;
  }
  .Login p.logging-in-status {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem;
    margin: 0;
    color: color-mix(in srgb, white 66%, #1e2a43);
    white-space: nowrap;
  }
  .Login p.logging-in-status span.dots {
    left: 100%;
  }
  .Login p.logging-in-status .Spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 8px);
    height: 1rem;
    fill: color-mix(in srgb, white 66%, #1e2a43);
    animation: spin-y 1s linear infinite;
  }
  .Login #KEY #-keySVGmacro {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .Login #KEY #-keySVGmacro .Spinner {
    margin-top: 2px;
  }
  .Login #KEY #-keySVGmacro #keySVGmacro {
    height: 3rem;
    fill: color-mix(in srgb, white 66%, #1e2a43);
  }
  .Login #PIN {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .Login #PIN p {
    font-family: Fira Code;
  }
  .Login #PIN #enter-PIN {
    font-size: 1.5rem;
    margin: auto;
    color: color-mix(in srgb, white 66%, #1e2a43);
  }
  .Login #PIN .-user-PIN {
    position: relative;
    margin: auto auto 15vw auto;
  }
  .Login #PIN .-user-PIN .user-PIN {
    position: relative;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 7vw;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    overflow: hidden;
  }
  .Login #PIN .-user-PIN .user-PIN.login-false {
    animation: bzz-login 0.2s linear forwards;
  }
  .Login #PIN .-user-PIN .user-PIN.login-false p {
    color: red;
  }
  .Login #PIN .-user-PIN .user-PIN p {
    position: relative;
    font-family: Consolas;
    font-size: 7vw;
    font-weight: bold;
    margin: 0;
    color: color-mix(in srgb, white 66%, #1e2a43);
  }
  .Login #PIN .-user-PIN .user-PIN p.placeholder {
    color: var(--dim);
    opacity: 0.5;
  }
  .Login #PIN .-user-PIN .user-PIN p.animate {
    animation: pop-up 0.2s ease forwards;
  }
  .Login #PIN .dial-pad {
    margin-bottom: 10vw;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding: 0 1rem;
  }
  .Login #PIN .dial-pad .pressed {
    animation: blink 0.1s steps(1);
  }
  .Login #PIN .dial-pad .button {
    position: relative;
  }
  .Login #PIN .dial-pad .button::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform: translate(-50%, -55%);
    content: "";
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: color-mix(in srgb, white 66%, #1e2a43);
    z-index: -1;
  }
  .Login #PIN .dial-pad .-num {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    aspect-ratio: 1.3;
    cursor: pointer;
    z-index: 1;
  }
  .Login #PIN .dial-pad .-num p.dig {
    font-family: Consolas;
    font-size: 2.75rem;
    margin: 0;
    transform: translateY(4px);
    color: #171717;
  }
  .Login #PIN .dial-pad .-num p.dig span.alpha {
    top: 100%;
    font-size: 17px;
    margin: 0;
    letter-spacing: 2px;
    opacity: 0.5;
  }
  .Login #PIN .dial-pad #del img {
    transform: translate(2px, -3px);
    height: 1.75rem;
  }
}/*# sourceMappingURL=login.css.map */