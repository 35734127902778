@charset "UTF-8";
@keyframes -fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes -fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes -blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes -blink-double {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.x0y0 {
  position: absolute;
  left: 0;
  top: 0;
}

.unset-input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
}

#DevRulers #x {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  width: 100vw;
  height: 2px;
  background: red;
  z-index: 9999999;
}
#DevRulers #y {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: 2px;
  height: 100vh;
  background: blue;
  z-index: 9999999;
}
#DevRulers #custom {
  border-left: 1px solid limegreen;
  border-right: 1px solid limegreen;
}
#DevRulers #drag-me {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0.5rem;
  top: 0;
  height: 100vh;
  width: 22px;
}
#DevRulers #drag-me .rule {
  height: 100%;
  width: 2px;
  background: yellow;
}

.cursor-default {
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

body {
  margin: 0;
}

img, svg {
  display: block;
}

.inline-block {
  display: inline-block;
}

.posrel {
  position: relative;
}

.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

[class^="--f"] {
  display: flex;
  flex-direction: column;
}
[class^="--f"][class*="--f-a"] {
  align-items: center;
}
[class^="--f"][class*="--f-j"] {
  justify-content: center;
}
[class^="--f"][class*="--f-j"][class*=-j-sb] {
  justify-content: space-between;
}
[class^="--f"][class*="--f-ja"] {
  justify-content: center;
  align-items: center;
}

[class^=-f-] {
  display: flex;
}
[class^=-f-][class*=-f-a] {
  align-items: center;
}
[class^=-f-][class*=-f-j] {
  justify-content: center;
}
[class^=-f-][class*=-f-j][class*=-j-sb] {
  justify-content: space-between;
}
[class^=-f-][class*=-f-ja] {
  justify-content: center;
  align-items: center;
}

[class*=-m-] {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
[class*=-m-] .back {
  width: 100%;
  height: 100%;
}
[class*=-m-] .back.dark {
  background: rgba(0, 0, 0, 0.2);
}

[class*=-p-] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
[class*=-p-][class*=-p-block] {
  width: 100%;
  height: 100%;
  z-index: 1;
}
[class*=-p-][class*=-p-back] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.inherit-w {
  width: inherit;
}

.black {
  color: black !important;
}

.m0 {
  margin: 0;
}

.mt0, .firstelm-mt0 {
  margin-top: 0;
}

.mt0IMP {
  margin-top: 0 !important;
}

.mb0, .lastelm-mb0 {
  margin-bottom: 0;
}

.mtb0, .nomarj {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  display: contents;
}

.hyperlink, .hlink, .h-link {
  font-weight: normal;
  color: blue !important;
  cursor: pointer;
}
.hyperlink:hover, .hlink:hover, .h-link:hover {
  text-decoration: underline;
}

.rlink {
  cursor: pointer;
}
.rlink:hover {
  text-decoration: underline;
}

.txt-u, .underline {
  text-decoration: underline;
}

.txt-i, .italic {
  font-style: italic;
}

.txt-b, .bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.txt-u {
  text-decoration: underline;
}

.pre {
  white-space: pre;
}

.nowrap {
  white-space: nowrap;
}

[class*=ps-]:hover::after {
  visibility: visible;
}
[class*=ps-]::after {
  visibility: hidden;
  width: -moz-max-content;
  width: max-content;
  white-space: nowrap;
  z-index: 999;
}
[class*=ps-][class*=-static]::after {
  border-radius: 4px !important;
  transition: none !important;
}
[class*=ps-][class*=ps-left]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 1.5rem);
  transition: right 0.3s ease-out;
}
[class*=ps-][class*=ps-left]:hover::after {
  right: calc(100% + 1rem);
}
[class*=ps-][class*=ps-right]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 1.5rem);
  transition: left 0.3s ease-out;
}
[class*=ps-][class*=ps-right]:hover::after {
  left: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top]:hover::after {
  bottom: calc(100% + 1rem);
}
[class*=ps-][class*=ps-bottom]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom]:hover::after {
  top: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top-mini]:hover::after {
  bottom: calc(100% + 0.5rem);
}
[class*=ps-][class*=ps-bottom-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom-mini]:hover::after {
  top: calc(100% + 0.5rem);
}

[class*=pseudo-]:hover .pseudo {
  visibility: visible;
}
[class*=pseudo-][class*=pseudo-top]:hover .pseudo {
  bottom: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-top] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-top-mini]:hover .pseudo {
  bottom: calc(100% + 0.5rem);
}
[class*=pseudo-][class*=pseudo-top-mini] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-bottom]:hover .pseudo {
  top: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-bottom] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=pseudo-] .pseudo {
  visibility: hidden;
  white-space: nowrap;
}

.ps-opacity:hover .ps-elem {
  opacity: 1;
}
.ps-opacity .ps-elem {
  opacity: 0;
}

.no-overflow {
  overflow: hidden;
}

.devices-no-tap, .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: Segoe UI;
  src: url("./font/Segoe UI/Segoe UI.woff");
}
@font-face {
  font-family: Segoe UI Bold;
  src: url("./font/Segoe UI/Segoe UI Bold.woff");
}
@font-face {
  font-family: Segoe UI Bold Italic;
  src: url("./font/Segoe UI/Segoe UI Bold Italic.woff");
}
@font-face {
  font-family: Segoe UI Semibold;
  src: url("./font/Segoe UI VF/Segoe-UI-Variable-Static-Text-Semibold.ttf");
}
@keyframes vanish {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ladder-img-ascend {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
@keyframes ladder-ladder-ascend {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes bzz {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes unset-overflow {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
.overflow-y {
  overflow-y: scroll;
}
.overflow-y::-webkit-scrollbar {
  display: none;
}

#canvas #centre-stage p {
  font-size: 1rem;
}
#canvas #centre-stage p span.flag {
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  line-height: 1em;
  width: 1.4rem;
  height: 1em;
}
#canvas #centre-stage p span.flag::before {
  content: " ";
}

#deployment-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  z-index: 999;
  animation: vanish 2s linear forwards;
}

.ph {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  mix-blend-mode: overlay;
  opacity: 0.5;
}
.ph.--small {
  font-size: 1rem;
  mix-blend-mode: unset;
}

.ph05 {
  font-family: Segoe UI;
  font-size: 0.5rem;
  margin: 0;
  color: rgba(128, 128, 128, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-style: italic;
}

.ph1 {
  font-family: Segoe UI;
  font-size: 1rem;
  margin: 0;
  color: rgba(128, 128, 128, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.ph2 {
  font-family: Segoe UI;
  font-size: 2rem;
  margin: 0;
  color: rgba(128, 128, 128, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

[class^=dummy] {
  border-radius: 4px;
}
[class^=dummy][class*=dummy-line] {
  background: rgba(255, 255, 255, 0.2);
}
[class^=dummy][class*=dummy-line]:hover {
  opacity: 0.66;
}

.dummy-gradient {
  background: linear-gradient(to right, blue, palegreen);
}

.dummy-gradient-half-L {
  background: linear-gradient(to right, blue, #4c7ecc);
}

.dummy-gradient-half-R {
  background: linear-gradient(to right, #4c7ecc, palegreen);
}

.dummy-block {
  background: palegreen;
}

.color {
  color: var(--background);
}

.background {
  background: var(--background);
}

.background-inherit {
  background: inherit;
}

.background-notice {
  background: #d6d600;
}

.background-semi {
  background: rgba(247, 247, 247, 0.97);
}

.dim {
  color: var(--dim) !important;
}

.dim-bg {
  background: var(--dim);
}

.-squad-stroke svg {
  fill: none;
  color: gold;
}

.soft {
  color: grey;
}

.h1 {
  font-family: Segoe UI Semibold;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: grey;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.h2 {
  font-family: Segoe UI Semibold;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: grey;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.t1 {
  font-family: Segoe UI Semibold;
  font-size: 2rem;
  color: grey;
}

.t2 {
  font-family: Segoe UI Semibold;
  font-size: 22px;
  color: grey;
}

.smaller {
  font-size: 15px;
}

.smallest {
  font-size: 14px;
}

.biggest {
  font-size: 18px;
}

.semibold {
  font-family: Segoe UI Semibold;
}

.bold {
  font-family: Segoe UI Bold;
}

.black-light {
  background: rgba(0, 0, 0, 0.5);
}
.black-light:hover {
  background: rgba(0, 0, 0, 0.7) !important;
}

.inline-half {
  margin-left: 4px;
}

.inline {
  margin-left: 0.5rem;
}

.inline-1 {
  margin-left: 1rem;
}

.inline-2 {
  margin-left: 2rem;
}

.inline-3 {
  margin-left: 3rem;
}

.low-light {
  background: var(--low-light);
}

.push {
  margin-right: 0.5rem;
}

.push-half {
  margin-right: 4px;
}

.push-1 {
  margin-right: 1rem;
}

.stack-half {
  margin-top: 8px;
}

.stack {
  margin-top: 1rem;
}

.stack-2 {
  margin-top: 2rem;
}

.margin-0 {
  margin: 0;
}

.button {
  padding: 8px;
  border-radius: 999px;
  text-align: center;
  cursor: pointer;
}

.clear-toolbars {
  margin-top: 104px !important;
}

.clear-toolbars-title {
  margin-top: 112px !important;
}

.click-me {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
}
.click-me.ui-handle:hover svg {
  color: whitesmoke !important;
}
.click-me:hover {
  background: var(--low-light);
}
.click-me:hover {
  background: var(--low-light);
}

.click-me-after {
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.click-me-after::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  display: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--low-light);
  z-index: -1;
}
.click-me-after:hover::after {
  display: block;
}

.-hide-display:hover .hide {
  display: block;
}
.-hide-display .hide {
  display: none;
}

.-hide-display-flex:hover .hide {
  display: flex;
}
.-hide-display-flex .hide {
  display: none;
}

.-soft-reveal:hover .soft-reveal {
  transition: opacity 0.3s;
  opacity: 1;
}
.-soft-reveal .soft-reveal {
  opacity: 0;
}

p {
  color: var(--default-p);
}

p.squad-id, span.squad-id {
  font-family: "Fira Code";
}

textarea {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  width: -webkit-fill-available;
}
textarea.textarea {
  font-family: Segoe UI;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  background: var(--low-light);
  padding: 8px;
  height: 3rem;
}
textarea.textarea::-moz-placeholder {
  font-size: 1rem;
}
textarea.textarea::placeholder {
  font-size: 1rem;
}

.-ArrowBack {
  cursor: pointer;
}
.-ArrowBack.click-me {
  width: 2rem;
}
.-ArrowBack svg {
  height: 1rem;
}

#chat-tools {
  top: 0;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  z-index: 1;
}
#chat-tools p {
  font-family: Segoe UI Semibold;
  font-size: 15px;
  margin: 0;
}

.clickDrop.open .drop {
  display: block;
}
.clickDrop .drop {
  display: none;
}

.clink {
  cursor: pointer;
  color: salmon !important;
}
.clink:hover {
  text-decoration: underline;
}

.Cookie {
  height: 19px;
  color: salmon !important;
}

#entry_proper .entry-user.isJournal .submission-info {
  justify-content: center;
  margin-bottom: 2rem;
}
#entry_proper .entry-user.isJournal .submission-info .-author {
  padding: 8px 12px;
}
#entry_proper .entry-user.isJournal .submission-info .-author p {
  font-size: 2rem;
  font-weight: bold;
  color: salmon;
}
#entry_proper .entry-user.isJournal .submission-info .-author span.isSquadie img {
  transform: translateY(3px);
  height: 14px;
  margin-left: 2px;
}
#entry_proper .entry-user.media .title {
  margin: 0;
}
#entry_proper .entry-user:not(.isJournal) #-replies- {
  border-top: 1px solid var(--dim);
}
#entry_proper .-submission-info {
  margin-bottom: 8px;
}
#entry_proper .-submission-content .title {
  margin-top: 0;
}
#entry_proper .-submission-content .media-wrapper {
  margin-top: 1rem;
}
#entry_proper .-submission-content .media-wrapper.video {
  background: black;
}
#entry_proper .-submission-content .media-wrapper img, #entry_proper .-submission-content .media-wrapper video {
  width: 600px;
  max-height: 77vh;
  -o-object-fit: contain;
     object-fit: contain;
}
#entry_proper .-submission-content .body:first-of-type {
  margin-top: 0;
}
#entry_proper .-submission-content .body:last-of-type {
  margin-bottom: 0;
}
#entry_proper #-replies- {
  margin-top: 2rem;
  padding-bottom: 33vh;
  width: 100%;
}
#entry_proper #-replies- p#add_comment {
  width: -moz-fit-content;
  width: fit-content;
  margin: 4rem auto 0 auto;
  padding: 8px;
  border-radius: 999px;
  cursor: pointer;
}
#entry_proper #-replies- textarea {
  display: block;
  height: 250px;
  padding: 1rem;
  color: lightgrey;
  border-radius: 6px;
  background: var(--background-accent-a);
  resize: vertical;
}
#entry_proper #-replies- textarea::-webkit-scrollbar {
  width: initial;
}
#entry_proper #-replies- #-replies {
  margin-top: 5rem;
}

.entry-user {
  position: relative;
}
.entry-user.--entry-preview {
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 2rem;
  cursor: pointer;
}
.entry-user.--entry-preview.journal-entry {
  background: rgba(0, 0, 255, 0.03);
}
.entry-user.--entry-preview.journal-entry:hover {
  background: rgba(0, 0, 255, 0.05);
}
.entry-user.--entry-preview.Squad {
  margin-bottom: 1.5rem;
}
.entry-user.--entry-preview:hover {
  background: whitesmoke;
}
.entry-user.--entry-preview .-submission-content .title, .entry-user.--entry-preview .-submission-content .flair {
  margin-top: 0;
  margin-bottom: 0;
}
.entry-user.--entry-preview .-submission-content .title {
  font-size: 23px;
  padding: 0 0.5rem;
}
.entry-user.--entry-preview .-submission-content svg.flair {
  height: 1.5rem;
}
.entry-user.--entry-preview .-submission-content .media-wrapper.video {
  background: black;
}
.entry-user.--entry-preview .-submission-content .media-wrapper img, .entry-user.--entry-preview .-submission-content .media-wrapper video {
  width: -moz-fit-content;
  width: fit-content;
  max-height: 66vh;
}
.entry-user.--entry-preview .-submission-content .txt-wrapper {
  position: relative;
  max-height: 176.4px;
  overflow: hidden;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.entry-user.--entry-preview .-submission-content .txt-wrapper.fade-container {
  background: -webkit-linear-gradient(#333, #333, transparent);
}
.entry-user.--entry-preview .-submission-content .txt-wrapper .body:last-of-type {
  margin-bottom: 0;
}
.entry-user.UserProfile {
  padding: 8px 12px;
  border-radius: 1rem;
}
.entry-user.UserProfile .-submission-content .-submission-info {
  padding: 0 0.5rem !important;
}
.entry-user.UserProfile .-submission-content .title {
  font-size: 21px;
}
.entry-user .-submission-info p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: grey;
}
.entry-user .-submission-info .hover-low:hover p {
  color: salmon;
}
.entry-user .-submission-info .-author {
  height: 2rem;
  padding: 0 8px 0 4px;
  border-radius: 999px;
}
.entry-user .-submission-info .-author:hover p {
  color: salmon;
}
.entry-user .-submission-info img.usr {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.entry-user .-submission-info .dummy-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}
.entry-user .-submission-info .bullet {
  padding: 0 5px;
}
.entry-user .-submission-info .-score {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.entry-user .-submission-info .-score .score.upvoted {
  color: dodgerblue;
}
.entry-user .-submission-info .-score .score.downvoted {
  color: red;
}
.entry-user .-submission-info .-replies {
  height: 2rem;
  padding: 0 0.5rem;
  border-radius: 999px;
}
.entry-user .-submission-info .-replies p {
  margin: 0;
}
.entry-user .-submission-info .entry-menu {
  right: 4px;
}
.entry-user .-submission-info .entry-menu.open .entry-menu-items {
  animation: unset-overflow 0s 0.3s forwards;
}
.entry-user .-submission-info .entry-menu.open .entry-menu-items .tray {
  right: 0.5rem;
}
.entry-user .-submission-info .entry-menu .-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  margin-left: auto;
  cursor: pointer;
}
.entry-user .-submission-info .entry-menu .-chevron:hover {
  background: rgba(128, 128, 128, 0.12);
}
.entry-user .-submission-info .entry-menu .-chevron svg {
  width: 10px;
  transform: rotate(90deg);
}
.entry-user .-submission-info .entry-menu .entry-menu-items {
  overflow: hidden;
  padding-left: 0.5rem;
}
.entry-user .-submission-info .entry-menu .entry-menu-items .tray {
  position: relative;
  right: -100%;
  transition: right 0.2s ease-out;
}
.entry-user .-submission-info .entry-menu .entry-menu-items .ico-interactive.pin::after {
  content: "Follow Post";
}
.entry-user .-submission-info .entry-menu .entry-menu-items .ico-interactive.report::after {
  content: "Report";
}
.entry-user .-submission-info .entry-menu .entry-menu-items .ico-interactive:not(:first-of-type) {
  margin-left: 0.5rem;
}
.entry-user .-submission-info .entry-menu .entry-menu-items .ico-interactive svg {
  height: 20px;
}
.entry-user .-submission-content {
  width: 100%;
  box-sizing: border-box;
}
.entry-user .-submission-content .title {
  color: #555;
}
.entry-user .-submission-content .media-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  overflow: hidden;
}
.entry-user .-submission-content .media-wrapper img.backdrop {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 133%;
  min-height: 133%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: blur(3rem);
}
.entry-user .-submission-content .media-wrapper img:not(.backdrop) {
  width: 100%;
  max-width: calc(100vw - 1rem);
  max-height: 720px;
  z-index: 2;
}
.entry-user .-submission-content .media-wrapper video {
  width: -moz-fit-content;
  width: fit-content;
  max-height: 720px;
}
.entry-user .-submission-content .txt-wrapper.fade-container {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.entry-user .-submission-content .txt-wrapper .body {
  font-family: Segoe UI;
  font-size: 15px;
  margin-top: 1em;
  color: #333;
}

.global-rank {
  display: inline-block;
}
.global-rank > div {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--dim);
}
.global-rank > div:not(:last-of-type) {
  margin-bottom: 3px;
}
.global-rank > div.current {
  background: cornflowerblue;
}

.handle-score {
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  top: -1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.handle-score:hover {
  background: var(--low-light);
}
.handle-score.handle-score-up div {
  border-left: 2px solid grey;
  border-top: 2px solid grey;
}
.handle-score.handle-score-down div {
  transform: rotate(45deg) translateY(-2px);
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
}
.handle-score div {
  display: inline-block;
  position: relative;
  top: 1px;
  width: 4px;
  height: 4px;
  transform: rotate(45deg);
}

.ico-interactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.ico-interactive:hover {
  background: rgba(128, 128, 128, 0.12);
}
.ico-interactive svg {
  height: 20px;
}

.isSquadie {
  position: relative;
  margin-left: 1px;
}
.isSquadie::after {
  content: "Squadie";
}
.isSquadie img {
  display: inline-block;
  height: 11px;
}

.journal-entry {
  padding: 8px;
  border-radius: 999px;
  transition: background 0.3s ease;
  cursor: pointer;
}
.journal-entry p {
  margin: 0;
}
.journal-entry p.username {
  font-size: 12px;
  color: var(--background);
}
.journal-entry p.blurb {
  font-size: 15px;
  color: var(--background);
}

.quick-view {
  width: 600px;
  z-index: 999;
}

.learn-more {
  cursor: pointer;
  font-style: italic;
  color: salmon !important;
}
.learn-more:hover {
  text-decoration: underline;
}

.mask {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  position: fixed;
  z-index: -1;
}

.-mod {
  position: relative;
  cursor: default;
}
.-mod.beginner[class*=ps-]::after {
  content: "Starter";
}
.-mod.squad[class*=ps-]::after {
  content: "My Squad";
}
.-mod.squad.color svg {
  color: var(--background) !important;
}
.-mod.squad svg {
  color: orangered;
}
.-mod.global[class*=ps-]::after {
  content: "Global Squad";
}
.-mod.global.color svg {
  stroke: var(--background) !important;
}
.-mod.global svg {
  stroke: dodgerblue;
}
.-mod.type-event p {
  font-family: pixel;
  font-weight: bold;
  color: gold;
}
.-mod.type-event[class*=ps-]::after {
  content: "One Shot Challenge";
}
.-mod.type-community[class*=ps-]::after {
  content: "ranked";
}
.-mod.type-community svg {
  fill: gold;
}
.-mod.wreath[class*=ps-]::after {
  content: "wreath";
}
.-mod.wreath svg {
  color: gold;
}

.-NA- {
  position: relative;
}
.-NA-.TRUE .-NA {
  display: block;
}
.-NA- .-NA {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 1rem);
  display: none;
  opacity: 0.9;
}
.-NA- .-NA::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% - 10px);
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background: violet;
}
.-NA- .-NA .NA {
  font-weight: initial !important;
  color: cornflowerblue !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0.5rem !important;
  opacity: 1 !important;
  font-family: Consolas;
  margin: 0;
  background: violet;
  border-radius: 3px;
}

.-rules {
  padding: 1rem;
  border-radius: 8px;
  background: rgba(64, 224, 208, 0.03);
}
.-rules .t {
  font-family: "Fira Code";
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--low-light);
}
.-rules .core-rules {
  padding: 0 1rem;
}
.-rules .core-rules p {
  font-size: 15px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 1rem;
  color: rgba(64, 224, 208, 0.5);
}
.-rules .core-rules p span {
  color: salmon;
}
.-rules .core-rules p .-About {
  display: inline-block;
  position: relative;
  transform: translateY(1px);
}
.-rules .core-rules p .-About:hover .rule-about {
  display: block;
}
.-rules .core-rules p .-About .rule-about {
  display: none;
  position: absolute;
  left: 0;
  bottom: calc(100% + 8px);
  width: -moz-max-content;
  width: max-content;
  max-width: 300px;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  color: salmon;
  background: var(--background-descent-a);
}
.-rules .core-rules p .-About svg {
  transform: translateY(1px);
  height: 14px;
}
.-rules .core-rules p .-About svg circle {
  color: transparent;
  fill: rgba(64, 224, 208, 0.5);
}
.-rules .core-rules p .-About svg .guts {
  color: #171717;
}
.-rules .rules {
  padding: 0 1rem;
}
.-rules .rules p {
  color: rgba(64, 224, 208, 0.7);
}

.separator {
  border-top: 1px solid grey;
  box-sizing: border-box;
  width: calc(100% - 2rem);
  margin: 8px auto;
}
.separator p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  margin-bottom: 0;
  transform: translate(-50%, calc(-50% - 2px));
  padding: 0 0.5rem;
  background: #171717;
}

.-Spinner svg {
  height: 3rem;
  fill: rgba(255, 255, 255, 0.3);
  animation: spin 1s linear infinite;
}

.squad-id {
  font-family: "Fira Code";
}

.ui-element {
  position: fixed;
  cursor: pointer;
  z-index: 999999;
}
.ui-element.-ArrowBack {
  left: 1rem;
  top: 1rem;
}
.ui-element.-X {
  right: 1rem;
  top: 1rem;
}
.ui-element.--fill svg {
  fill: white;
}
.ui-element svg {
  height: 1.5rem;
  color: white;
}
.ui-element svg.fill {
  fill: white;
}

.ui-handle.--fill svg {
  fill: grey;
}
.ui-handle svg {
  color: grey;
}

.usn {
  font-family: Segoe UI Semibold;
  color: grey;
  cursor: pointer;
}

.usr-border {
  border: 2px solid transparent;
}
.usr-border:hover, .usr-border.active {
  border-color: rgba(255, 255, 255, 0.5);
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  background: var(--background);
  overflow-y: scroll;
  z-index: 9999;
}
.overlay::-webkit-scrollbar {
  width: 6px;
}
.overlay::-webkit-scrollbar-track {
  background: none;
}
.overlay::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background: indianred;
}
.overlay .content {
  width: 600px;
}

#pop-up {
  display: none;
  position: fixed;
  font-size: 18px;
  padding: 3rem;
  margin: 0;
  border-radius: 2rem;
  background: palegoldenrod;
  white-space: nowrap;
  z-index: 999;
}
#pop-up.show {
  display: block;
}
#pop-up.add_activity {
  background: var(--users-background);
}
#pop-up.add_activity .status-option {
  text-align: center;
}
#pop-up.add_activity .status-option .-svg {
  cursor: pointer;
}
#pop-up.add_activity .status-option .-svg svg {
  height: 4rem;
  color: pink;
}
#pop-up.add_activity .status-option p {
  margin-bottom: 0;
  opacity: 0.5;
}
#pop-up.add_activity p.sub {
  font-size: 15px;
  margin-top: 0.5em;
  opacity: 0.5;
}
#pop-up.add_activity input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.5rem;
  color: pink;
  border: 2px solid pink;
  border-radius: 8px;
  padding: 12px 8px;
}
#pop-up.add_activity input.bzz {
  animation: bzz 0.2s forwards;
  border-color: rgba(255, 0, 0, 0.7);
  background: rgba(255, 0, 0, 0.2);
}
#pop-up.add_activity #status-submit {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1em auto 0 auto;
  color: steelblue;
  cursor: pointer;
}
#pop-up.RESET .squad-id {
  font-weight: bold;
}
#pop-up.RESET p#countdown {
  display: inline;
  font-family: "Fira Code";
  font-weight: bold;
  color: var(--background);
}
#pop-up .-X.click-me {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
}
#pop-up .-X.click-me svg {
  height: 1.5rem;
  color: palegoldenrod;
}

#pop-up-m {
  position: fixed;
  background: var(--background);
  opacity: 0.5;
}

[class*=ps-]::after, .pseudo {
  margin: 0;
  padding: 0.5rem;
  border-radius: 999px;
  font-family: Segoe UI;
  font-size: 1rem;
  color: #F7F7F7;
  background: salmon;
  opacity: 0.95;
  white-space: nowrap;
  z-index: 999999;
}

[class*=ps-][class*=-mini]::after, [class*=pseudo-][class*=-mini] .pseudo {
  margin: 0;
  padding: 3px;
  border-radius: 2px;
  font-family: Segoe UI;
  font-size: 13px;
  color: #F7F7F7;
  background: salmon;
  opacity: 0.95;
  white-space: nowrap;
  z-index: 999999;
}

[class*=ps-][class*=-info]::after {
  font-size: 15px !important;
  padding: 1rem !important;
  color: gainsboro !important;
  background: #111 !important;
  opacity: 0.975 !important;
}

sup.i {
  margin: 0;
  font-family: Segoe UI Bold;
  font-weight: 500;
  cursor: pointer;
}

.hoverDrop:hover .drop {
  display: block;
}
.hoverDrop .drop {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 1;
}
.hoverDrop .drop:hover {
  display: block;
}

.hover-low:hover {
  background: var(--low-light);
}

body {
  background: #171717;
  overflow: hidden scroll;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: none;
}
body::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background: indianred;
}
body.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
body.noscroll {
  overflow: hidden;
}

#-ROUTERJS-[theme=dark] {
  --default-p: darkgrey;
  --background: #171717;
  --background-rgb: 23, 23, 23;
  --background-accent-a: #222;
  --background-descent-a: #121212;
  --dim: #555;
  --low-light: rgba(255, 255, 255, .05);
  --text: #BBB;
  --resign: #d73636;
  --users-background: #2D2D2D;
}
#-ROUTERJS-[theme=dark] [class*=ps-]::after, #-ROUTERJS-[theme=dark] .pseudo {
  color: salmon;
  background: rgb(59, 59, 59);
  opacity: 0.9;
}
#-ROUTERJS-[theme=dark] .contrast {
  color: lightgrey;
}
#-ROUTERJS-[theme=dark] .background-semi {
  background: rgba(23, 23, 23, 0.97);
}
#-ROUTERJS-[theme=dark] .background-notice {
  background: #b9ff6c;
}
#-ROUTERJS-[theme=dark] #ibox .info p {
  color: darkgrey;
}
#-ROUTERJS-[theme=dark] .i-mask, #-ROUTERJS-[theme=dark] .fly {
  background: rgba(23, 23, 23, 0.97);
}
#-ROUTERJS-[theme=dark] .ui-handle.--fill svg {
  fill: rgba(255, 255, 255, 0.5);
}
#-ROUTERJS-[theme=dark] .ui-handle svg {
  color: rgba(255, 255, 255, 0.5);
}
#-ROUTERJS-[theme=dark] .overlay p {
  color: darkgrey;
}
#-ROUTERJS-[theme=dark] #Resetting {
  background: rgba(23, 23, 23, 0.9);
}
#-ROUTERJS-[theme=dark] #-logo-tools #navigation.chat-menu, #-ROUTERJS-[theme=dark] #-logo-tools #navigation.menu, #-ROUTERJS-[theme=dark] #-left-panel #navigation.chat-menu, #-ROUTERJS-[theme=dark] #-left-panel #navigation.menu {
  background: #2D2D2D;
}
#-ROUTERJS-[theme=dark] #-logo-tools #navigation.chat-menu .separator p, #-ROUTERJS-[theme=dark] #-logo-tools #navigation.menu .separator p, #-ROUTERJS-[theme=dark] #-left-panel #navigation.chat-menu .separator p, #-ROUTERJS-[theme=dark] #-left-panel #navigation.menu .separator p {
  background: #2D2D2D;
}
#-ROUTERJS-[theme=dark] #-logo-tools #navigation .chat-menu, #-ROUTERJS-[theme=dark] #-left-panel #navigation .chat-menu {
  background: #2D2D2D;
}
#-ROUTERJS-[theme=dark] #-logo-tools #navigation .chat-menu .-chat-items .separator, #-ROUTERJS-[theme=dark] #-left-panel #navigation .chat-menu .-chat-items .separator {
  border-top: 1px solid #171717;
}
#-ROUTERJS-[theme=dark] #-logo-tools #navigation .chat-menu .-chat-items .-chat-item:hover, #-ROUTERJS-[theme=dark] #-left-panel #navigation .chat-menu .-chat-items .-chat-item:hover {
  background: var(--low-light);
}
#-ROUTERJS-[theme=dark] #Profile .drop .wrapper {
  background: #3E3E3E;
}
#-ROUTERJS-[theme=dark] #Profile .drop .wrapper svg, #-ROUTERJS-[theme=dark] #Profile .drop .wrapper p {
  color: #a2d5c0;
  opacity: 0.9;
}
#-ROUTERJS-[theme=dark] #track {
  background: #262626;
}
#-ROUTERJS-[theme=dark] #track #scrollbar {
  background: darkslategrey;
}
#-ROUTERJS-[theme=dark] #toolbar, #-ROUTERJS-[theme=dark] .filter-options {
  background: #171717;
}
#-ROUTERJS-[theme=dark] #toolbar span.welcome, #-ROUTERJS-[theme=dark] .filter-options span.welcome {
  color: #baffb7;
}
#-ROUTERJS-[theme=dark] #toolbar #global-filters .filter, #-ROUTERJS-[theme=dark] .filter-options #global-filters .filter {
  background: #171717;
}
#-ROUTERJS-[theme=dark] #toolbar #global-filters p, #-ROUTERJS-[theme=dark] .filter-options #global-filters p {
  color: grey;
}
#-ROUTERJS-[theme=dark] #__SQUAD__ #squad_activity #-charged- .header svg, #-ROUTERJS-[theme=dark] #__SQUAD__ #squad_activity #-charged- .header p, #-ROUTERJS-[theme=dark] #__SQUAD__ #squad_activity #-charged- span.indicator {
  color: palegoldenrod;
}
#-ROUTERJS-[theme=dark] #__SQUAD__ #squad_activity #-resigned- .resign-item p.msg {
  color: lightgrey;
}
#-ROUTERJS-[theme=dark] #_users_ #squad-handles .click-me.squad::after {
  background: orangered;
  color: var(--background);
}
#-ROUTERJS-[theme=dark] #_users_ #squad-handles .click-me.global::after {
  background: cornflowerblue;
  color: var(--background);
}
#-ROUTERJS-[theme=dark] .handle-score:hover {
  background: rgba(255, 255, 255, 0.125);
}
#-ROUTERJS-[theme=dark] .entry-user .title {
  color: lightgrey;
}
#-ROUTERJS-[theme=dark] .entry-user .body {
  color: darkgrey;
}
#-ROUTERJS-[theme=dark] .--entry-preview:hover {
  background: #282828;
}
#-ROUTERJS-[theme=dark] .--entry-preview .txt-wrapper.fade-container {
  background: -webkit-linear-gradient(darkgrey, darkgrey, transparent);
}
#-ROUTERJS-[theme=dark] .separator {
  border-color: #3E3E3E;
}
#-ROUTERJS-[theme=dark] #entry_proper #-replies- .-textarea textarea {
  color: var(--text);
}
#-ROUTERJS-[theme=dark] #UserProfile #focus #-user-items {
  color: grey;
}
#-ROUTERJS-[theme=dark] #UserProfile #focus #-user-items span.usn p#report {
  background: white;
}
#-ROUTERJS-[theme=dark] #UserProfile #focus #-user-items span.user-do svg:hover {
  color: salmon;
  fill: salmon;
}
#-ROUTERJS-[theme=dark] #CHAT .-chat #chat .__msg p.msg.special {
  color: deepskyblue;
}
#-ROUTERJS-[theme=dark] #CHAT .-chat .-textarea .tool svg {
  color: color-mix(in srgb, white 50%, #171717);
}
#-ROUTERJS-[theme=dark] #CHAT .-chat .-textarea textarea::-moz-placeholder {
  color: color-mix(in srgb, white 50%, #171717);
}
#-ROUTERJS-[theme=dark] #CHAT .-chat .-textarea textarea::placeholder {
  color: color-mix(in srgb, white 50%, #171717);
}
#-ROUTERJS-[theme=dark] #Trigger {
  background: rgba(23, 23, 23, 0.98);
}
#-ROUTERJS-[theme=dark] #Trigger .content {
  border-bottom-color: #070707;
}
#-ROUTERJS-[theme=dark] #Trigger .content .left p {
  color: red;
}
#-ROUTERJS-[theme=dark] #Trigger .content .right p {
  color: blue;
}
#-ROUTERJS-[theme=dark] #-LADDER- #container #ctr-1 #-ladder .-triggers {
  opacity: 1;
}
#-ROUTERJS-[theme=dark] #-LADDER- #container #ctr-1 #-ladder .-triggers:hover {
  opacity: 0.8;
}
#-ROUTERJS-[theme=light] {
  --default-p: normal;
  --background: #F7F7F7;
  --background-rgb: 0, 0, 0;
  --background-accent-a: blue;
  --dim: darkgrey;
  --low-light: rgba(0, 0, 0, .05);
  --text: #333;
  --resign: red;
  --users-background: rgba(0, 0, 0, .8);
}
#-ROUTERJS-[theme=light] .usr-border:hover {
  border-color: rgba(250, 128, 114, 0.8);
}
#-ROUTERJS-[minimalist=y] .minimalist {
  display: none !important;
}
#-ROUTERJS-[minimalist=y] #squad_activity .squad-activity-section .header svg {
  width: 2.5rem;
}
#-ROUTERJS-[minimalist=y] #squad_activity .squad-activity-section .header p {
  display: none;
}

#-logo, #-logo-tools, #-left-panel {
  position: fixed;
  left: 2vw;
  top: 2vw;
}

#-logo {
  cursor: pointer;
  z-index: 99999;
}
#-logo #in-development {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  font-family: Consolas;
  font-size: 1rem;
  font-weight: bold;
  color: fuchsia;
  white-space: nowrap;
}

#-logo-tools, #-left-panel {
  width: 2.5rem;
  height: 2.5rem;
  z-index: 9999;
}
#-logo-tools #navigation, #-left-panel #navigation {
  position: absolute;
  left: 0;
  top: calc(100% + 3rem);
  border-radius: 1rem;
}
#-logo-tools #navigation.menu, #-left-panel #navigation.menu {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 300px;
  height: calc(100vh - 3rem - 3rem - 2vw - 2rem);
  overflow-y: scroll;
  padding: 0.5rem;
  border-radius: 1rem;
}
#-logo-tools #navigation.menu::-webkit-scrollbar, #-left-panel #navigation.menu::-webkit-scrollbar {
  display: none;
}
#-logo-tools #navigation.menu .separator, #-left-panel #navigation.menu .separator {
  position: relative;
  border-color: var(--dim);
}
#-logo-tools #navigation.menu .separator p, #-left-panel #navigation.menu .separator p {
  transform: translate(-50%, calc(-50% - 1px));
  font-size: 13px;
  padding: 0 4px;
}
#-logo-tools #navigation.menu .options, #-left-panel #navigation.menu .options {
  width: 100%;
  box-sizing: border-box;
  padding: 0 3rem;
}
#-logo-tools #navigation.menu .options p, #-left-panel #navigation.menu .options p {
  width: 50%;
  font-size: 15px;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}
#-logo-tools #navigation.menu .options p.current, #-left-panel #navigation.menu .options p.current {
  color: cornflowerblue;
  border-bottom-color: cornflowerblue;
}
#-logo-tools #navigation.menu .options p:not(.current):hover, #-left-panel #navigation.menu .options p:not(.current):hover {
  border-bottom-color: var(--dim);
}
#-logo-tools #navigation.menu .grid-menu, #-left-panel #navigation.menu .grid-menu {
  display: grid;
  grid-auto-rows: 1fr;
}
#-logo-tools #navigation.menu .grid-menu .journal-entry, #-left-panel #navigation.menu .grid-menu .journal-entry {
  border-radius: 6px;
}
#-logo-tools #navigation.menu .grid-menu .journal-entry[current=y] p, #-left-panel #navigation.menu .grid-menu .journal-entry[current=y] p {
  color: salmon !important;
  opacity: 1 !important;
}
#-logo-tools #navigation.menu .grid-menu .journal-entry.blurb .username, #-left-panel #navigation.menu .grid-menu .journal-entry.blurb .username {
  opacity: 0.5;
}
#-logo-tools #navigation.menu .grid-menu .journal-entry:hover, #-left-panel #navigation.menu .grid-menu .journal-entry:hover {
  background: var(--low-light);
}
#-logo-tools #navigation.menu .grid-menu .journal-entry img, #-left-panel #navigation.menu .grid-menu .journal-entry img {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.top-tools {
  display: flex;
  align-items: center;
}
.top-tools#left-side-tools {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 2rem);
}
.top-tools#right-side-tools {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 2rem);
}
.top-tools#right-side-tools #tool-Relapse_Report svg {
  height: 26px;
}
.top-tools .click-me {
  position: relative;
  width: 2.5rem;
}
.top-tools .click-me.active svg {
  color: whitesmoke;
}
.top-tools .click-me svg {
  height: 1.5rem;
}

.nav {
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}
.nav#nav-Community p {
  color: turquoise;
}
.nav#nav-My_Squad svg {
  color: orangered;
}
.nav#nav-My_Squad p {
  color: orangered;
}
.nav#nav-Global_Squad svg {
  stroke: cornflowerblue;
}
.nav#nav-Global_Squad p {
  color: cornflowerblue;
}
.nav.reg:hover svg {
  color: salmon;
}
.nav.reg p {
  color: salmon;
}
.nav:not(.not) .-nav-icon.click-me {
  background: rgba(255, 255, 255, 0.1);
}
.nav:not(.not) .reg svg {
  color: salmon;
}
.nav:not(.not) #ico-Feed svg {
  fill: salmon;
}
.nav p {
  position: relative;
  left: 0;
  font-size: 18px;
  margin: 0 0 0 1rem;
  white-space: nowrap;
}

.-nav-icon {
  width: 3rem;
}
.-nav-icon#ico-Community img {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.-nav-icon.pro svg {
  height: 2rem;
}
.-nav-icon.reg svg {
  width: 20px;
  color: grey;
}
.-nav-icon svg {
  fill: none;
  margin: 0 auto;
}

#Profile {
  position: fixed;
  right: 2vw;
  top: 2vw;
  transform: translate(3px, -3px);
  z-index: 99999;
  cursor: pointer;
}
#Profile img {
  min-width: 3rem;
  min-height: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

#profile-menu, .main-menu {
  position: fixed;
  right: 0;
  top: 0;
  padding: 0 4rem;
  background: var(--background);
  z-index: 99998;
}
#profile-menu .menu-items-wrapper > div, .main-menu .menu-items-wrapper > div {
  cursor: pointer;
}
#profile-menu .menu-items-wrapper > div:not(:last-of-type), .main-menu .menu-items-wrapper > div:not(:last-of-type) {
  margin-bottom: 1rem;
}
#profile-menu .menu-items-wrapper > div:hover p, .main-menu .menu-items-wrapper > div:hover p {
  color: var(--background);
  background: turquoise;
}
#profile-menu .menu-items-wrapper > div #-theme, .main-menu .menu-items-wrapper > div #-theme {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
#profile-menu .menu-items-wrapper > div #-theme .-icon-, .main-menu .menu-items-wrapper > div #-theme .-icon- {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease;
}
#profile-menu .menu-items-wrapper svg, #profile-menu .menu-items-wrapper p, .main-menu .menu-items-wrapper svg, .main-menu .menu-items-wrapper p {
  color: turquoise;
}
#profile-menu .menu-items-wrapper .-icon, .main-menu .menu-items-wrapper .-icon {
  display: flex;
  justify-content: center;
  width: 24px;
}
#profile-menu .menu-items-wrapper .-icon svg, .main-menu .menu-items-wrapper .-icon svg {
  height: 19px;
}
#profile-menu .menu-items-wrapper .-icon svg#light, .main-menu .menu-items-wrapper .-icon svg#light {
  min-height: 24px;
}
#profile-menu .menu-items-wrapper p, .main-menu .menu-items-wrapper p {
  font-family: Consolas;
  font-size: 17px;
  box-sizing: border-box;
  width: 200px;
  padding: 1rem;
  margin: 0;
  margin-left: 1rem;
  border-radius: 8px;
  white-space: nowrap;
}

#toolbar, .filter-options {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 600px;
  padding: 3rem 0 2rem 0;
  color: salmon;
  background: #F7F7F7;
  z-index: 99999;
}
#toolbar.with-title, .filter-options.with-title {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
}
#toolbar.with-title .t, .filter-options.with-title .t {
  font-size: 1.5rem;
}
#toolbar p, .filter-options p {
  margin-top: 0;
  margin-bottom: 0;
}
#toolbar p.toolbar-header, .filter-options p.toolbar-header {
  font-family: Segoe UI;
  font-size: 17px;
  font-weight: bold;
  color: var(--dim);
}
#toolbar p.title, .filter-options p.title {
  font-family: Segoe UI Semibold;
}
#toolbar p.title#general-chat-title, .filter-options p.title#general-chat-title {
  color: steelblue;
}
#toolbar p#squad-id-toolbar, .filter-options p#squad-id-toolbar {
  color: salmon;
}
#toolbar .-ArrowBack, .filter-options .-ArrowBack {
  display: flex;
  align-items: center;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
#toolbar .-ArrowBack::after, .filter-options .-ArrowBack::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--low-light);
  z-index: -1;
}
#toolbar #-stats, .filter-options #-stats {
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
}
#toolbar #-stats p.stat, .filter-options #-stats p.stat {
  margin-left: 2rem;
}
#toolbar #-stats p.stat.id-current, .filter-options #-stats p.stat.id-current {
  position: relative;
}
#toolbar #-stats p.stat.id-current span::after, .filter-options #-stats p.stat.id-current span::after {
  content: "Squadies";
}
#toolbar #-stats p.stat.id-current span svg, .filter-options #-stats p.stat.id-current span svg {
  display: inline-block;
  fill: none;
  color: gold;
  height: 14px;
  margin-left: 4px;
  transform: translateY(1px);
}
#toolbar #global-filters, .filter-options #global-filters {
  margin-right: auto;
}
#toolbar #global-filters .hoverDrop, .filter-options #global-filters .hoverDrop {
  position: relative;
}
#toolbar #global-filters .hoverDrop p, .filter-options #global-filters .hoverDrop p {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#toolbar #global-filters .hoverDrop .drop, .filter-options #global-filters .hoverDrop .drop {
  right: 0;
  padding-top: 0.5rem;
}
#toolbar #global-filters .hoverDrop .filter, .filter-options #global-filters .hoverDrop .filter {
  justify-content: right;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1.5px solid transparent;
  background: #F7F7F7;
  cursor: pointer;
}
#toolbar #global-filters .hoverDrop .filter.selected, .filter-options #global-filters .hoverDrop .filter.selected {
  padding: 0;
  margin-right: 0.5rem;
  margin-top: 0;
}
#toolbar #global-filters .hoverDrop .filter:not(.selected):hover, .filter-options #global-filters .hoverDrop .filter:not(.selected):hover {
  transition: border-color 0.3s;
  border-color: salmon;
}
#toolbar #global-filters .hoverDrop .filter svg, .filter-options #global-filters .hoverDrop .filter svg {
  height: 19px;
  margin-right: 4px;
}
#toolbar #global-filters .hoverDrop .filter svg, #toolbar #global-filters .hoverDrop .filter p, .filter-options #global-filters .hoverDrop .filter svg, .filter-options #global-filters .hoverDrop .filter p {
  color: salmon;
}
#toolbar #p-create-post, .filter-options #p-create-post {
  color: grey;
  cursor: pointer;
}

#-HOME- {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 3rem;
}
#-HOME- .community {
  cursor: pointer;
  padding: 8px;
  border-radius: 999px;
}
#-HOME- .community img {
  height: 26px;
  border-radius: 50%;
}
#-HOME- .community p {
  margin: 0;
  color: #fffff5;
}
#-HOME- .community p.t {
  font-size: 17px;
}
#-HOME- .community p.sub {
  margin-top: 2px;
}
#-HOME- .community p .-Beginner {
  display: inline-block;
  position: relative;
  font-size: 12px;
}
#-HOME- .community p .-Beginner::after {
  content: "Starter Options";
}
#-HOME- .community p .-Beginner svg {
  height: 12px;
  color: darkseagreen;
}
#-HOME- .community p .-Tick {
  display: inline-block;
  position: relative;
}
#-HOME- .community p .-Tick::after {
  content: "Joined";
}
#-HOME- .community p .-Tick svg {
  height: 8px;
  fill: palegreen;
}
#-HOME- .--my {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 4px 12px;
  margin-bottom: 8px;
  border-radius: unset;
}
#-HOME- .--my p.t span.day-x {
  color: grey;
}
#-HOME- .--my p.t span.starter {
  color: grey;
}
#-HOME- .--my p.t span.starting-soon {
  font-size: 15px;
  border-radius: 2px;
  color: palegoldenrod;
}
#-HOME- .--my p.t span.starting-soon svg {
  height: 1rem;
  color: palegoldenrod;
  transform: translateY(3px);
}
#-HOME- .--my p.sub {
  font-size: 14px;
  margin: 0;
}
#-HOME- .--my p.sub span[class*=stat] {
  color: grey;
}
#-HOME- .--my p.sub span[class*=stat].stat-current svg {
  color: gold;
}
#-HOME- .--my p.sub span svg {
  display: inline-block;
  transform: translateY(1px);
  height: 12px;
  fill: none;
}
#-HOME- .-search {
  position: relative;
  transform: translateY(-8px);
  margin: 2vw auto 0 auto;
  z-index: 1;
}
#-HOME- .-search input.search {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  box-sizing: border-box;
  width: 50vw;
  height: 4rem;
  font-size: 1.25rem;
  padding-left: calc(2.5rem + 32px);
  border-radius: 999px;
  color: salmon;
  background: rgba(255, 255, 255, 0.025);
}
#-HOME- .-search .-svg-Search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  height: 2rem;
  color: grey;
}
#-HOME- .-search .-svg-X {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  cursor: pointer;
}
#-HOME- .-search .-svg-X svg {
  height: 1rem;
  color: grey;
}
#-HOME- .-search .search-results {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 8px);
  box-sizing: border-box;
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--background-accent-a);
  z-index: 999;
}
#-HOME- .-search .search-results p {
  color: gainsboro;
}
#-HOME- .-search .search-results p:not(.no-results) {
  cursor: pointer;
}
#-HOME- .-search .search-results p:not(.no-results):hover {
  opacity: 0.8;
}
#-HOME- #section-tags {
  display: none;
  width: 50vw;
  box-sizing: border-box;
  overflow-x: scroll;
  margin: 8px auto 3rem auto;
  padding: 0 1rem;
}
#-HOME- #section-tags::-webkit-scrollbar {
  display: none;
}
#-HOME- #section-tags p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  padding: 8px;
  border-radius: 999px;
  color: salmon !important;
  white-space: nowrap;
  cursor: pointer;
}
#-HOME- #section-tags p:not(:last-of-type) {
  margin-right: 2rem;
}
#-HOME- #bigSearch {
  padding: 0 1rem;
}
#-HOME- #bigSearch .tag {
  width: -moz-fit-content;
  width: fit-content;
  margin: 2em;
  color: salmon;
  padding: 8px;
  border-radius: 999px;
  cursor: pointer;
}
#-HOME- #bigSearch .tag svg {
  display: inline-block;
  transform: translateY(-1px);
  height: 8px;
  margin-right: 8px;
  fill: salmon;
}
#-HOME- #bigSearch .community {
  margin-bottom: 8px;
}
#-HOME- .grid {
  display: grid;
  grid-template-columns: auto 400px;
  grid-template-areas: "g-l1 g-communities" "g-l2 g-communities" "g-l3 g-communities";
  grid-gap: 3rem;
  margin-top: 3rem;
}
#-HOME- .grid .g-l1 {
  grid-area: g-l1;
}
#-HOME- .grid .g-l2 {
  grid-area: g-l2;
}
#-HOME- .grid .g-l3 {
  grid-area: g-l3;
}
#-HOME- .grid .g-communities {
  grid-area: g-communities;
}
#-HOME- .grid .grid-gap-bottom {
  margin-bottom: 3rem;
}
#-HOME- .grid .dummy-gradient {
  height: 100%;
}
#-HOME- .g-l1#News-Announcements {
  height: 200px;
}
#-HOME- .g-l2#Upcoming-Month {
  height: 200px;
}
#-HOME- .g-l3#Communities-Spotlight {
  height: 300px;
}
#-HOME- #create-community.button {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1.5em;
  padding: 8px 12px;
  border-radius: 999px;
  color: salmon;
}
#-HOME- #My-Communities .header {
  font-size: 21px;
  font-variant: all-petite-caps;
  letter-spacing: 3px;
  margin-top: 0;
  color: grey;
  text-align: center;
  white-space: nowrap;
}
#-HOME- #My-Communities .communities {
  margin-top: 8px;
  display: grid;
  grid-auto-rows: 1fr;
}
#-HOME- #-footer {
  margin-top: 3rem;
}
#-HOME- #-footer #footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 2rem;
  box-sizing: border-box;
  padding: 3rem 0;
}
#-HOME- #-footer #footer .dummy {
  width: 100%;
  height: 1rem;
  background: var(--dim);
}
#-HOME- #-footer #footer p {
  font-family: Consolas;
  margin: 0;
  color: var(--dim);
}

#-HOME2- {
  position: relative;
  box-sizing: border-box;
  width: 600px;
  padding: 5rem 0 3rem 0;
  margin: 0 auto;
}
#-HOME2- .exhibit {
  margin-bottom: 5rem;
}
#-HOME2- .exhibit .continue {
  justify-self: center;
  align-self: center;
}
#-HOME2- .exhibit .continue.button {
  margin: 0;
  background: skyblue;
}
#-HOME2- .exhibit .continue p {
  margin: 0;
}
#-HOME2- .exhibit .continue.isJoined {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4px;
}
#-HOME2- .exhibit .continue.isJoined p {
  font-size: 15px;
  text-align: right;
}
#-HOME2- .exhibit .continue.isJoined p.joined {
  font-size: 15px;
  color: palegreen;
}
#-HOME2- .exhibit .continue.isJoined p.joined .-Tick {
  display: inline-block;
}
#-HOME2- .exhibit .continue.isJoined p.joined .-Tick svg {
  height: 10px;
  fill: palegreen;
}
#-HOME2- .exhibit .continue.isJoined p.go-to-squad {
  color: turquoise;
}
#-HOME2- .exhibit .-credentials .-img img {
  height: 9rem;
  margin: 0 auto;
  border-radius: 50%;
}
#-HOME2- .exhibit .-credentials .name {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.75rem;
  margin: 1em auto 12px auto;
  text-align: center;
  color: turquoise;
}
#-HOME2- .exhibit .-credentials .name span.beginner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  font-size: 1.75rem;
  color: darkseagreen;
  white-space: nowrap;
}
#-HOME2- .exhibit .-credentials .behaviours {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour {
  position: relative;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour:not(:last-of-type) {
  margin-right: 1rem;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour.-mod-beginner {
  cursor: pointer;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour.-mod-beginner p {
  filter: saturate(1);
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour.-mod-beginner:hover p, #-HOME2- .exhibit .-credentials .behaviours .behaviour.-mod-beginner.selected p {
  filter: saturate(0);
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour svg {
  height: 18px;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour p {
  margin: 0;
  text-align: center;
}
#-HOME2- .exhibit .-credentials .behaviours .behaviour p.sub {
  padding: 3px 2px 1px 2px;
  margin-top: 4px;
  border-top: 1px solid;
}
#-HOME2- .community {
  padding: 1rem;
  border-radius: 999px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.01);
}
#-HOME2- .community:not(:last-of-type) {
  margin-bottom: 8px;
}
#-HOME2- .community:hover {
  background: rgba(255, 255, 255, 0.02);
}
#-HOME2- .community img {
  height: 30px;
  border-radius: 50%;
}
#-HOME2- .community p {
  margin: 0;
  color: #fffff5;
}
#-HOME2- .community p.t {
  font-size: 17px;
  margin-left: 12px;
}
#-HOME2- .community p.sub {
  margin-left: 12px;
  margin-top: 2px;
  white-space: nowrap;
}
#-HOME2- .--my {
  position: relative;
}
#-HOME2- .--my p.t span.day-x {
  color: grey;
}
#-HOME2- .--my p.t span.starter {
  color: grey;
}
#-HOME2- .--my p.t span.starting-soon {
  font-size: 15px;
  border-radius: 2px;
  color: palegoldenrod;
}
#-HOME2- .--my p.t span.starting-soon svg {
  height: 1rem;
  color: palegoldenrod;
  transform: translateY(3px);
}
#-HOME2- .--my p.stat-current {
  color: grey;
  margin-left: auto;
}
#-HOME2- .--my p.stat-current svg {
  display: inline-block;
  height: 12px;
  fill: none;
  color: gold;
}
#-HOME2- #joinable-communities .header, #-HOME2- #my-communities .header {
  font-size: 21px;
  font-variant: all-petite-caps;
  letter-spacing: 3px;
  margin-top: 0;
  color: grey;
  text-align: center;
  white-space: nowrap;
}
#-HOME2- #joinable-communities .communities, #-HOME2- #my-communities .communities {
  margin-top: 8px;
  padding: 0 1rem;
  display: grid;
  grid-auto-rows: 1fr;
}
#-HOME2- #joinable-communities {
  margin-top: 3rem;
}
#-HOME2- #-footer {
  margin-top: 7rem;
  padding: 0 2rem;
}
#-HOME2- #-footer #footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 2rem;
  box-sizing: border-box;
}
#-HOME2- #-footer #footer .dummy {
  width: 100%;
  height: 1rem;
  background: var(--low-light);
}
#-HOME2- #-footer #footer p {
  font-family: Consolas;
  margin: 0;
  color: var(--dim);
}

#CommunityLanding {
  position: relative;
  box-sizing: border-box;
  width: 600px;
  padding: 5rem 0;
  margin: 0 auto;
}
#CommunityLanding .exhibit .continue {
  justify-self: center;
  align-self: center;
}
#CommunityLanding .exhibit .continue.button {
  margin: 0;
  background: skyblue;
}
#CommunityLanding .exhibit .continue p {
  margin: 0;
}
#CommunityLanding .exhibit .continue.isJoined {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4px;
}
#CommunityLanding .exhibit .continue.isJoined p {
  font-size: 15px;
  text-align: right;
}
#CommunityLanding .exhibit .continue.isJoined p.joined {
  font-size: 15px;
  color: palegreen;
}
#CommunityLanding .exhibit .continue.isJoined p.joined .-Tick {
  display: inline-block;
}
#CommunityLanding .exhibit .continue.isJoined p.joined .-Tick svg {
  height: 10px;
  fill: palegreen;
}
#CommunityLanding .exhibit .continue.isJoined p.go-to-squad {
  color: turquoise;
}
#CommunityLanding .exhibit .-credentials .-img {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
#CommunityLanding .exhibit .-credentials .-img img {
  height: 7rem;
  margin: 0 auto;
  border-radius: 50%;
}
#CommunityLanding .exhibit .-credentials .name {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.75rem;
  margin: 1em auto 2em auto;
  text-align: center;
  color: turquoise;
}
#CommunityLanding .exhibit .-credentials .name span.beginner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  font-size: 1.75rem;
  color: darkseagreen;
  white-space: nowrap;
}
#CommunityLanding .-type {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
#CommunityLanding .-type #type svg {
  height: 2rem;
}
#CommunityLanding .-type #type p {
  font-size: 1.5rem;
  margin: 0;
  margin-top: 4px;
  color: gold;
  text-transform: capitalize;
}
#CommunityLanding .-type .behaviours {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid var(--background-accent-a);
}
#CommunityLanding .-type .behaviours > :not(:last-child) {
  margin-right: 2rem;
}
#CommunityLanding .-type .behaviours .-mod {
  position: relative;
}
#CommunityLanding .-type .behaviours .-mod svg {
  height: 1.25rem;
}
#CommunityLanding .-type .behaviours .-mod p {
  font-size: 1.25rem;
  margin: 0;
  text-align: center;
}
#CommunityLanding .-type .behaviours .-mod p.sub {
  padding: 3px 2px 1px 2px;
  margin-top: 4px;
  border-top: 1px solid;
}
#CommunityLanding .-type .behaviours #community-size {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  font-size: 2rem;
  color: gold;
  cursor: default;
}
#CommunityLanding .-type .behaviours #community-size::after {
  content: "Members";
}
#CommunityLanding .community-about {
  margin: 5rem 0;
}
#CommunityLanding .community-about .about {
  font-size: 1.25rem;
  margin-left: 1rem;
  color: rgba(64, 224, 208, 0.5);
  text-align: center;
}

.App {
  position: relative;
  width: 600px;
  margin: 0 auto;
}

#COMMUNITY {
  padding-bottom: 7rem;
}
#COMMUNITY .-m- {
  width: 100%;
  background: rgba(var(--background-rgb), 0.975);
}
#COMMUNITY .pop-up {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  padding: 1rem 6rem;
  border-radius: 1rem;
  background: var(--background-accent-a);
}
#COMMUNITY .pop-up p#confirmRESET {
  font-size: 17px;
  font-weight: bold;
  color: red;
}
#COMMUNITY .pop-up > div p {
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
}
#COMMUNITY .pop-up > div p:hover {
  color: salmon;
}
#COMMUNITY #content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 4rem auto;
}
#COMMUNITY #content-header img#community-img {
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  cursor: default;
}
#COMMUNITY #content-header p#community_name {
  font-family: "Fira Code";
  font-weight: 500;
  font-size: 2rem;
  margin-top: 0.5em;
}
#COMMUNITY #content-header #actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
#COMMUNITY #content-header #actions > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0 1rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
}
#COMMUNITY #content-header #actions > div#reset {
  position: relative;
}
#COMMUNITY #content-header #actions > div#reset.isTOMORROWSquad {
  border-color: red !important;
  background: red !important;
  cursor: not-allowed;
}
#COMMUNITY #content-header #actions > div#reset.isTOMORROWSquad svg {
  color: var(--background);
}
#COMMUNITY #content-header #actions > div:hover {
  border-color: red;
  background: rgba(255, 0, 0, 0.2);
}
#COMMUNITY #content-header #actions > div svg {
  height: 19px;
  color: red;
}
#COMMUNITY #content-header #actions > div p {
  color: red;
  margin: 0;
  text-align: center;
}
#COMMUNITY p#isTOMORROWSquad {
  font-size: 1.25rem;
  margin-top: 7em;
  text-align: center;
}
#COMMUNITY p#squad_id {
  font-size: 18px;
}
#COMMUNITY p#squad_id span {
  font-size: 17px;
  font-weight: bold;
}
#COMMUNITY .nickname {
  font-size: 1.25rem;
  color: salmon;
}
#COMMUNITY .section {
  margin-bottom: 3rem;
}
#COMMUNITY .section.foo-section svg, #COMMUNITY .section.foo-section p {
  color: var(--dim);
}
#COMMUNITY .section.foo-section svg {
  height: 1.25rem;
}
#COMMUNITY .side-note {
  font-size: 15px;
  font-style: italic;
}
#COMMUNITY #-suggest #suggest {
  font-size: 18px;
  margin-top: 2rem;
}
#COMMUNITY #-suggest #suggest .-input {
  position: relative;
}
#COMMUNITY #-suggest #suggest .-input .after {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  font-size: 14px;
  margin: 0;
}
#COMMUNITY #-suggest #suggest .-input .after span.over, #COMMUNITY #-suggest #suggest .-input .after span.under {
  color: indianred;
}
#COMMUNITY #-suggest #suggest .-input input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  border-bottom: 3px solid darkslategrey;
  padding: 12px;
  font-size: 1.25rem;
  color: salmon;
}
#COMMUNITY #-suggest #propose {
  margin-top: 2em;
  margin-bottom: 2em;
}
#COMMUNITY #-suggest #propose span {
  margin: 0 2px;
}
#COMMUNITY .-poll {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  background: #2D2D2D;
}
#COMMUNITY .-poll .-suggestions {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1rem 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 2rem;
  box-sizing: border-box;
}
#COMMUNITY .-poll .-suggestions p {
  font-size: 18px;
  margin: 0;
}
#COMMUNITY .-poll .-suggestions p.suggestion {
  font-weight: bold;
}
#COMMUNITY .-poll .-suggestions p.vote {
  color: grey;
  cursor: pointer;
}
#COMMUNITY .-poll .-suggestions p.vote:hover {
  color: salmon;
}
#COMMUNITY #inception {
  cursor: default;
}
#COMMUNITY #-stats- {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 2rem;
}
#COMMUNITY #-stats- p {
  font-family: "Fira Code";
  font-size: 18px;
}
#COMMUNITY #-stats- p span#stat-current {
  color: gold;
}
#COMMUNITY #-stats- p span#stat-initial {
  color: palegreen;
}
#COMMUNITY #-stats- p span#stat-resigned {
  color: red;
}

#GlobalRankInfo {
  height: 100%;
}
#GlobalRankInfo .-divisions- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
#GlobalRankInfo .-divisions- .-divisions {
  position: relative;
}
#GlobalRankInfo .-divisions- .-divisions::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 5px;
  height: 100%;
  background: var(--dim);
  opacity: 0.3;
  z-index: -1;
}
#GlobalRankInfo .-divisions- .-divisions > .division.current {
  border-color: steelblue;
}
#GlobalRankInfo .-divisions- .-divisions > .division.current p {
  color: steelblue;
}
#GlobalRankInfo .-divisions- .-divisions > .division:not(:last-of-type) {
  margin-bottom: 3rem;
}
#GlobalRankInfo .-divisions- .-divisions > .division p {
  font-family: "Fira Code";
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  padding: 8px;
  background: var(--background);
  color: var(--dim);
}

#CHAT, .conversation-window {
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  width: 100%;
}
#CHAT::-webkit-scrollbar, .conversation-window::-webkit-scrollbar {
  display: none;
}
#CHAT[expand=false], .conversation-window[expand=false] {
  padding: 0 1rem;
}
#CHAT .-chat, .conversation-window .-chat {
  position: relative;
}
#CHAT .-chat #chat, .conversation-window .-chat #chat {
  padding: 0 1rem;
}
#CHAT .-chat #chat .__msg, .conversation-window .-chat #chat .__msg {
  margin-top: 1rem;
}
#CHAT .-chat #chat .__msg img.usr, .conversation-window .-chat #chat .__msg img.usr {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-right: 0.5rem;
  cursor: pointer;
}
#CHAT .-chat #chat .__msg ._msg p, .conversation-window .-chat #chat .__msg ._msg p {
  margin: 0;
}
#CHAT .-chat #chat .__msg ._msg p span.span-usn, .conversation-window .-chat #chat .__msg ._msg p span.span-usn {
  color: var(--dim);
  cursor: pointer;
}
#CHAT .-chat #chat .__msg ._msg p span.span-usn:hover, .conversation-window .-chat #chat .__msg ._msg p span.span-usn:hover {
  color: salmon;
}
#CHAT .-chat #chat .__msg ._msg p.msg, .conversation-window .-chat #chat .__msg ._msg p.msg {
  font-family: Segoe UI;
  font-size: 15px;
  color: var(--text);
}
#CHAT .-chat #chat .__msg ._msg p.msg.special, .conversation-window .-chat #chat .__msg ._msg p.msg.special {
  color: mediumblue;
}
#CHAT .-chat #chat .__msg ._msg img.img-submission, .conversation-window .-chat #chat .__msg ._msg img.img-submission {
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 100%;
  max-height: 50vh;
  margin-top: 8px;
}
#CHAT .-chat .-textarea, .conversation-window .-chat .-textarea {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2rem 0;
}
#CHAT .-chat .-textarea .tool, .conversation-window .-chat .-textarea .tool {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}
#CHAT .-chat .-textarea .tool#svg-chat-menu, .conversation-window .-chat .-textarea .tool#svg-chat-menu {
  left: 0.5rem;
}
#CHAT .-chat .-textarea .tool#svg-chat-menu svg, .conversation-window .-chat .-textarea .tool#svg-chat-menu svg {
  height: 1rem;
}
#CHAT .-chat .-textarea .tool#svg-chat-menu::after, .conversation-window .-chat .-textarea .tool#svg-chat-menu::after {
  content: "Chats";
}
#CHAT .-chat .-textarea .tool#resize-chat, .conversation-window .-chat .-textarea .tool#resize-chat {
  right: 0.5rem;
}
#CHAT .-chat .-textarea .tool svg, .conversation-window .-chat .-textarea .tool svg {
  height: 18px;
  color: grey;
}
#CHAT .-chat .-textarea .tool svg path, .conversation-window .-chat .-textarea .tool svg path {
  stroke-width: 2;
}
#CHAT .-chat .-textarea textarea, .conversation-window .-chat .-textarea textarea {
  display: block;
  height: 21px;
  font-family: Segoe UI;
  font-size: 1rem;
  border: 2px solid transparent;
  border-radius: 999px;
  padding: 10px;
  padding-left: 1.25rem;
  background: var(--background-accent-a);
  color: var(--text);
  resize: none;
  opacity: 0.5;
  transition: opacity 0.1s;
}
#CHAT .-chat .-textarea textarea:hover, #CHAT .-chat .-textarea textarea:focus, .conversation-window .-chat .-textarea textarea:hover, .conversation-window .-chat .-textarea textarea:focus {
  opacity: 1;
}

#__SQUAD__ {
  min-height: 200vh;
}
#__SQUAD__ #StartingIn {
  margin-top: 16.5vh;
  font-family: Consolas;
  color: lightskyblue;
  text-align: center;
}
#__SQUAD__ #StartingIn #p-starting-in {
  font-size: 2.25rem;
  margin: 0.5em 0;
  opacity: 0.5;
}
#__SQUAD__ #StartingIn #countdown {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}
#__SQUAD__ .usr {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
#__SQUAD__ .sticky {
  margin-bottom: 7rem;
}
#__SQUAD__ .sticky .--header svg, #__SQUAD__ .sticky .--header p {
  color: var(--dim);
}
#__SQUAD__ .sticky .--header svg {
  height: 2rem;
}
#__SQUAD__ .sticky .--header p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Fira Code";
  font-size: 1.5rem;
}
#__SQUAD__ .nothing-to-see {
  margin: 9rem 0;
  font-size: 1.25rem;
  color: var(--dim);
  text-align: center;
}
#__SQUAD__ .nothing-to-see#add_status {
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
}
#__SQUAD__ .nothing-to-see p {
  color: var(--dim);
}
#__SQUAD__ .nothing-to-see svg {
  height: 1.25rem;
  color: var(--dim);
}
#__SQUAD__ .nothing-to-see #add_journal {
  font-size: 1rem;
  cursor: pointer;
}
#__SQUAD__ #squad_overview {
  margin-bottom: 4rem;
  padding-top: 2rem;
}
#__SQUAD__ #squad_overview .--header {
  margin-bottom: 1.5rem;
}
#__SQUAD__ #squad_overview .--header img {
  display: inline-block;
  width: 37px;
  height: 37px;
  opacity: 0.1;
}
#__SQUAD__ #squad_overview .--header .squad-id {
  font-size: 3rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.1) !important;
}
#__SQUAD__ #squad_overview .--header #squad_activity_global_rank {
  position: relative;
  padding: 0 8px;
  cursor: pointer;
}
#__SQUAD__ #squad_overview .--header #squad_activity_global_rank:hover::after {
  display: block;
}
#__SQUAD__ #squad_overview .--header #squad_activity_global_rank::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--low-light);
  cursor: pointer;
}
#__SQUAD__ #squad_overview #-overview- {
  display: grid;
  grid-column-gap: 4rem;
  width: -moz-fit-content;
  width: fit-content;
  justify-items: center;
  margin: 0 auto;
}
#__SQUAD__ #squad_overview #-overview- .overview-item {
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-current .stat {
  color: gold;
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-current::after {
  content: "Current";
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-up_24 .stat {
  color: cornflowerblue;
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-up_24::after {
  content: "24 Hours";
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-down_24 .stat {
  color: var(--resign);
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-down_24::after {
  content: "24 Hours";
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-change_since_login .stat {
  color: var(--resign);
}
#__SQUAD__ #squad_overview #-overview- .overview-item#overview-change_since_login::after {
  content: "Since Last Login";
}
#__SQUAD__ #squad_overview #-overview- .overview-item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  z-index: -1;
  background: var(--low-light);
}
#__SQUAD__ #squad_overview #-overview- .overview-item .stat {
  font-family: "Fira Code";
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0;
}
#__SQUAD__ #squad_overview #-overview- .overview-item > div {
  position: absolute;
  top: 0;
  left: calc(100% + 4px);
}
#__SQUAD__ #squad_overview #-overview- .overview-item > div svg {
  height: 14px;
  fill: none;
}
#__SQUAD__ #squad_overview #-overview- .overview-item > div p {
  transform: translateY(-2px);
  font-family: "Fira Code";
  font-size: 15px;
  margin: 0;
}
#__SQUAD__ #squad_activity {
  padding: 0 2rem;
}
#__SQUAD__ #squad_activity .squad-activity-section {
  margin-bottom: 2rem;
  padding: 1rem;
}
#__SQUAD__ #squad_activity .squad-activity-section .header svg {
  width: unset;
  height: 2rem;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr {
  padding-left: 1.5rem;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr .-user {
  position: relative;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr .-user:hover {
  z-index: 999 !important;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr .-user .usr-border.isPickedUp {
  border-color: blue;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr img {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr .indicator {
  font-size: 15px;
  margin: 0;
  color: darkgrey;
  white-space: nowrap;
}
#__SQUAD__ #squad_activity .squad-activity-section .users-arr .indicator span.blip {
  position: relative;
  top: 1px;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools {
  display: flex;
  align-items: center;
  margin-left: auto;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .ico-interactive {
  background: var(--low-light);
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .ico-interactive.picked-up, #__SQUAD__ #squad_activity .squad-activity-section .tools .ico-interactive.applied {
  background: blue;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .ico-interactive.picked-up svg, #__SQUAD__ #squad_activity .squad-activity-section .tools .ico-interactive.applied svg {
  color: var(--background);
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .ps-top.pick-up::after {
  content: "Pick Up";
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .ps-top.delete::after {
  content: "Delete";
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .-X:hover svg {
  color: red;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .join:hover svg {
  color: gold;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .pick-up:not(.picked-up):hover svg {
  color: blue;
}
#__SQUAD__ #squad_activity .squad-activity-section .tools .msg-in:hover svg {
  color: var(--resign);
}
#__SQUAD__ #squad_activity #-charged- .up-arr {
  width: 100%;
}
#__SQUAD__ #squad_activity #-charged- .up-arr > div:not(:last-of-type) {
  margin-bottom: 1rem;
}
#__SQUAD__ #squad_activity #-charged- .up-arr .indicator {
  left: calc(100% + 8px);
  padding-bottom: 1px;
  color: turquoise;
}
#__SQUAD__ #squad_activity #-charged- .up-arr .tools .join.joined svg {
  color: gold;
  height: 1.75rem;
}
#__SQUAD__ #squad_activity #-charged- .up-arr .tools .join::after {
  content: "Join";
}
#__SQUAD__ #squad_activity #-help- .header svg {
  color: lightcoral;
}
#__SQUAD__ #squad_activity #-help- .-user {
  cursor: pointer;
}
#__SQUAD__ #squad_activity #-help- .-user.focused {
  z-index: 99 !important;
}
#__SQUAD__ #squad_activity #-help- .-user.anotherIsFocused {
  opacity: 0.5;
}
#__SQUAD__ #squad_activity #-help- .-user:hover {
  opacity: 1 !important;
  z-index: 999 !important;
}
#__SQUAD__ #squad_activity #-help- .-user .indicator {
  bottom: calc(100% + 1rem);
  padding: 1rem;
  border-radius: 999px;
  background: var(--low-light);
  color: lightcoral;
}
#__SQUAD__ #squad_activity #-help- .-user .indicator span.blip {
  color: lightcoral;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box {
  position: relative;
  margin-top: 1rem;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box #msg-sent {
  margin: 0;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea {
  position: relative;
  width: 100%;
  padding-left: 8px;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea textarea, #__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea #foo {
  position: relative;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  background: var(--users-background);
  color: lightgrey;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea textarea::-webkit-scrollbar, #__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea #foo::-webkit-scrollbar {
  display: none;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea textarea:focus-within, #__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea #foo:focus-within {
  outline: 2px solid rgba(255, 255, 255, 0.8);
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .placeholder {
  position: absolute;
  left: 2rem;
  top: 1rem;
  opacity: 0.5;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .placeholder span.placeholder {
  color: darkgrey;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .placeholder span.recipient {
  color: lightgrey;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .msg-out {
  right: 8px;
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .msg-out:hover svg {
  color: rgba(255, 255, 255, 0.8);
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .-textarea .msg-out::after {
  content: "Send message";
}
#__SQUAD__ #squad_activity #-help- .help-dialogue-box .ico-interactive.pick-up {
  margin: 0 4px;
}
#__SQUAD__ #squad_activity #-resigned- .header {
  margin-bottom: 1.5rem;
}
#__SQUAD__ #squad_activity #-resigned- .header svg {
  color: var(--resign);
  transform: rotate(-90deg) translate(0px, -3px);
}
#__SQUAD__ #squad_activity #-resigned- .header svg path {
  stroke-width: 2;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr {
  width: 100%;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item.open .ico-interactive.msg-in {
  background: var(--resign);
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item.open .ico-interactive.msg-in svg {
  color: var(--background);
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item.open .-msg {
  display: block;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item:not(:last-child) {
  margin-bottom: 8px;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .-user-resigned {
  padding: 4px;
  padding-right: 8px;
  border-radius: 999px;
  cursor: pointer;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .-user-resigned:hover {
  background: var(--low-light);
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .username {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: grey;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .tools {
  margin-left: auto;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .tools .msg-in {
  margin-right: 4px;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .resign-item .tools .msg-in::after {
  content: "See message";
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .-msg {
  display: none;
  position: relative;
  margin: 1rem 0 2rem 0;
  padding: 1em;
  background: var(--resign);
  border-radius: 1rem;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .-msg .-X {
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .-msg .-X:hover {
  background: rgba(0, 0, 0, 0.4);
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .-msg .-X svg {
  color: red;
  height: 20px;
}
#__SQUAD__ #squad_activity #-resigned- .users-arr .-msg p.msg {
  margin: 0;
}
#__SQUAD__ #journalled_today {
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
#__SQUAD__ #journalled_today .--header {
  margin-bottom: 0;
}
#__SQUAD__ #journalled_today #sticky-journals-read {
  padding: 2rem;
}
#__SQUAD__ #journalled_today #sticky-journals-read img {
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
#__SQUAD__ #journalled_today #sticky-journals-unread {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px;
}
#__SQUAD__ #journalled_today #sticky-journals-unread .journal-entry {
  background: salmon;
}
#__SQUAD__ #journalled_today #sticky-journals-unread .journal-entry:hover {
  background: rgba(255, 255, 255, 0.1);
}
#__SQUAD__ #recent_submissions .--header {
  margin-bottom: 5rem;
}
#__SQUAD__ #recent_submissions #gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 8px;
  -moz-column-gap: 8px;
       column-gap: 8px;
  padding: 0 1rem;
  margin: 0 auto 4rem auto;
}
#__SQUAD__ #recent_submissions #gallery.wrapped {
  justify-content: start;
}
#__SQUAD__ #recent_submissions #gallery .-media img, #__SQUAD__ #recent_submissions #gallery .-media video {
  width: 100px;
  box-sizing: border-box;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}
#__SQUAD__ #recent_submissions .entry-user {
  padding: 8px 1rem 1rem 1rem;
}
#__SQUAD__ #recent_submissions .entry-user .-submission-info img.usr {
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
#__SQUAD__ #recent_submissions .entry-user .title {
  text-overflow: ellipsis;
}

.-user {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.-user:hover {
  z-index: 99;
}
.-user:hover .username {
  display: block;
}
.-user img {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.-user .dummy-img {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.-user .dummy-img div {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.-user .username {
  display: none;
  top: calc(100% + 0.5rem);
  font-family: "Fira Code";
  font-size: 14px;
  margin: 0;
  color: #8f4e4e;
  white-space: nowrap;
  background: rgba(247, 247, 247, 0.9);
  z-index: 999;
}

#_users_ {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 0;
  z-index: 9;
}
#_users_.expand #wrapper {
  display: block;
}
#_users_.expand #wrapper #-users {
  overflow: hidden scroll;
  height: 100% !important;
  width: 100% !important;
}
#_users_.expand #wrapper #-users::-webkit-scrollbar {
  width: 6px;
}
#_users_.expand #wrapper #-users::-webkit-scrollbar-track {
  background: none;
}
#_users_.expand #wrapper #-users::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background: indianred;
}
#_users_.expand #wrapper #-users .-user {
  position: relative;
  margin-bottom: 1rem;
}
#_users_.expand #wrapper #-users .-user .username {
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 1rem);
  font-size: 1rem;
  background: unset;
  color: turquoise;
}
#_users_ #wrapper {
  position: relative;
  width: 600px;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 2rem;
  background: var(--users-background);
}
#_users_ #wrapper #-users {
  position: relative;
}
#_users_ #wrapper #-users .-user img, #_users_ #wrapper #-users .-user .dummy-img {
  border: 2px solid transparent;
}
#_users_ #wrapper #-users .-user img:hover, #_users_ #wrapper #-users .-user .dummy-img:hover {
  border-color: rgba(255, 255, 255, 0.8) !important;
}
#_users_ #squad-handles {
  right: calc(100% + 1rem);
}
#_users_ #squad-handles .click-me {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
#_users_ #squad-handles .click-me:not(.yes):hover {
  background: rgba(255, 255, 255, 0.2);
}
#_users_ #squad-handles .click-me.yes.squad {
  background: orangered;
}
#_users_ #squad-handles .click-me.yes.global {
  background: cornflowerblue;
}
#_users_ #squad-handles .click-me svg {
  height: 50%;
  color: var(--background);
  stroke: var(--background);
}

#show-squad {
  visibility: hidden;
  position: fixed;
  bottom: 0;
  padding: 0.5rem 0;
  z-index: 1;
  transition: bottom 0.3s;
}
#show-squad.squad-hidden {
  visibility: visible;
}
#show-squad:hover {
  bottom: 3px;
}
#show-squad:hover svg {
  height: 34px;
}
#show-squad svg {
  height: 2rem;
  color: gold;
  fill: gold;
  transition: height 0.3s;
}

.alcohol.global .trigger {
  color: rgba(240, 230, 140, 0.4);
}
.alcohol.global .trigger:hover {
  color: khaki;
}
.alcohol .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: khaki;
}
.alcohol .-trigger .trigger {
  color: khaki;
}

.boredom.global .trigger {
  color: rgba(192, 192, 192, 0.4);
}
.boredom.global .trigger:hover {
  color: silver;
}
.boredom .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: silver;
}
.boredom .-trigger .trigger {
  color: silver;
}

.depression.global .trigger {
  color: rgba(255, 0, 255, 0.4);
}
.depression.global .trigger:hover {
  color: magenta;
}
.depression .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: magenta;
}
.depression .-trigger .trigger {
  color: magenta;
}

.homework.global .trigger {
  color: rgba(0, 250, 154, 0.4);
}
.homework.global .trigger:hover {
  color: mediumspringgreen;
}
.homework .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: mediumspringgreen;
}
.homework .-trigger .trigger {
  color: mediumspringgreen;
}

.lewd.global .trigger {
  color: rgba(255, 20, 147, 0.4);
}
.lewd.global .trigger:hover {
  color: deeppink;
}
.lewd .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: deeppink;
}
.lewd .-trigger .trigger {
  color: deeppink;
}

.loneliness.global .trigger {
  color: rgba(0, 0, 255, 0.4);
}
.loneliness.global .trigger:hover {
  color: blue;
}
.loneliness .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: blue;
}
.loneliness .-trigger .trigger {
  color: blue;
}

.social_media.global .trigger {
  color: rgba(30, 144, 255, 0.4);
}
.social_media.global .trigger:hover {
  color: dodgerblue;
}
.social_media .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: dodgerblue;
}
.social_media .-trigger .trigger {
  color: dodgerblue;
}

.stress.global .trigger {
  color: rgba(255, 0, 0, 0.4);
}
.stress.global .trigger:hover {
  color: red;
}
.stress .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: red;
}
.stress .-trigger .trigger {
  color: red;
}

.urge.global .trigger {
  color: rgba(255, 0, 255, 0.4);
}
.urge.global .trigger:hover {
  color: fuchsia;
}
.urge .-trigger.first-e::after {
  border: 4px solid transparent;
  border-right-color: fuchsia;
}
.urge .-trigger .trigger {
  color: fuchsia;
}

#CreateCommunity {
  width: 600px;
  margin: 0 auto;
  padding-bottom: 10rem;
}
#CreateCommunity #toolbar {
  position: sticky;
  top: 0;
  height: 22px;
  padding: 3rem 0 1rem 0;
  box-sizing: unset;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-content: center;
}
#CreateCommunity #Create-Community {
  font-size: 1.75rem;
  margin: 0;
  color: turquoise;
  justify-self: center;
}
#CreateCommunity .form {
  margin-top: 4rem;
}
#CreateCommunity .form p {
  color: turquoise;
}
#CreateCommunity .form p.section-label {
  margin: 0 0 8px 0;
  color: darkgrey;
}
#CreateCommunity .form .checked {
  color: darkgrey !important;
}
#CreateCommunity .form .info {
  position: relative;
  cursor: default;
}
#CreateCommunity .form .info[class*=left]::after {
  left: 0;
  transform: none;
}
#CreateCommunity .form .info#i-default::after {
  content: "A static community with no user movement.";
}
#CreateCommunity .form .info#i-launch::after {
  content: "Set your challenge for launch at a future date (For example, monthly challenges can be created in advance to launch on the 1st of the month). Your challenge will be immediately joinable and users can chat in the lobby. Initing a challenge early gives more time for users to join allowing for a bigger squad size.";
}
#CreateCommunity .form .info#i-duration::after {
  content: 'This is the length of time each challenge lasts after which the remaining members have won. For example, if you set the duration to "7 Days," the runtime  of the challenge will be 7 days. The community itself runs indefinitely and continues to host joinable squads unless terminated.';
}
#CreateCommunity .form .info#i-interval::after {
  content: "After your challenge has started, or if a user fails and wants to start over, how soon should a new squad be available? Longer intervals gives more time for users to pool into a single squad but increases wait times.";
}
#CreateCommunity .form .info#i-tags::before {
  background: rgba(152, 251, 152, 0.5);
}
#CreateCommunity .form .info#i-tags::after {
  content: "Make your community easier to find by adding searchable keywords (tags).";
}
#CreateCommunity .form .info#i-admin::before {
  background: rgba(255, 165, 0, 0.3);
}
#CreateCommunity .form .info#i-admin::after {
  content: "Admins have the power to assign and resign moderators, as well as the powers of a moderator. Only you can assign someone as an admin. Only you can change the community settings. You can pass your role of superadmin to another user.";
}
#CreateCommunity .form .info#i-mods::before {
  background: rgba(255, 165, 0, 0.3);
}
#CreateCommunity .form .info#i-mods::after {
  content: "Mods have the power to issue warnings and remove users from the community.";
}
#CreateCommunity .form .info:hover::before {
  height: 100%;
}
#CreateCommunity .form .info::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 40%;
  left: 0;
  bottom: -2px;
  background: rgba(138, 82, 255, 0.54);
  z-index: -1;
  transition: height 0.2s ease-out;
}
#CreateCommunity .form .info::after {
  max-width: 250px;
  padding: 2rem !important;
  white-space: normal;
}
#CreateCommunity .form #CommunityImage {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 2rem auto 3rem auto;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
#CreateCommunity .form #CommunityImage:hover {
  opacity: 0.8;
}
#CreateCommunity .form .selected-modifiers {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 19px;
  grid-auto-rows: 19px;
  grid-gap: 12px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 2rem auto;
  justify-content: center;
  align-items: center;
}
#CreateCommunity .form .selected-modifiers svg.mod {
  height: 19px;
}
#CreateCommunity .form .selected-modifiers svg.mod.type-community {
  height: 15px;
}
#CreateCommunity .form input, #CreateCommunity .form textarea {
  color: turquoise;
  box-sizing: border-box;
}
#CreateCommunity .form input#name {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  width: 100%;
  font-size: 1.75rem;
  padding: 1rem;
}
#CreateCommunity .form input#name:hover::-moz-placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form input#name:hover::placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-bio {
  font-size: 1rem;
  margin-bottom: 3rem;
}
#CreateCommunity .form .-bio #limiter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  height: 4px;
}
#CreateCommunity .form .-bio input#bio {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  width: 100%;
  font-size: 1.25rem;
  padding: 8px 1rem;
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-bio input#bio:hover::-moz-placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-bio input#bio:hover::placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-modifiers {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
}
#CreateCommunity .form .-modifiers .group {
  position: relative;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.1);
  border-radius: 8px;
}
#CreateCommunity .form .-modifiers .group::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 1px;
  height: calc(100% - 1rem);
  background: rgba(128, 128, 128, 0.1);
}
#CreateCommunity .form .-modifiers .group:not(.hovered) .-mod.selected {
  color: gold !important;
  background: var(--low-light);
}
#CreateCommunity .form .-modifiers .group:not(.hovered) .-mod.selected svg {
  fill: gold;
}
#CreateCommunity .form .-modifiers .group:not(.hovered) .-mod.selected p.title {
  color: gold;
}
#CreateCommunity .form .-modifiers .group .-mod {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 5rem;
  border-radius: 8px;
  cursor: pointer;
}
#CreateCommunity .form .-modifiers .group .-mod:hover {
  color: gold !important;
  background: var(--low-light);
}
#CreateCommunity .form .-modifiers .group .-mod:hover svg {
  fill: gold;
}
#CreateCommunity .form .-modifiers .group .-mod:hover p.title {
  color: gold;
}
#CreateCommunity .form .-modifiers .group .-mod.type-event {
  font-size: 1.5rem;
  color: var(--dim);
}
#CreateCommunity .form .-modifiers .group .-mod svg {
  height: 1.5rem;
  fill: var(--dim);
}
#CreateCommunity .form .-modifiers .group .-mod p.title {
  font-family: Segoe UI;
  font-size: 1.25rem;
  font-weight: normal;
  color: var(--dim);
  margin: 0.5em 0 0 0;
}
#CreateCommunity .form .-modifiers .info-modifiers {
  padding: 1rem;
  height: 5rem;
}
#CreateCommunity .form .-modifiers .info-modifiers .info-title {
  font-size: 1.25rem;
  color: darkgrey;
  text-align: center;
  text-transform: capitalize;
}
#CreateCommunity .form .-modifiers .info-modifiers .info-desc {
  color: darkgrey;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE {
  padding: 1rem;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE p.label {
  transform: translateY(50%);
  font-size: 14px;
  margin: 0;
  padding: 0 3px;
  color: darkgrey;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group {
  margin-right: 2rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(128, 128, 128, 0.1);
  border-radius: 8px;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group .-mod {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 8px;
  cursor: pointer;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group .-mod.selected {
  background: var(--low-light);
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group .-mod.beginner {
  font-size: 20px;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group .-mod.type-event {
  font-size: 1.5rem;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .group .-mod svg {
  height: 1.5rem;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .info-modifiers .info-title {
  font-size: 1.25rem;
  color: darkgrey;
  text-align: center;
  text-transform: capitalize;
}
#CreateCommunity .form .PRESERVE-modifiersPRESERVE .info-modifiers .info-desc {
  color: darkgrey;
}
#CreateCommunity .form .common .dummies [class*=dummy-line] {
  background: rgba(255, 255, 255, 0.1);
}
#CreateCommunity .form .common .dummies .-dummy {
  margin-bottom: 2rem;
}
#CreateCommunity .form .common .dummies .-dummy .dummy-line-1 {
  width: 5rem;
  height: 1rem;
}
#CreateCommunity .form .common .dummies .-dummy .dummy-line-2 {
  width: 3rem;
  height: 1rem;
}
#CreateCommunity .form .common .dummies .-dummy .dummy-line-major {
  width: 50%;
  height: 1rem;
}
#CreateCommunity .form .common #common-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 2rem;
}
#CreateCommunity .form .common .-_about svg {
  fill: none;
  color: turquoise;
  height: 13px;
}
#CreateCommunity .form .common .-_about svg:hover {
  fill: turquoise;
}
#CreateCommunity .form .common .-_about svg:hover .guts {
  color: var(--background);
}
#CreateCommunity .form .common p.label {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}
#CreateCommunity .form .common p.value {
  margin-top: 0;
  margin-bottom: 0;
}
#CreateCommunity .form .common .form-field {
  height: 38px;
  margin-top: 2rem;
}
#CreateCommunity .form .common .form-field.type-1 p.label {
  display: inline;
}
#CreateCommunity .form .common .form-field.type-1 .value {
  display: inline-block;
  cursor: pointer;
}
#CreateCommunity .form .common .form-field.type-1 .value:hover {
  opacity: 1 !important;
}
#CreateCommunity .form .common .form-field.type-1 .value:not(.selected) {
  opacity: 0.5;
}
#CreateCommunity .form .common .form-field.type-1:not(.stay-open) .value:not(.selected).always-on-display {
  visibility: hidden;
}
#CreateCommunity .form .common .form-field.type-1:not(.stay-open) .value:not(.selected):not(.always-on-display) {
  display: none;
}
#CreateCommunity .form .common .form-field.type-1:hover .value {
  display: inline-block !important;
}
#CreateCommunity .form .common .document-input-style {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  display: inline-block;
  font-size: 1rem;
  width: 100px;
  padding: 8px;
  border: 2px solid transparent;
  border-radius: 4px;
}
#CreateCommunity .form .common .document-input-style:focus {
  border-color: rgba(138, 82, 255, 0.54);
}
#CreateCommunity .form .common #launch-date {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  color: turquoise;
}
#CreateCommunity .form .common #launch-date #input-date {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  z-index: -1;
}
#CreateCommunity .form .common #launch-date ._calendar {
  height: 1.25rem;
  color: inherit;
  opacity: 0.8;
  cursor: pointer;
}
#CreateCommunity .form .common #launch-date ._calendar:hover {
  opacity: 1;
}
#CreateCommunity .form .common #duration #infinity {
  height: 10px;
  stroke: turquoise;
}
#CreateCommunity .form .common #duration #error-custom {
  margin-top: 0;
  margin-bottom: 0;
  display: inline;
  color: red;
}
#CreateCommunity .form .common #duration #input-custom {
  width: 133px;
}
#CreateCommunity .form .common #tags {
  margin-top: 4rem;
}
#CreateCommunity .form .common #tags p.label {
  display: inline;
}
#CreateCommunity .form .common #tags .tag {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}
#CreateCommunity .form .common #tags .tag p {
  margin-top: 0;
  margin-bottom: 0;
}
#CreateCommunity .form .common #tags .tag .-click-me {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  transform: translateY(calc(-50% + 2px));
  padding-right: 4px;
}
#CreateCommunity .form .common #tags .tag .-click-me .click-me.-X {
  width: 1rem;
  height: 1rem;
}
#CreateCommunity .form .common #tags .tag .-click-me .click-me.-X svg {
  height: 12px;
  color: salmon;
}
#CreateCommunity .form .-moderators {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  box-sizing: border-box;
  margin-top: 5rem;
  border-top: 3px solid rgba(255, 165, 0, 0.8);
  padding: 1rem 2rem;
  min-height: 50px;
}
#CreateCommunity .form .-moderators p.section-label {
  position: absolute;
  left: 1rem;
  top: 0;
  transform: translateY(calc(-50% - 4px));
  padding: 0 4px;
  margin: 0;
  color: rgba(255, 165, 0, 0.8);
}
#CreateCommunity .form .-moderators p.section-label-micro {
  width: -moz-fit-content;
  width: fit-content;
  color: rgba(255, 165, 0, 0.8);
}
#CreateCommunity .form .-moderators .moderator {
  position: relative;
  margin-top: 1rem;
  padding-left: 1rem;
}
#CreateCommunity .form .-moderators .moderator.bzz {
  animation: bzz 0.3s linear forwards;
}
#CreateCommunity .form .-moderators .moderator:hover .click-me.-X {
  visibility: visible;
}
#CreateCommunity .form .-moderators .moderator .click-me.-X {
  visibility: hidden;
  right: calc(100% - 1rem);
  width: 1rem;
  height: 1rem;
}
#CreateCommunity .form .-moderators .moderator .click-me.-X svg.X {
  height: 12px;
  color: rgba(128, 128, 128, 0.5);
}
#CreateCommunity .form .-moderators .moderator img {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
#CreateCommunity .form .-moderators .moderator p.usn {
  margin-top: 0;
  margin-bottom: 0;
  color: grey;
}
#CreateCommunity .form .-moderators .moderator p.usn span {
  font-size: 14px;
  opacity: 0.5;
}
#CreateCommunity .form .-moderators .mod-group#admin {
  margin-bottom: 2rem;
}
#CreateCommunity .form .-moderators .mod-group input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  box-sizing: border-box;
  font-size: 1rem;
  width: calc(100% - 2rem);
  padding: 0 0 8px 8px;
  margin: 2rem 1rem;
  border-bottom: 1px solid transparent;
}
#CreateCommunity .form .-moderators .mod-group input.false {
  cursor: pointer;
}
#CreateCommunity .form .-moderators .mod-group input.false:hover::-moz-placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-moderators .mod-group input.false:hover::placeholder {
  color: rgba(64, 224, 208, 0.7);
}
#CreateCommunity .form .-moderators .mod-group input.true {
  border-color: grey;
}
#CreateCommunity #launch {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 8px 1rem;
  margin: 0 0 0 auto;
  background: var(--dim);
  cursor: default;
}
#CreateCommunity #launch.ready {
  background: palegoldenrod;
  cursor: pointer;
}
#CreateCommunity #launch:not(.ready) {
  opacity: 0.5;
}

#CreateEntry {
  width: 600px;
  padding-top: 2rem;
}
#CreateEntry .form .type {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 1rem 0 3rem 0;
}
#CreateEntry .form .type .click-me {
  width: 6rem;
}
#CreateEntry .form .type .click-me.current {
  background: var(--low-light);
}
#CreateEntry .form .type .click-me.current svg {
  color: cornflowerblue;
}
#CreateEntry .form .type .click-me .posrel {
  transform: translateY(-6px);
}
#CreateEntry .form .type .click-me .posrel svg {
  width: 2rem;
  margin: auto;
  color: grey;
}
#CreateEntry .form .type .click-me .posrel p {
  top: 100%;
  font-size: 14px;
  margin-top: 2px;
}
#CreateEntry .form .title, #CreateEntry .form .body, #CreateEntry .form .img-upload {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
  display: block;
  width: 100%;
  padding: 1rem;
  background: #1e1e1e;
  border-radius: 8px;
  color: grey;
}
#CreateEntry .form #p-give-title {
  left: 8px;
  margin: 0;
}
#CreateEntry .form .title {
  font-size: 1.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}
#CreateEntry .form .body {
  height: 250px;
  font-size: 1rem;
  resize: vertical;
}
#CreateEntry .form .img-upload:not(.preview) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  box-sizing: border-box;
}
#CreateEntry .form img#preview {
  max-width: 600px;
  max-height: 50vh;
  margin: auto;
}
#CreateEntry .form .-submit {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}
#CreateEntry .form .-submit p#change-img-CreateEntry {
  cursor: pointer;
}
#CreateEntry .form .-submit .submit {
  padding: 8px;
  margin: 8px;
  border-radius: 1rem;
  cursor: pointer;
}

#Farewell {
  background: rgba(23, 23, 23, 0.975);
  overflow: hidden scroll;
  z-index: 99997;
}
#Farewell .-m- {
  z-index: -1;
}
#Farewell .content {
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 104px;
}
#Farewell .content .-svg {
  transform: translateY(2px);
  display: inline-block;
  margin-left: 8px;
}
#Farewell .content .-svg.-Spinner svg {
  height: 14px;
  fill: violet;
}
#Farewell .content .-svg.-Tick svg {
  height: 1rem;
  color: violet;
}
#Farewell .content #submitted {
  color: violet;
}
#Farewell .content #submitted svg {
  height: 1rem;
}
#Farewell .content #submitted p {
  color: inherit;
}
#Farewell .content p.l-h {
  font-size: 1.25rem;
  color: turquoise;
}
#Farewell .content p.l-h#p-help-the-community {
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 2em;
}
#Farewell .content p.l-h#p-leave-a-message {
  margin-bottom: 0;
}
#Farewell .content p.l-h#p-some-parting-messages {
  font-size: 1.4rem;
  margin: 2em 0 3em 0;
  padding-top: 2em;
  border-top: 1px solid var(--background-accent-a);
}
#Farewell .content #triggers-menu {
  padding: 1rem;
  padding-left: 0;
  flex-wrap: wrap;
}
#Farewell .content #triggers-menu .trigger-option {
  margin-left: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rebeccapurple;
  border-radius: 4px;
  color: rebeccapurple;
  cursor: pointer;
}
#Farewell .content #triggers-menu .trigger-option.true {
  color: violet;
  border-color: violet;
}
#Farewell .content #triggers-menu .trigger-option.true button {
  color: violet;
}
#Farewell .content #triggers-menu .trigger-option button, #Farewell .content #triggers-menu .trigger-option p {
  padding: 8px 12px;
}
#Farewell .content #triggers-menu .trigger-option button {
  background: none;
  border: none;
  outline: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: rebeccapurple;
  cursor: pointer;
}
#Farewell .content #triggers-menu #trigger-addcustom p {
  margin: 0;
  outline: none;
  color: inherit;
}
#Farewell .content #-leave-a-message {
  margin-bottom: 3rem;
}
#Farewell .content #-leave-a-message .textarea-tools {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 1rem 8px auto;
}
#Farewell .content #-leave-a-message .textarea-tools .word-limiter {
  transform: translateY(2px);
  height: 4px;
  background: violet;
}
#Farewell .content #-leave-a-message .textarea-tools #submit-resign-msg {
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 1rem;
  color: blue;
}
#Farewell .content #-leave-a-message .textarea-tools #submit-resign-msg:not(.msg_exceeded):hover {
  cursor: pointer;
}
#Farewell .content #-leave-a-message .textarea-tools #submit-resign-msg.msg_exceeded {
  color: grey;
  cursor: default;
}
#Farewell .content #-leave-a-message textarea {
  box-sizing: unset;
  resize: vertical;
  height: 22px;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  color: lightgrey;
  background: var(--background-accent-a);
}
#Farewell .content #-leave-a-message textarea::-webkit-scrollbar {
  display: none;
}
#Farewell .content #-leave-a-message .foot-note {
  font-size: 14px;
  margin: 0.5em 0 1em 0;
  opacity: 0.4;
}
#Farewell .content #farewells {
  padding: 0 1rem;
}
#Farewell .content #farewells .-farewell:not(:last-of-type) {
  margin-bottom: 2rem;
}
#Farewell .content #farewells .-farewell img {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  cursor: default;
}
#Farewell .content #farewells .-farewell p {
  margin: 0 0 0 1rem;
  color: lightgrey;
}

#Messages {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  position: fixed;
  background: var(--background);
  z-index: 99995;
}
#Messages #navigation {
  position: relative;
}
#Messages #navigation #newMsg.click-me {
  margin: 8px 8px 8px auto;
  width: 2rem;
}
#Messages #navigation #newMsg.click-me svg {
  height: 1.25rem;
}
#Messages #navigation .messages-menu .message {
  position: relative;
  padding: 1.25rem 8px;
  border-radius: 8px;
  cursor: pointer;
}
#Messages #navigation .messages-menu .message:hover {
  background: var(--low-light);
}
#Messages #navigation .messages-menu .message .username {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  max-width: calc(100% - 1rem);
}
#Messages #navigation .messages-menu .message .indicator-unread {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-family: Consolas;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  color: orangered;
}
#Messages #navigation .messages-menu .separator {
  display: none;
  width: 100%;
  margin: 2px 0;
  border-color: var(--low-light);
}
#Messages .conversation-window {
  width: 600px;
  height: calc(100vh - 104px - 2rem);
  box-sizing: border-box;
  margin: 104px auto 0 auto;
  padding: 1rem;
  border: 1px solid var(--low-light);
  border-radius: 2rem;
}
#Messages .conversation-window.openMsg {
  border: 1px solid var(--dim);
}
#Messages .conversation-window .usn {
  margin-bottom: 2px;
}
#Messages .conversation-window .-textarea {
  padding-bottom: 0;
}

#Trigger {
  width: 100%;
  background: rgba(247, 247, 247, 0.98);
  box-sizing: border-box;
  padding: 104px 0;
  z-index: 999;
}
#Trigger .content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 1rem;
  justify-items: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid lightgrey;
}
#Trigger .content p#trigger-id {
  font-family: Segoe UI Semibold;
  font-size: 18px;
  margin: 0 auto;
}
#Trigger .content .left p, #Trigger .content .right p {
  position: relative;
  font-family: Segoe UI Bold;
  text-align: center;
  margin: 0;
}
#Trigger .content .left p span, #Trigger .content .right p span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  font-family: Segoe UI;
  font-size: 1.25rem;
  min-width: 150px;
}
#Trigger .content .left p {
  font-size: 3rem;
  color: salmon;
}
#Trigger .content .right p {
  font-size: 3rem;
  color: cornflowerblue;
}
#Trigger .content p#unicode {
  font-size: 10rem;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
}
#Trigger .content p#unicode.lewd {
  transform: translateY(-1rem);
}
#Trigger .take-aways {
  margin-top: 2rem;
}
#Trigger .take-aways .take-away {
  border-radius: 4px;
  width: 300px;
  height: 1.25rem;
  margin-top: 1rem;
}
#Trigger .-discussion {
  margin-top: 2rem;
}
#Trigger .-discussion .discussion {
  border-radius: 4px;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
}

#UserProfile {
  padding-top: 104px;
}
#UserProfile #focus {
  display: grid;
  grid-template-rows: min-content min-content auto;
  height: 100%;
  box-sizing: border-box;
}
#UserProfile #focus .-img {
  padding-bottom: 2rem;
  justify-self: center;
  z-index: 999;
}
#UserProfile #focus .-img img, #UserProfile #focus .-img .dummy-img {
  min-width: 30vh;
  min-height: 30vh;
  max-width: 30vh;
  max-height: 30vh;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--low-light);
  cursor: default;
}
#UserProfile #focus #-user-items {
  margin: 0 auto;
}
#UserProfile #focus #-user-items p {
  font-family: "Fira Code";
}
#UserProfile #focus #-user-items p#user-items {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1em auto;
}
#UserProfile #focus #-user-items span.usn {
  font-family: Segoe UI Semibold;
  color: grey;
  cursor: pointer;
  font-family: "Fira Code";
  font-size: 20px;
  cursor: default;
}
#UserProfile #focus #-user-items span.usn .drop {
  width: 100%;
  padding-top: 1rem;
}
#UserProfile #focus #-user-items span.usn p#report {
  width: -moz-fit-content;
  width: fit-content;
  font-family: Consolas;
  font-size: 15px;
  margin: 0;
  padding: 3px;
  border-radius: 2px;
  background: grey;
  cursor: pointer;
  opacity: 0.9;
  white-space: nowrap;
}
#UserProfile #focus #-user-items span.usn p#report:hover {
  background: red !important;
}
#UserProfile #focus #-user-items span.isSquadie img {
  height: 15px;
}
#UserProfile #focus #-user-items span.flag {
  position: relative;
  display: inline-block;
  width: 1.4rem;
  height: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  line-height: 1em;
  margin-left: 8px;
}
#UserProfile #focus #-user-items span.flag::before {
  content: " ";
}
#UserProfile #focus #-user-items span.iso {
  position: relative;
  color: salmon;
  cursor: default;
}
#UserProfile #focus #feed-user {
  padding: 3rem 0;
}
#UserProfile #focus #feed-user .UserProfile img {
  max-height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
#UserProfile #focus #feed-user #smile {
  width: 2.5rem;
  color: #2D2D2D;
}

#_404 {
  width: 100vw;
  height: 100vh;
  background: dimgrey;
}
#_404 p {
  font-family: "Consolas";
  font-size: 20vh;
  font-weight: bold;
  margin: 0;
  color: white;
  opacity: 0.3;
}

@media (orientation: landscape) {
  #entry_proper .entry-user.isJournal {
    padding: 3rem;
    border: 1px solid var(--background-accent-a);
    border-radius: 2rem;
  }
  .nav {
    padding: 0.5rem 2rem 0.5rem 0;
  }
  .nav:hover p {
    left: 4px;
    opacity: 1;
  }
  .nav p {
    opacity: 0;
    transition: opacity 0.3s ease-out, left 0.3s ease-out;
  }
  #-logo #-HomeSVG.click-me {
    left: calc(100% + 1rem);
    width: 2.5rem;
    height: 2.5rem;
  }
  #-logo #-HomeSVG.click-me::after {
    content: "Home";
  }
  #-logo #-HomeSVG.click-me svg {
    height: 1.5rem;
    fill: none;
    color: indianred;
  }
  #profile-menu {
    border-left: 1px solid turquoise;
  }
  #-HOME- #section-communities {
    position: fixed;
    width: 400px;
    padding: 1rem 0;
    border-radius: 1rem;
  }
  #-HOME- #section-communities .header {
    font-size: 25px;
    color: var(--dim);
  }
  #-HOME- #section-communities .community.--my {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 1.5rem;
    background: var(--low-light);
  }
  #-HOME- #section-communities .community.--my p.t {
    font-size: 19px;
  }
  #__SQUAD__ {
    padding-top: 2rem;
    padding-bottom: 33vh;
  }
  #__SQUAD__ #gallery {
    grid-template-columns: repeat(4, 1fr);
  }
  #_users_ {
    background: rgba(var(--background-rgb), 0.8);
  }
  #_users_.expand {
    height: 100vh;
  }
  #_users_ #squad-handles {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  #_users_ .right-side-tools {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 1rem);
    box-sizing: border-box;
    height: 100%;
    padding: 1rem 0;
  }
  #_users_ .right-side-tools p {
    font-size: 15px;
  }
  #_users_ .right-side-tools svg {
    width: 15px;
  }
  #_users_ .right-side-tools .click-me-after svg {
    color: var(--dim);
  }
  #_users_ .right-side-tools .click-me-after svg path {
    stroke-width: 2;
  }
  #_users_ .right-side-tools .click-me-after:hover svg {
    color: lightgrey;
  }
  #_users_ .right-side-tools .click-me-after::after {
    width: 1.5rem;
    height: 1.5rem;
  }
  #_users_ .right-side-tools #squad-min-max {
    position: absolute;
    top: 2rem;
  }
  #_users_ .right-side-tools #squad-min-max p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 21px;
    color: var(--dim);
  }
  #_users_ .right-side-tools #squad-min-max p:hover {
    color: lightgrey;
  }
  #_users_ .right-side-tools .-squad-stroke svg {
    width: 1rem;
  }
  #_users_ .right-side-tools #_reload {
    position: absolute;
    bottom: 2rem;
  }
  #_users_ #-users.overflow {
    overflow: scroll hidden;
    padding-bottom: calc(1rem + 6px);
  }
  #_users_ #-users.overflow::-webkit-scrollbar {
    height: 6px;
  }
  #_users_ #-users.overflow::-webkit-scrollbar-track {
    background: none;
  }
  #_users_ #-users.overflow::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: indianred;
  }
  #show-squad {
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  #FEED.noEntries {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
  }
  #FEED.noEntries p {
    font-size: 2rem;
    margin: auto;
  }
}
@media (orientation: portrait) {
  body::-webkit-scrollbar {
    display: none;
  }
  [class*=ps-]::after {
    border-radius: 999px;
    padding: 7px 8px 8px 8px;
    font-size: 17px;
  }
  .back {
    display: none;
  }
  .clear-toolbars {
    margin-top: 129.8px !important;
  }
  .clear-navbar {
    padding-top: 77px !important;
  }
  .clear-navbar-1 {
    padding-top: calc(77px + 1rem) !important;
  }
  .-ArrowBack {
    position: relative;
  }
  .-ArrowBack::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
  }
  .-ArrowBack svg {
    height: 2rem;
    color: white !important;
  }
  #entry_proper {
    padding: calc(77px + 1rem) 1rem 6rem 1rem;
  }
  #entry_proper .media-wrapper img {
    width: 100vw;
    max-height: unset;
    -o-object-fit: contain;
       object-fit: contain;
  }
  #entry_proper .media-wrapper video {
    width: 100vw;
    max-height: calc(100vh - 36px - 48px);
    -o-object-fit: contain;
       object-fit: contain;
    background: black;
  }
  .global-rank {
    display: inline-block;
  }
  .global-rank > div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--dim);
  }
  .global-rank > div:not(:last-of-type) {
    margin-bottom: 4px;
  }
  .global-rank > div.current {
    background: cornflowerblue;
  }
  .ico-interactive svg {
    height: 24px;
  }
  .quick-view {
    top: 0;
    width: 100vw;
    box-sizing: border-box;
  }
  .-rules {
    position: relative;
    flex: 1;
  }
  .-rules .rule-about {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% - 8px);
    width: -moz-max-content;
    width: max-content;
    max-width: 90vw;
    font-size: 14px;
    padding: 8px;
    margin: 0;
    border-radius: 8px;
    background: salmon;
  }
  .backing {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99998;
  }
  .backing.fixed {
    position: fixed;
  }
  #ibox .info {
    width: 90%;
  }
  #-logo {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    left: 1rem;
    top: 38.5px;
    transform: translateY(-50%);
    height: 1.75rem;
  }
  #-logo img, #-logo svg {
    min-width: 1.75rem;
    width: 1.75rem;
    min-height: 1.75rem;
    height: 1.75rem;
  }
  #mobile-navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 77px;
    z-index: 99996;
  }
  #mobile-navbar .title {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Segoe UI Semibold;
    font-size: 18px;
    padding: 6px 8px 8px 8px;
    border-radius: 8px;
    white-space: nowrap;
    cursor: pointer;
  }
  #mobile-navbar .title#general-chat-title {
    color: steelblue !important;
  }
  #navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #171717 !important;
    z-index: 999999;
  }
  #navigation .-squad-options {
    margin: 4rem auto;
  }
  #navigation .-squad-options .-mod {
    width: 8rem;
    padding: 1rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.02);
  }
  #navigation .-squad-options .-mod.inactive p, #navigation .-squad-options .-mod.inactive svg {
    opacity: 0.5;
  }
  #navigation .-squad-options .-mod p {
    font-family: "Fira Code";
    font-size: 1rem;
    margin-bottom: 0;
    color: var(--dim);
  }
  #navigation .-squad-options .-mod.squad.inactive svg {
    color: var(--dim);
  }
  #navigation .-squad-options .-mod.squad:not(.inactive) p {
    color: orangered;
  }
  #navigation .-squad-options .-mod.global {
    margin-left: 1rem;
  }
  #navigation .-squad-options .-mod.global.inactive svg {
    stroke: var(--dim);
  }
  #navigation .-squad-options .-mod.global:not(.inactive) p {
    color: cornflowerblue;
  }
  #navigation .-squad-options svg {
    height: 4rem;
  }
  #navigation .menu-items-wrapper {
    padding-top: 2rem;
  }
  #navigation .menu-items-wrapper #menu_item_community img {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  #Profile {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    right: 1rem;
    top: 38.5px;
    transform: translateY(-50%);
  }
  #Profile img {
    min-width: 1.75rem;
    min-height: 1.75rem;
    max-width: 1.75rem;
    max-height: 1.75rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: none;
  }
  #Profile #dms {
    right: calc(100% + 12px);
  }
  #-squad-option-navbar {
    position: fixed;
    top: 8px;
    z-index: 99999;
  }
  #-squad-option-navbar .community img {
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  #-squad-option-navbar .feed svg {
    color: salmon;
    height: 3.75rem;
    margin-top: 0.125rem;
  }
  #-squad-option-navbar svg, #-squad-option-navbar img {
    height: 4rem;
  }
  #toolbar, .filter-options {
    top: 77px;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
  #toolbar .nav-pointer, .filter-options .nav-pointer {
    transform: translateY(-1.5px);
  }
  #toolbar .navlink, .filter-options .navlink {
    font-size: 1rem;
    transform: translateY(-1px);
  }
  #toolbar .community-svg svg, .filter-options .community-svg svg {
    height: 20px;
  }
  #toolbar #-stats, .filter-options #-stats {
    margin-right: 0;
  }
  #toolbar #-stats p.stat.id-current::after, .filter-options #-stats p.stat.id-current::after {
    top: calc(100% - 5px);
  }
  #-logo-tools {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1rem;
    top: 12px;
    width: 2rem;
    height: 2rem;
  }
  #-logo-tools #menu {
    height: 1.5rem;
  }
  #profile-menu, .main-menu {
    width: 100vw;
    box-sizing: border-box;
    padding: 0 3rem;
    z-index: 999999;
  }
  #profile-menu .-X, .main-menu .-X {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  #profile-menu .-X svg, .main-menu .-X svg {
    height: 1.5rem;
    color: mintcream;
  }
  #profile-menu .menu-items-wrapper, .main-menu .menu-items-wrapper {
    padding: 0;
    border-radius: unset;
    background: unset !important;
  }
  #profile-menu .menu-items-wrapper > div, .main-menu .menu-items-wrapper > div {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    padding: 1rem 1.5rem;
  }
  #profile-menu .menu-items-wrapper > div:hover, .main-menu .menu-items-wrapper > div:hover {
    background: none;
  }
  #profile-menu .menu-items-wrapper > div#developer svg, .main-menu .menu-items-wrapper > div#developer svg {
    height: 22px;
  }
  #profile-menu .menu-items-wrapper > div p, .main-menu .menu-items-wrapper > div p {
    margin-left: 1rem;
  }
  #-ROUTERJS-[theme=dark] #mobile-navbar {
    background: rgba(23, 23, 23, 0.99);
  }
  #-HOME- {
    width: 100vw;
    padding: 0 8px;
  }
  #-HOME- .-search {
    margin: calc(77px + 2rem) 0 0 0;
  }
  #-HOME- .-search input.search {
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    padding-left: calc(2rem + 24px);
  }
  #-HOME- .-search svg {
    height: 1.5rem;
  }
  #-HOME- #section-tags {
    width: calc(100vw - 1rem);
    margin: 1rem auto 2rem auto;
  }
  #-HOME- #section-tags p {
    padding: 0;
  }
  #-HOME- #bigSearch .tag {
    margin-top: 1em;
  }
  #-HOME- .grid {
    display: grid;
    grid-template-areas: "g-communities" "g-l1" "g-l2" "g-l3";
    grid-template-columns: unset;
    grid-auto-flow: row;
    grid-gap: 2rem;
  }
  #-HOME- .grid .grid-gap-bottom {
    margin-bottom: 2rem;
  }
  #-HOME- #-footer #footer {
    box-sizing: border-box;
    width: calc(100vw - 16px);
  }
  #-HOME2- {
    margin-top: calc(77px + 2rem);
    padding: 0;
    width: 100vw;
  }
  #-HOME2- .community p.sub {
    max-width: calc(100vw - 7rem);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #-HOME2- #footer {
    padding: 1rem;
  }
  #CommunityLanding {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-bottom: 0;
  }
  #CommunityLanding .exhibit .continue {
    top: 0;
  }
  .App {
    width: 100vw;
  }
  #CHAT {
    padding: 0 1rem;
  }
  #CHAT[expand=true] {
    margin: 0;
  }
  #CHAT .chat-menu {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: unset;
    border-radius: unset;
    background: unset;
  }
  #CHAT .chat-menu .-chat-items .separator.first {
    margin-top: 0;
  }
  #CHAT .chat-menu .-chat-items #chats-tools {
    font-size: 18px;
    padding: 2rem 0;
  }
  #CHAT .chat-menu .-chat-items .-chat-item .title {
    font-size: 19px;
  }
  #CHAT .chat-menu .-chat-items .-chat-item p:not(.title) {
    margin-left: 4px;
  }
  #CHAT .-chat #chat {
    padding: 0 8px;
  }
  #CHAT .-chat #chat .__msg ._msg p.msg {
    font-size: 1rem;
  }
  #CHAT .-chat #chat .__msg ._msg p.msg span.usn {
    font-size: 1rem;
  }
  #CHAT .-chat .-textarea {
    padding: 2rem 0;
  }
  #CHAT .-chat .-textarea textarea {
    font-size: 18px;
    height: 24px;
  }
  #CHAT .-chat .-textarea textarea::-moz-placeholder {
    font-size: 18px;
  }
  #CHAT .-chat .-textarea textarea::placeholder {
    font-size: 18px;
  }
  #__SQUAD__ {
    padding-top: 127px;
    padding-bottom: 33vh;
  }
  #__SQUAD__ #StartingIn #p-starting-in {
    font-size: 2rem;
  }
  #__SQUAD__ .-user img {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  #__SQUAD__ .sticky .--header {
    margin-bottom: 5rem;
  }
  #__SQUAD__ .sticky .--header svg {
    height: 2rem;
  }
  #__SQUAD__ #squad_overview {
    border-top: none;
    padding-top: 4rem;
  }
  #__SQUAD__ #squad_overview .--header img {
    width: 2.5rem;
    height: 2.5rem;
  }
  #__SQUAD__ #squad_overview .--header .squad-id {
    font-size: 3rem;
  }
  #__SQUAD__ #squad_overview #-overview- {
    grid-column-gap: 4rem;
  }
  #__SQUAD__ #squad_overview #-overview- .stat {
    font-size: 2em !important;
  }
  #__SQUAD__ #squad_activity {
    padding-left: 3rem;
  }
  #__SQUAD__ #squad_activity .squad-activity-section {
    margin-bottom: 1rem;
  }
  #__SQUAD__ #squad_activity .ps-top::after {
    left: unset;
    transform: none;
    right: 0;
  }
  #__SQUAD__ #journalled_today {
    padding: 1rem;
    border-radius: 0;
  }
  #__SQUAD__ #journalled_today #sticky-journals-unread {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 0.5rem 1rem;
  }
  #__SQUAD__ #journalled_today #sticky-journals-unread .journal-entry img {
    min-width: 1.75rem;
    min-height: 1.75rem;
    max-width: 1.75rem;
    max-height: 1.75rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  #__SQUAD__ #recent_submissions {
    padding: 0 0.5rem;
  }
  #__SQUAD__ #recent_submissions #gallery {
    padding: 0 1rem;
  }
  #__SQUAD__ #recent_submissions .--entry-preview.Squad {
    padding: 0.5rem 0;
  }
  .-user img {
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .-user .dummy-img {
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .-user .dummy-img div {
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  #_users_ {
    overflow: scroll hidden;
    bottom: 0;
    width: 100vw;
    padding: 0;
    background: var(--users-background);
    transform: none;
    z-index: 999999;
  }
  #_users_::-webkit-scrollbar {
    height: 6px;
  }
  #_users_::-webkit-scrollbar-track {
    background: none;
  }
  #_users_::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: indianred;
  }
  #_users_::-webkit-scrollbar {
    height: 4px !important;
  }
  #_users_::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
  #_users_.squad-hidden {
    transform: translateY(100%);
  }
  #_users_.block #wrapper {
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    bottom: 7rem;
  }
  #_users_.expand {
    display: grid !important;
    grid-template-rows: 1fr auto 1fr;
    padding: 0;
    z-index: 999999;
  }
  #_users_.expand #credentials, #_users_.expand .tools {
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    transform: none;
  }
  #_users_.expand #credentials .squad-id {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  #_users_.expand #credentials .squad-id::after {
    display: none;
  }
  #_users_.expand #wrapper {
    height: auto;
    padding: 0 1rem;
    overflow-y: scroll;
  }
  #_users_.expand #wrapper::-webkit-scrollbar {
    width: 6px;
  }
  #_users_.expand #wrapper::-webkit-scrollbar-track {
    background: none;
  }
  #_users_.expand #wrapper::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: indianred;
  }
  #_users_.expand .tools {
    padding: 2rem 0;
  }
  #_users_ #credentials {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3rem;
    position: fixed;
    width: -moz-fit-content;
    width: fit-content;
    justify-self: center;
    z-index: 1;
  }
  #_users_ #credentials.squad .squad-id {
    color: orangered;
  }
  #_users_ #credentials.global .squad-id {
    position: relative;
    color: dodgerblue;
  }
  #_users_ #credentials.global .squad-id::after {
    content: "(global)";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    font-size: 1rem;
    color: var(--dim);
  }
  #_users_ #credentials img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  #_users_ #credentials p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.25rem;
  }
  #_users_ #wrapper {
    min-width: 100%;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0;
    padding: 0 1rem;
    background: none;
  }
  #_users_ .tools {
    position: fixed;
    bottom: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
  }
  #_users_ .tools #squad-handles {
    display: flex;
    justify-self: center;
  }
  #_users_ .tools #squad-handles .click-me:not(.yes) {
    background: var(--dim);
  }
  #_users_ .tools .click-me {
    width: 2.5rem;
    height: 2.5rem;
  }
  #_users_ .tools .click-me svg {
    color: var(--users-background) !important;
    stroke: var(--users-background) !important;
  }
  #_users_ .tools #_expand_ {
    margin: 0 auto 0 2rem;
  }
  #_users_ .tools #_expand_.active {
    background: white;
  }
  #_users_ .tools #_expand_.active svg {
    stroke: var(--background);
  }
  #_users_ .tools #_expand_ svg {
    height: 40%;
    stroke: rgba(255, 255, 255, 0.15);
  }
  #_users_ .tools #_collapse_ {
    margin: 0 2rem 0 auto;
  }
  #_users_ .tools #_collapse_.active {
    background: white;
  }
  #_users_ .tools #_collapse_.active svg {
    color: var(--background);
  }
  #_users_ .tools #_collapse_ svg {
    height: 50%;
    color: rgba(255, 255, 255, 0.15);
  }
  #show-squad {
    visibility: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.5rem 0;
    z-index: 1;
    transition: opacity 0.5s linear;
  }
  #show-squad.squad-hidden {
    visibility: visible;
  }
  #show-squad svg {
    height: 2rem;
    color: gold;
    fill: gold;
  }
  #COMMUNITY {
    padding: 0 1rem;
    padding-bottom: 3rem;
  }
  #Trigger {
    box-sizing: border-box;
    width: 100vw;
    padding: calc(77px + 2rem) 1rem;
  }
  #Trigger .content {
    grid-template-columns: 55% 45%;
    grid-gap: 0;
    box-sizing: border-box;
    padding: 0 1rem 2rem 1rem;
  }
  #Trigger .content .left p, #Trigger .content .right p {
    font-size: 2rem;
    text-align: left;
  }
  #Trigger .content .left p span, #Trigger .content .right p span {
    position: unset;
    left: unset;
    transform: unset;
    display: block;
    font-size: 1rem;
    min-width: unset;
  }
  #Trigger .content .left {
    margin-top: 1rem;
  }
  #Trigger .content p#trigger-id {
    text-align: center;
  }
  #Trigger .content p#unicode {
    font-size: 8rem;
  }
  #Trigger .content p#unicode.lewd {
    transform: unset;
  }
  #-LADDER- #container {
    padding: calc(77px + 1rem) 0 1.5rem;
  }
  #-LADDER- #container #ctr-1 #-options {
    left: 3rem;
  }
  #UserProfile {
    width: 100vw;
    padding-top: 77px;
  }
  #UserProfile #focus .-img {
    padding: 2rem 0;
  }
  #UserProfile #focus #feed-user {
    padding: 3rem 1rem;
  }
}/*# sourceMappingURL=App.css.map */