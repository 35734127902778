@import '../global'

$smaller: 15px
$smallest: 14px
$tiny: 13px
$bigger: 17px
$biggest: 18px

$background: #1e2a43
$color: color-mix(in srgb, white 66%, $background)
$project-id-color: transparentize(ghostwhite, .44)

$low-light: transparentize(white, .9)

$page-width: 600px
$ui-elem-height: 1.5rem


@mixin b-color2()
    color: white
    mix-blend-mode: overlay


@keyframes dip
    0%
        transform: translate(-50%, -100%)
    100%
        transform: translate(-50%, 100%)
@keyframes heartbeat
  0%, 100%
    transform: scale(1)
  25%, 75%
    transform: scale(1.1)
@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)


#x
    position: fixed
    left: 0
    top: 50%
    transform: translateY(-50%)
    width: 100vw
    height: 2px
    background: yellow

//  colors
.b-background
    background: $background
.b-color
    color: $background
//  code colors
.bool
    color: orange !important
.other
    color: #569CD6 !important

//  global

.tiny
    font-size: $tiny !important

// elements

.b-ui-element
    position: fixed
    cursor: pointer

    &#bArrowBack
        left: 1rem
        top: 1rem

        svg
            fill: none !important
    &#bX, &#bX-Landing
        right: 1rem
        top: 1rem
    &#bX-Landing
        transform: rotate(180deg)

    svg
        height: $ui-elem-height
        color: white !important
.meta p
    @include noselect()
    margin: .5em 0
    color: $project-id-color
    text-align: center
    // text-decoration: dotted underline
    // text-decoration-color: transparentize(white, .8)
    cursor: pointer
    &:hover
        text-decoration: dotted underline
        // text-decoration-color: transparentize(white, .8)
#project_id
    @include noselect()
    // font-family: 'pixel' !important
    font-size: 1.5rem
    font-weight: bold
    margin: 0
    color: $project-id-color
    text-align: center
.ui-handle
    &.--fill svg
        fill: $project-id-color !important



#BetaLanding
    @include x0y0
    @include full-page
    position: fixed
    display: flex
    background: $background
    overflow-y: scroll
    z-index: 999999

    &::-webkit-scrollbar
        width: 4px

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-thumb
        // border-radius: 999px
        background: transparentize(white, .5)

    #-About
        @include x
        position: fixed
        top: 1rem
        
        svg
            height: $ui-elem-height
            color: white

    .content
        width: $page-width
        height: fit-content
        margin: auto

        p
            font-family: "Fira Code", monospace

            &.section-h
                width: fit-content
                font-weight: 700
                font-size: 1.5rem

            // &.section-h, span.blocky-underline
            //     position: relative

            //     &::after
            //         content: ''
            //         position: absolute
            //         left: 0
            //         bottom: 0
            //         width: 100%
            //         height: 40%
            //         z-index: -1
            //         background: #ffb2ff30

            &.sub
                font-size: $smaller
                margin: 2em 0
                color: $color

                span
                    font-weight: bold

                .svg-triangle
                    display: inline-block
                    height: 8px
                    fill: $color

        #FVH
            p
                color: $color

                &.version
                    @include b-color2
                    font-weight: bold
                    margin-top: 3em

                    span
                        font-size: $smaller
                        font-weight: normal

#BetaPortal
    @include x0y0
    position: fixed
    display: none
    width: 100vw
    background: $background
    overflow-y: scroll
    z-index: 999999

    &.open
        display: block

    &::-webkit-scrollbar
        width: 4px

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-thumb
        // border-radius: 999px
        background: transparentize(white, .5)

    // START common styles & elems

    .-ui-handle // remove me
        @include f-ja
        position: fixed
        width: 3rem
        height: 3rem
        border-radius: 50%
        background: transparentize(white, .9)
        cursor: pointer

        &#-back
            position: absolute
            left: 1rem
            top: calc(1rem + 4px)
            width: 2.5rem
            height: 2.5rem

            svg
                fill: $background
                height: 1.25rem

        &:hover
            background: transparentize(white, .8)

        svg
            height: 1.5rem
            color: inherit
    p
        font-family: "Fira Code", monospace

        span.inline-report
            color: salmon
    .-tick
        position: relative

        &::after
            content: 'saved'
            color: $background
            background: indianred
    ._tick_
        height: 10px
        fill: palegreen
    .-Spinner svg
        vertical-align: middle
    .Spinner
        height: 1rem
        fill: palegreen
        animation: spin 1s linear infinite
    .response
        &.--left
            @include y
            right: $out-8

        p
            white-space: nowrap
            font-size: $smallest
            margin-left: 4px
            color: palegreen
    .beta-button
        font-size: $smaller
        text-align: center
        padding: 12px
        border-radius: 8px
        color: $background
        opacity: .33
        cursor: default

        &.ready
            opacity: 1
            cursor: pointer

        &.small
            font-size: 13px
            padding: 8px
    textarea
        display: block
        box-sizing: border-box
        height: 250px
        min-height: 250px
        font-family: "Fira Code", monospace
        font-size: 14.5px
        padding: 12px
        border: 1px solid transparent
        border-radius: 4px
        color: darkgrey
        resize: vertical

    // END common styles & elems

    #thank-you
        top: 0
        transform: translate(-50%, -100%)
        animation: dip 1s ease-out forwards
        font-family: 'pixel'
        font-size: 1.5rem
        margin: 0
        padding: 1.25rem
        border-radius: 1.25rem
        color: $background
        background: transparentize(turquoise, .1)

    #PatchNotes
        height: 100%
        box-sizing: border-box
        padding: 5rem 2rem 1rem 2rem
        background: black

        p
            color: $color

    .content
        display: flex
        flex-direction: column
        box-sizing: border-box
        height: 100%

        #project_id
            font-family: 'pixel' !important
            font-size: 3rem

        #love
            p
                white-space: nowrap
            #heart
                @include noselect()
                cursor: pointer

                &.loved
                    animation: heartbeat 1s ease-in-out 2

                svg

                    height: 5rem
                    fill: red

        .feedback-handles
            bottom: 2rem
            text-align: center

        .bugs-meta
            margin-top: 2rem
            padding: 0 2rem
            color: $color

            #patch-notes
                width: fit-content
     
        // .section
        //     .section-start
        //         margin-bottom: 2rem

        //         &#handleFeedback.active
        //             svg, p
        //                 font-style: normal
        //                 mix-blend-mode: unset
        //                 color: cornflowerblue

        //         &#handleBugs.active
        //             svg, p
        //                 font-style: normal
        //                 mix-blend-mode: unset
        //                 color: red

        //         svg, p
        //             @include b-color2()

        //         svg
        //             transform: translateY(-1px)
        //             height: 19px
        //             margin: 0 12px

        //         p
        //             @include no-marj()
        //             font-family: 'Consolas'
        //             font-size: 20px
        //             font-weight: bold
        //             font-style: italic

        //     .section-body
        //         margin-top: 4rem

        //         p
        //             font-size: $smaller

        //     .handleSection
        //         font-size: $smaller
        //         font-weight: bold
        //         max-width: fit-content
        //         margin: auto
        
        textarea
            background: #ffffff0f
            padding: 2rem
            border-radius: 2rem
            color: ghostwhite

            &::placeholder
                color: transparentize(white, .66)

        p.submit
            position: relative
            font-size: $smaller
            width: fit-content
            margin: 8px auto 0 auto
            color: white
            opacity: .3
            cursor: pointer

            &:hover
                opacity: .6

        #Feedback_Suggestions
            padding-bottom: 1rem

            .handleSection
                color: cornflowerblue

        #Report_Bugs
            // start buttons
            #-report-bugs
                position: absolute
                right: 2rem
                top: 0

                .report-bugs
                    width: fit-content
                    font-size: 13px
                    padding: 8px
                    margin: 0
                    border-radius: 8px
                    background: yellow
                    cursor: pointer

                    p
                        @include no-marj()
            .beta-button
                background: red
            // end buttons

            #caught-bugs
                margin: 1.5rem 0 2.5rem 1rem

                .-caught-bug
                    position: relative
                    width: fit-content

                    &:hover .-trash-
                        visibility: visible

                    .caught-bug
                        padding: 0 .5rem
                        // width: fit-content
                        border-radius: 8px
                        cursor: pointer

                        svg
                            height: 18px
                            color: inherit

                        p
                            white-space: nowrap
                            max-width: 300px
                            color: $color
                            margin: .5em 0 .5em .5rem
                            overflow: hidden
                            text-overflow: ellipsis

                    .-trash-
                        @include y
                        left: 100%
                        padding-left: 8px
                        visibility: hidden

                        .-trash
                            position: relative
                            cursor: pointer

                            // &::after
                            //     content: 'delete'

                            svg
                                height: 18px
                                color: transparentize(white, .66)
            
            p.submit
                opacity: .6
                color: #ff7c7c

                &:hover
                    opacity: 1



@media (orientation: landscape)
    #BetaLanding
        .content
            .section
                .section-body
                    padding-left: 8px

            #FVH
                // padding-top: $clear-toolbar

    #BetaPortal
        .wrapper
            width: 600px
            margin: 0 auto
            padding-top: 4rem

            .content
                .section
                    .section-start
                        padding: 0 2rem
                        cursor: pointer
                    .section-body
                        padding: 0 2rem

                #project_id
                    margin-bottom: 4rem

                #Rate .-ratings
                    padding: 1rem 3rem
                #Report_Bugs
                    #-textarea-bugs #bug-prompt
                        background: $background


@media (orientation: portrait)
    $page-width: 100vw

    .beta-y-scroll
        &::-webkit-scrollbar
            width: 4px

        &::-webkit-scrollbar-thumb
            border-radius: 0

    #BetaLanding
        width: $page-width
        overflow-x: hidden

        .content
            width: $page-width
            box-sizing: border-box
            padding: 2rem

            .section
                &#announcement p:not(.section-h)
                    font-size: $smaller

            .meta p
                font-size: $biggest

            #devices-rating
                .-device
                    svg
                        height: 21px
                    
                    .p-rating
                        font-size: $biggest

            .user-cred
                p#tester
                    font-size: $biggest

            #continue
                position: fixed
                left: 50%
                bottom: 1.5rem
                transform: translateX(-50%)
                width: fit-content
                font-size: 1.25rem
                padding: .5rem 1rem
                margin: 0
                border-radius: 999px
                background: white
                z-index: 999

    #BetaPortal
        box-sizing: border-box
        width: $page-width

        .content
            padding: 3rem 1rem 0 1rem

            .meta
                #project_id
                    font-size: 1.5rem

                #devices-rating
                    margin: 1rem 0 3rem 0

                    > div 
                        .-device-svg svg
                            height: 21px

                        .p-rating
                            font-size: $biggest

            .section
                margin-top: 4rem

            #love
                p
                    font-size: $biggest
                #heart svg
                    height: 7rem

            .feedback-handles p
                font-size: $biggest

            #Report_Bugs
                #handleReportBugs
                    margin: 3rem auto 1rem auto

            .-feedback textarea
                height: calc(100vh * 0.33)
