// :)
// notes 🗒️
// ⚠️ (notice)
// !! (do me)
// ?? (investigate)
// 👀 / 00 (eye-balled)
// ...

// > DARK MODE

    
@import 'colors'
@import 'global'


@font-face
    font-family: Segoe UI
    src: url('./font/Segoe UI/Segoe UI.woff')
@font-face
    font-family: Segoe UI Bold
    src: url('./font/Segoe UI/Segoe UI Bold.woff')
@font-face
    font-family: Segoe UI Bold Italic
    src: url('./font/Segoe UI/Segoe UI Bold Italic.woff')
@font-face
    font-family: Segoe UI Semibold
    src: url('./font/Segoe UI VF/Segoe-UI-Variable-Static-Text-Semibold.ttf')


$width-major: 600px
$logo-width: 2.5rem
$ico-height: 20px


//          C  O  L  O  R  S


$theme-color: salmon
$theme-color-alt: steelblue

//  Light Theme

$background-light: $black3
$background-light-2: color-mix(in srgb, salmon 20%, $background-light) // background light secondary color

$color-txt: #333

//  Dark Theme

$background-dark: #171717

$DM-accent-2: #2D2D2D
$DM-accent-3: #3E3E3E
$DM-accent-4: #070707

$DM-separator: #333

//  element colors

$my-squad-color: orangered
$global-squad-color: cornflowerblue

$alert-color: #63d1bf
$help-color: lightcoral
$up-color: palegoldenrod

//          T  E  X  T


$font-theme: Segoe UI
$font-theme-semibold: Segoe UI Semibold
$font-theme-bold: Segoe UI Bold
$font-squad: 'Fira Code'
$font-txt: Segoe UI

$smaller: 15px
$smallest: 14px
$bigger: 17px
$biggest: 18px


//          .  .  .


$single: 1rem
$double: 2rem
$triple: 3rem
$half: .5rem
$qtr: 4px

$u-border-radius: 2px
$u-transition-duration: .3s

$zMAX: 999999

$toolbar-height: 104px // 👀
$toolbar-title-height: 112px // 👀


// @function set-color($theme)
//   @if ($theme == 'light')
//     @return $black3
//   @else
//     @return #222

// $background: set-color($parent-class)


@keyframes vanish
    0%
        display: block

    100%
        display: none
@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
@keyframes ladder-img-ascend
    0%
        top: 100%
    
    100%
        top: 0
@keyframes ladder-ladder-ascend
    0%
        height: 0
    
    100%
        height: 100%
@keyframes bzz
    0%
        transform: translateX(0)
    25%
        transform: translateX(3px)
    50%
        transform: translateX(-3px)
    75%
        transform: translateX(3px)
    100%
        transform: translateX(0)
@keyframes unset-overflow
    0%
        overflow: hidden
    100%
        overflow: visible


@mixin placeholder($size)
    font-family: Segoe UI
    font-size: $size
    margin: 0
    color: transparentize(grey, .75)
    user-select: none
@mixin i($color)
    fill: none
    color: $color

    &:hover
        fill: $color

        .guts
            color: var(--background)
@mixin h($size)
    font-family: $font-theme-semibold
    font-size: $size
    letter-spacing: .5px
    color: grey
    user-select: none
@mixin t($size)
    font-family: Segoe UI Semibold
    font-size: $size
    color: grey
@mixin global-rank($size, $spread)
    display: inline-block

    > div
        width: $size
        height: $size
        border-radius: 50%
        background: var(--dim)

        &:not(:last-of-type)
            margin-bottom: $spread

        &.current
            background: $global-squad-color
@mixin img($size)
    min-width: $size
    min-height: $size
    max-width: $size
    max-height: $size
    object-fit: cover
    border-radius: 50%
    overflow: hidden
    cursor: pointer
@mixin usn()
    font-family: Segoe UI Semibold
    color: grey
    cursor: pointer
@mixin click-me()
    @include f-ja
    aspect-ratio: 1
    border-radius: 50%
    cursor: pointer
    
    &.ui-handle:hover svg
        color: whitesmoke !important

    &:hover
        background: var(--low-light)
@mixin mini-spotlight()
    width: 1.75rem
    height: 1.75rem
    border-radius: 50%

    &:hover
        background: transparentize(grey, .88)
@mixin scrollbarX()
    &::-webkit-scrollbar
        height: 6px

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-thumb
        border-radius: 999px
        background: indianred
@mixin scrollbarY()
    &::-webkit-scrollbar
        width: 6px

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-thumb
        border-radius: 999px
        background: indianred
@mixin highlight-mod()
    color: gold !important
    background: var(--low-light)

    svg
        fill: gold

    p.title
        color: gold 
@mixin triggers($triggers-col, $background)
    &.global
        .trigger
            color: transparentize($triggers-col, .6)

            &:hover
                color: $triggers-col

            // .bg-ctr
            //     background: $triggers-col

    .-trigger
        &.first-e::after
            border: 4px solid transparent
            border-right-color: $triggers-col

        // &.mob.first-e::after
        //     border-right-color: transparent
        //     border-left-color: $triggers-col

        .trigger
            color: $triggers-col


.overflow-y
    @include hide-scrollbar()
    overflow-y: scroll



//          🡇 🡇 🡇    D  E  V    🡇 🡇 🡇

#canvas #centre-stage
    p
        font-size: 1rem
    
        span.flag
            display: inline-block
            vertical-align: middle
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            line-height: 1em
            width: 1.4rem
            height: 1em

            &::before
                content: '\00a0'

#deployment-indicator
    position: absolute
    left: 0
    top: 0
    width: 12px
    height: 12px
    z-index: 999
    animation: vanish 2s linear forwards

// placeholder

.ph
    font-size: 1.25rem
    font-weight: bold
    margin: 0
    mix-blend-mode: overlay
    opacity: .5

    &.--small
        font-size: 1rem
        mix-blend-mode: unset

.ph05
    @include placeholder(.5rem)
    font-style: italic

.ph1
    @include placeholder(1rem)

.ph2
    @include placeholder(2rem)

[class^="dummy"]
    border-radius: 4px

    &[class*="dummy-line"]
        background: transparentize(white, .8)

        &:hover
            opacity: .66

// template colors

.dummy-gradient
    background: linear-gradient(to right, blue, palegreen)
.dummy-gradient-half-L
    background: linear-gradient(to right, blue, mix(blue, palegreen, 50%))
.dummy-gradient-half-R
    background: linear-gradient(to right, mix(blue, palegreen, 50%), palegreen)
.dummy-block
    background: palegreen

//          🡅 🡅 🡅    D  E  V    🡅 🡅 🡅



//          C  O  L  O  R  S


.color
    color: var(--background)
.background
    background: var(--background)
.background-inherit
    background: inherit
.background-notice
    background: #d6d600
.background-semi
    background: transparentize($background-light, .03)
.dim
    color: var(--dim) !important
.dim-bg
    background: var(--dim)


//          SVG

    
.-squad-stroke svg
    fill: none
    color: gold


//          Text


.soft
    color: grey


//          T  E  X  T


.h1
    @include h(18px)
.h2
    @include h($smaller)
.t1
    @include t(2rem)
.t2
    @include t(22px)
.smaller
    font-size: $smaller
.smallest
    font-size: $smallest
.biggest
    font-size: $biggest
.semibold
    font-family: $font-theme-semibold
.bold
    font-family: $font-theme-bold




//          G  L  O  B  A  L          G  L  O  B  A  L          G  L  O  B  A  L          G  L  O  B  A  L          G  L  O  B  A  L




//          Styles



.black-light
    background: transparentize(black, .5)

    &:hover
        background: transparentize(black, .3) !important
.inline-half
    margin-left: 4px
.inline
    margin-left: $half
.inline-1
    margin-left: 1rem
.inline-2
    margin-left: 2rem
.inline-3
    margin-left: 3rem
.low-light
    background: var(--low-light)
.push
    margin-right: $half
.push-half
    margin-right: 4px
.push-1
    margin-right: 1rem    
.stack-half
    margin-top: 8px
.stack
    margin-top: 1rem
.stack-2
    margin-top: 2rem
.margin-0
    margin: 0
.button
    padding: 8px
    border-radius: 999px
    text-align: center
    cursor: pointer
.clear-toolbars
    margin-top: $toolbar-height !important
.clear-toolbars-title
    margin-top: $toolbar-title-height !important
.click-me
    @include click-me()

    &:hover
        background: var(--low-light)
.click-me-after
    position: relative
    cursor: pointer
    z-index: 1

    &::after
        @include xy
        content: ''
        display: none
        width: 2rem
        height: 2rem
        border-radius: 50%
        background: var(--low-light)
        z-index: -1
    &:hover::after
        display: block
.-hide-display
    &:hover .hide
        display: block

    .hide
        display: none
.-hide-display-flex
    &:hover .hide
        display: flex

    .hide
        display: none
.-soft-reveal
    &:hover .soft-reveal
        transition: opacity $u-transition-duration
        opacity: 1

    .soft-reveal
        opacity: 0


//          Elements


p
    color: var(--default-p)
p.squad-id, span.squad-id
    font-family: $font-squad
textarea
    @include unset-input
    width: -webkit-fill-available
    // resize: none

    &.textarea
        font-family: $font-txt
        font-size: 1rem
        box-sizing: border-box
        border-radius: 5px
        background: var(--low-light)
        padding: 8px
        height: 3rem

        &::placeholder
            font-size: 1rem
.-ArrowBack
    cursor: pointer

    &.click-me
        width: 2rem

    svg
        height: 1rem
#chat-tools
    top: 0
    width: 100%
    padding: 1rem 0
    text-align: center
    z-index: 1

    p
        font-family: $font-theme-semibold
        font-size: $smaller
        margin: 0
.clickDrop
    &.open .drop
        display: block

    .drop
        display: none
.clink
    @include rlink
    color: $theme-color !important
.Cookie
    height: 19px
    color: $theme-color !important
#entry_proper
    .entry-user.isJournal
        .submission-info
            justify-content: center
            margin-bottom: 2rem
        
            .-author
                padding: 8px 12px

                img.usr
                    // @include img(1.75rem)

                p
                    font-size: 2rem
                    font-weight: bold
                    color: $theme-color

                span.isSquadie img
                    @include nudge(3px)
                    height: 14px
                    margin-left: 2px
    .entry-user.media
        .title
            margin: 0
    .entry-user:not(.isJournal)
        #-replies-
            border-top: 1px solid var(--dim)

    .-submission-info
        margin-bottom: 8px
    .-submission-content
        .title
            margin-top: 0
   
        // .txt-wrapper, .media-wrapper
        //     padding-bottom: 3rem

        .media-wrapper
            margin-top: $single

            &.video
                background: black

            img, video
                width: $width-major
                max-height: 77vh
                object-fit: contain

        .body:first-of-type
            margin-top: 0

        .body:last-of-type
            margin-bottom: 0

    #-replies-
        margin-top: 2rem
        padding-bottom: 33vh
        width: 100%

        p#add_comment
            width: fit-content
            margin: 4rem auto 0 auto
            padding: 8px
            border-radius: 999px
            cursor: pointer

        textarea
            display: block
            height: 250px
            padding: 1rem
            color: lightgrey
            border-radius: 6px
            background: var(--background-accent-a)
            resize: vertical

            &::placeholder
                // color: $theme-color

            &::-webkit-scrollbar
                width: initial

        #-replies
            margin-top: 5rem
.entry-user
    position: relative
        
    &.--entry-preview
        height: fit-content
        padding: .5rem
        border-radius: 2rem
        cursor: pointer

        &.journal-entry
            background: transparentize(blue, .97)

            &:hover
                background: transparentize(blue, .95)
        &.Squad
            margin-bottom: 1.5rem
        &:hover
            background: whitesmoke

        .-submission-content
            .title, .flair
                @include unset-margin-tb()

            .title
                font-size: 23px
                padding: 0 $half

            svg.flair
                height: 1.5rem

            .media-wrapper
                &.video
                    background: black

                img, video
                    width: fit-content
                    max-height: 66vh

            .txt-wrapper
                position: relative
                max-height: calc(19.6px * 9) // 📜 make programmatically beautiful
                overflow: hidden
                padding: 0 $half $half $half

                &.fade-container
                    background: -webkit-linear-gradient(#333, #333, transparent)

                .body:last-of-type
                    margin-bottom: 0
    &.UserProfile
        padding: 8px 12px
        border-radius: 1rem

        .-submission-content
            .-submission-info
                padding: 0 .5rem !important

            .title
                font-size: 21px

    .-submission-info
        p
            @include no-marj()
            font-size: $smallest
            color: grey

        .hover-low:hover p
            color: $theme-color

        .-author
            height: 2rem
            padding: 0 8px 0 4px
            border-radius: 999px

            &:hover p
                color: $theme-color

        img.usr
            @include img($size: 1.5rem)

        .dummy-img
            width: 1.5rem
            height: 1.5rem
            border-radius: 50%

        .bullet
            padding: 0 5px

        .-score
            width: 2rem
            height: 2rem
            border-radius: 50%
            
            .score
                &.upvoted
                    color: dodgerblue

                &.downvoted
                    color: red

        .-replies
            height: 2rem
            padding: 0 .5rem
            border-radius: 999px

            p
                margin: 0

        .entry-menu
            $connected: $half
            right: 4px

            &.open .entry-menu-items
                animation: unset-overflow 0s $u-transition-duration forwards

                .tray
                    right: $connected

            .-chevron
                @include f-ja
                @include mini-spotlight()
                margin-left: auto
                cursor: pointer

                svg
                    width: 10px
                    transform: rotate(90deg)

            .entry-menu-items
                overflow: hidden
                padding-left: $connected
                
                .tray
                    position: relative
                    right: -100%
                    transition: right .2s ease-out

                .ico-interactive
                    &.pin::after
                        content: 'Follow Post'

                    &.report::after
                        content: 'Report'

                    &:not(:first-of-type)
                        margin-left: $half

                    svg
                        height: 20px

    .-submission-content
        width: 100%
        box-sizing: border-box

        .title
            color: #555

        .media-wrapper
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            width: 100%
            margin-top: $half
            overflow: hidden

            img.backdrop
                @include xy
                min-width: 133%
                min-height: 133%
                object-fit: cover
                filter: blur(3rem)

            img:not(.backdrop)
                width: 100%
                max-width: calc(100vw - 1rem)
                max-height: 720px
                z-index: 2

            video
                width: fit-content
                max-height: 720px
            
        .txt-wrapper
            &.fade-container
                -webkit-background-clip: text !important
                -webkit-text-fill-color: transparent !important

            .body
                font-family: $font-theme
                font-size: $smaller
                margin-top: 1em
                color: $color-txt
.global-rank
    @include global-rank(4px, 3px)
.handle-score
    @include f-ja
    display: inline-flex
    position: relative
    top: -1px
    width: 22px
    height: 22px
    border-radius: 50%

    &:hover
        background: var(--low-light)

    &.handle-score-up
        div
            border-left: 2px solid grey
            border-top: 2px solid grey

    &.handle-score-down
        div
            transform: rotate(45deg) translateY(-2px)
            border-right: 2px solid grey
            border-bottom: 2px solid grey

    div
        display: inline-block
        position: relative
        top: 1px
        width: 4px
        height: 4px
        transform: rotate(45deg)
.ico-interactive
    @include f-ja
    @include mini-spotlight()
    position: relative
    cursor: pointer

    svg
        height: $ico-height
.isSquadie
    position: relative
    margin-left: 1px

    &::after
        content: 'Squadie'

    img
        display: inline-block
        height: 11px
.journal-entry
    padding: 8px
    border-radius: 999px
    transition: background $u-transition-duration ease
    cursor: pointer

    p
        margin: 0

        &.username
            font-size: 12px
            color: var(--background)

        &.blurb
            font-size: $smaller
            color: var(--background)
.quick-view
    // position: absolute
    // top: $toolbar-height
    width: $width-major
    z-index: 999
.learn-more
    @include rlink
    font-style: italic
    color: $theme-color !important
.mask
    @include full-page
    @include x0y0
    position: fixed
    z-index: -1
.-mod
    position: relative
    cursor: default

    &.beginner
        &[class*='ps-']::after
            content: 'Starter'
    &.squad
        &[class*='ps-']::after
            content: 'My Squad'

        &.color svg
            color: var(--background) !important

        svg
            color: orangered
    &.global
        &[class*='ps-']::after
            content: 'Global Squad'

        &.color svg
            stroke: var(--background) !important

        svg
            stroke: dodgerblue
    &.type-event
        p
            font-family: pixel
            font-weight: bold
            color: gold

        &[class*='ps-']::after
            content: 'One Shot Challenge'
    &.type-community
        &[class*='ps-']::after
            content: 'ranked'

        svg
            fill: gold
    &.wreath
        &[class*='ps-']::after
            content: 'wreath'

        svg
            color: gold
.-NA-
    position: relative

    &.TRUE
        .-NA
            display: block

    .-NA
        @include y
        right: $out-rem
        display: none
        opacity: .9

        &::after
            content: ''
            @include y
            left: calc(100% - 10px)
            transform: rotate(45deg) translateY(-50%)
            width: 10px
            height: 10px
            border-radius: 3px
            background: violet

        .NA
            font-weight: initial !important
            color: cornflowerblue !important
            width: fit-content !important
            padding: .5rem !important
            opacity: 1 !important
            font-family: Consolas
            margin: 0
            background: violet
            border-radius: 3px
.-rules
    padding: 1rem
    border-radius: 8px
    background: transparentize(turquoise, .97)

    .t
        font-family: 'Fira Code'
        font-size: 2.5rem
        margin-top: 0
        margin-bottom: 1rem
        color: var(--low-light)

    .core-rules
        padding: 0 1rem

        p
            font-size: $smaller
            width: fit-content
            margin: 0
            padding: 1rem
            color: transparentize(turquoise, .5)

            span
                color: $theme-color

            .-About
                display: inline-block
                position: relative
                transform: translateY(1px)

                &:hover .rule-about
                    display: block

                .rule-about
                    display: none
                    position: absolute
                    left: 0
                    bottom: $out-8
                    width: max-content
                    max-width: 300px
                    font-size: $smallest
                    padding: 8px
                    border-radius: 8px
                    color: salmon
                    background: var(--background-descent-a)
                
                svg
                    @include nudge(1px)
                    height: 14px

                    circle
                        color: transparent
                        fill: transparentize(turquoise, .5)

                    .guts
                        color: $background-dark

    .rules
        padding: 0 1rem

        p
            color: transparentize(turquoise, .3)
.separator // this style was moved to global from 'entry'. commute styles there accordingly if changing
    border-top: 1px solid grey
    box-sizing: border-box
    width: calc(100% - 2rem)
    margin: 8px auto

    p
        @include xy
        @include no-marj()
        transform: translate(-50%, calc(-50% - 2px))
        padding: 0 .5rem
        background: $background-dark
.-Spinner
    svg
        height: 3rem
        fill: transparentize(white, .7)
        animation: spin 1s linear infinite
.squad-id
    font-family: $font-squad
.ui-element
    position: fixed
    cursor: pointer
    z-index: $zMAX

    &.-ArrowBack
        left: 1rem
        top: 1rem
    &.-X
        right: 1rem
        top: 1rem
    &.--fill svg
        fill: white
    svg
        height: 1.5rem
        color: white

        &.fill
            fill: white
.ui-handle
    &.--fill svg
        fill: grey
    svg
        color: grey
.usn
    @include usn
.usr-border
    border: 2px solid transparent

    &:hover, &.active
        border-color: transparentize(white, .5)


//          Planes


.overlay
    @include full-page
    @include x0y0
    @include f-j
    @include scrollbarY()
    position: fixed
    background: var(--background)
    overflow-y: scroll
    z-index: 9999

    .content
        width: $width-major


//          Pop-Up, Pseudo & Hover


#pop-up
    display: none
    position: fixed
    font-size: $biggest
    padding: 3rem
    margin: 0
    border-radius: 2rem
    background: palegoldenrod
    white-space: nowrap
    z-index: 999

    &.show
        display: block
    &.add_activity
        background: var(--users-background)

        .status-option
            text-align: center

            .-svg
                cursor: pointer

                svg
                    height: 4rem
                    color: pink

            p
                margin-bottom: 0
                opacity: .5

        p.sub
            font-size: $smaller
            margin-top: .5em
            opacity: .5

        input
            @include unset-input
            box-sizing: border-box
            width: 100%
            font-size: 1.5rem
            color: pink
            border: 2px solid pink
            border-radius: 8px
            padding: 12px 8px

            &.bzz
                animation: bzz .2s forwards
                border-color: transparentize(red, .3)
                background: transparentize(red, .8)

        #status-submit
            width: fit-content
            margin: 1em auto 0 auto
            color: steelblue
            cursor: pointer
    &.RESET
        .squad-id
            font-weight: bold
        
        p#countdown
            display: inline
            font-family: $font-squad
            font-weight: bold
            color: var(--background)

    .-X.click-me
        position: absolute
        right: 0
        top: 0
        width: 2rem
        height: 2rem

        svg
            height: 1.5rem
            color: palegoldenrod
#pop-up-m
    position: fixed
    background: var(--background)
    opacity: .5
     
[class*="ps-"]::after, .pseudo
    margin: 0
    padding: .5rem
    border-radius: 999px
    font-family: Segoe UI
    font-size: 1rem
    color: $background-light
    background: salmon
    opacity: .95
    white-space: nowrap
    z-index: $zMAX
[class*="ps-"][class*="-mini"]::after, [class*="pseudo-"][class*="-mini"] .pseudo
    margin: 0
    padding: 3px
    border-radius: $u-border-radius
    font-family: Segoe UI
    font-size: 13px
    color: $background-light
    background: salmon
    opacity: .95
    white-space: nowrap
    z-index: $zMAX
// ??
[class*="ps-"][class*="-info"]::after
    font-size: $smaller !important
    padding: 1rem !important
    color: gainsboro !important
    background: #111 !important
    opacity: .975 !important

sup.i
    margin: 0
    font-family: Segoe UI Bold
    font-weight: 500
    // opacity: .5
    cursor: pointer

    // &:hover
    //     opacity: 1

.hoverDrop
    &:hover .drop
        display: block

    .drop
        display: none
        position: absolute
        top: 100%
        z-index: 1

        &:hover
            display: block
.hover-low:hover
    background: var(--low-light)




body
    // @mixin scrollbarY()
    background: $background-dark
    overflow: hidden scroll

    &::-webkit-scrollbar
        width: 6px
    &::-webkit-scrollbar-track
        background: none
    &::-webkit-scrollbar-thumb
        border-radius: 999px
        background: indianred

    &.hide-scrollbar
        @include hide-scrollbar()
    &.noscroll
        overflow: hidden

#-ROUTERJS-
    // > DARK MODE
    &[theme="dark"]
        --default-p: darkgrey
        --background: #171717
        --background-rgb: 23, 23, 23
        --background-accent-a: #222
        --background-descent-a: #121212
        --dim: #555
        --low-light: rgba(255, 255, 255, .05)
        --text: #BBB
        
        --resign: #d73636
        --users-background: #2D2D2D

        $alert-color: #baffb7

        // global color profiles

        [class*="ps-"]::after, .pseudo
            color: salmon
            background: rgb(59 59 59)
            opacity: .9

        .contrast
            color: lightgrey

        .background-semi
            background: transparentize($background-dark, .03)
        .background-notice
            background: #b9ff6c

        //      G L O B A L      G L O B A L      G L O B A L      G L O B A L

        #ibox .info 
            p
                color: darkgrey

        .i-mask, .fly
            background: transparentize($background-dark, .03)

        .ui-handle
            &.--fill svg
                fill: transparentize(white, .5)
            svg
                color: transparentize(white, .5)

        // planes

        .overlay
            p
                color: darkgrey

        #Resetting
            background: transparentize($background-dark, .1)

        // DOM

        #-logo-tools, #-left-panel
            #navigation
                &.chat-menu, &.menu
                    background: $DM-accent-2

                    .separator p
                        background: $DM-accent-2

                .chat-menu
                    background: $DM-accent-2

                    .-chat-items
                        .separator
                            border-top: 1px solid $background-dark

                        .-chat-item:hover
                            background: var(--low-light)

        #Profile
            .drop .wrapper
                background: $DM-accent-3

                svg, p
                    // font-weight: bold
                    color: #a2d5c0
                    opacity: .9

                    // span
                    //     color: $background-dark

        // CENTER

        #track
            background: #262626
            
            #scrollbar
                background: darkslategrey

        #toolbar, .filter-options
            background: $background-dark

            span.welcome
                color: $alert-color

            #global-filters
                .filter
                    background: $background-dark

                p
                    color: grey


        #__SQUAD__
            #squad_activity
                #-charged-
                    .header svg, .header p, span.indicator
                        color: $up-color
                #-resigned-
                    .resign-item p.msg
                        color: lightgrey
     
        #_users_ #squad-handles
            .click-me.squad::after
                background: $my-squad-color
                color: var(--background)
            .click-me.global::after
                background: $global-squad-color
                color: var(--background)


        .handle-score:hover
            background: transparentize(white, .875)

        .entry-user
            .title
                color: lightgrey

            .body
                color: darkgrey

        .--entry-preview
            // &:not(.borderless)
            //     // border-color: darkslategrey

            //     &:hover
            //         border-color: grey

            &:hover
                background: #282828

            .txt-wrapper.fade-container
                background: -webkit-linear-gradient(darkgrey, darkgrey, transparent)
        
        .separator
            border-color: $DM-accent-3

        #entry_proper #-replies- .-textarea textarea
            color: var(--text)

        #UserProfile
            #focus #-user-items
                color: grey

                span.usn
                    p#report
                        background: white

                span.user-do svg:hover
                    color: $theme-color
                    fill: $theme-color

        #CHAT
            $chat-color: color-mix(in srgb, white 50%, $background-dark)
            // border-color: $DM-separator

            .-chat
                #chat
                    .__msg p.msg.special
                        color: deepskyblue

                .-textarea
                    .tool svg
                        color: $chat-color

                    textarea
                        &::placeholder
                            color: $chat-color

        #Trigger
            background: transparentize($background-dark, .02)

            .content
                border-bottom-color: $DM-accent-4

                .left p
                    color: red

                .right p
                    color: blue

        #-LADDER-
            #container #ctr-1 #-ladder .-triggers
                opacity: 1
                
                &:hover
                    opacity: .8

                // .trigger
                //     color: $background-dark
    &[theme="light"]
        --default-p: normal
        --background: #F7F7F7
        --background-rgb: 0, 0, 0
        --background-accent-a: blue
        --dim: darkgrey
        --low-light: rgba(0, 0, 0, .05)
        --text: #333
        
        --resign: red
        --users-background: rgba(0, 0, 0, .8)

        .usr-border:hover
            border-color: transparentize($theme-color, .2)
    &[minimalist="y"]
        .minimalist
            display: none !important

        #squad_activity .squad-activity-section .header
            svg
                width: 2.5rem
            p
                display: none




//          U  I          U  I          U  I          U  I          U  I          U  I          U  I          U  I          U  I          U  I          U  I          U  I




#-logo, #-logo-tools, #-left-panel
    position: fixed
    left: 2vw
    top: 2vw
#-logo
    cursor: pointer
    z-index: 99999

    #in-development
        @include x
        top: 100%
        font-family: Consolas
        font-size: 1rem
        font-weight: bold
        color: fuchsia
        white-space: nowrap
#-logo-tools, #-left-panel
    width: $logo-width
    height: $logo-width
    // z-index: 99996
    z-index: 9999

    #navigation
        position: absolute
        // left: calc(1.5rem - 13px)
        left: 0
        top: calc(100% + 3rem)
        border-radius: 1rem
        // background: transparentize(salmon, .1)

        &.menu
            @include hide-scrollbar()
            display: flex
            flex-direction: column
            box-sizing: border-box
            width: 300px
            height: calc(100vh - 3rem - 3rem - 2vw - 2rem)
            overflow-y: scroll
            padding: .5rem
            border-radius: 1rem

            .separator
                position: relative
                border-color: var(--dim)
                
                p
                    transform: translate(-50%, calc(-50% - 1px))
                    font-size: 13px
                    padding: 0 4px

            .options
                width: 100%
                box-sizing: border-box
                padding: 0 3rem

                p
                    width: 50%
                    font-size: $smaller
                    text-align: center
                    padding-bottom: 3px
                    border-bottom: 3px solid transparent
                    cursor: pointer

                    &.current
                        color: cornflowerblue
                        border-bottom-color: cornflowerblue
                    &:not(.current):hover
                        border-bottom-color: var(--dim)

            .grid-menu
                display: grid
                grid-auto-rows: 1fr

                .journal-entry
                    border-radius: 6px

                    &[current='y'] p
                        color: $theme-color !important
                        opacity: 1 !important
                    &.blurb .username
                        opacity: .5
                    &:hover
                        background: var(--low-light)

                    img
                        @include img($size: 1.5rem)
.top-tools
    @include f-a
    &#left-side-tools
        @include y
        left: calc(100% + 2rem)
    &#right-side-tools
        @include y
        right: calc(100% + 2rem)

        #tool-Relapse_Report svg
            height: 26px

    .click-me
        position: relative
        width: 2.5rem

        &.active svg
            color: whitesmoke

        svg
            height: 1.5rem
.nav
    transition: opacity $u-transition-duration ease-out
    cursor: pointer

    &#nav-Community p
        color: turquoise
    &#nav-My_Squad
        svg
            color: $my-squad-color
        p
            color: $my-squad-color
    &#nav-Global_Squad
        svg
            stroke: $global-squad-color
        p
            color: $global-squad-color

    // &.common, &.special
    //     color: $theme-color-alt
    // &.tools
    //     color: $theme-color

    &.reg
        &:hover svg
            color: $theme-color
        p
            color: $theme-color
    &:not(.not)
        .-nav-icon.click-me
            background: transparentize(white, .9)
        
        .reg svg
            color: $theme-color

        #ico-Feed svg
            fill: $theme-color

    p
        position: relative
        left: 0
        font-size: $biggest
        margin: 0 0 0 $single
        white-space: nowrap
.-nav-icon
    width: 3rem

    &#ico-Community img
        @include img($size: 2rem)
    &.pro svg
        height: 2rem
        // color: grey
        // stroke: grey
    &.reg svg
        width: 20px
        color: grey
    
    svg
        fill: none
        margin: 0 auto
#Profile
    $color-profile-links: gainsboro
    position: fixed
    right: 2vw
    top: 2vw
    transform: translate(3px, -3px)
    z-index: 99999
    cursor: pointer

    img
        @include img($size: 3rem)
#profile-menu, .main-menu
    position: fixed
    right: 0
    top: 0
    padding: 0 4rem
    background: var(--background)
    z-index: 99998
    
    .menu-items-wrapper
        > div
            cursor: pointer

            &:not(:last-of-type)
                margin-bottom: 1rem

            &:hover p
                color: var(--background)
                background: turquoise

            #-theme
                position: relative
                width: 24px
                height: 24px
                overflow: hidden

                .-icon-
                    @include x
                    transition: top $u-transition-duration ease

        svg, p
            color: turquoise

        .-icon
            @include f-j
            width: 24px

            svg
                height: 19px

                &#light
                    min-height: 24px

        p
            font-family: Consolas
            font-size: 17px
            box-sizing: border-box
            width: 200px
            padding: 1rem
            margin: 0
            margin-left: 1rem
            border-radius: 8px
            white-space: nowrap
#toolbar, .filter-options
    @include f-a
    position: fixed
    top: 0
    width: $width-major
    padding: $triple 0 $double 0
    color: $theme-color
    background: $background-light
    z-index: 99999

    &.with-title
        display: grid
        grid-template-columns: 1fr auto 1fr
        justify-content: center

        .t
            font-size: 1.5rem

    p
        @include unset-margin-tb()

        &.toolbar-header
            font-family: $font-theme
            font-size: $bigger
            font-weight: bold
            color: var(--dim)

        &.title
            font-family: $font-theme-semibold

            &#general-chat-title
                color: $theme-color-alt

        &#squad-id-toolbar
            color: $theme-color

    .-ArrowBack
        @include f-a
        position: relative
        width: fit-content
        cursor: pointer

        &::after
            @include xy
            content: ''
            // display: none
            width: 2rem
            height: 2rem
            border-radius: 50%
            background: var(--low-light)
            z-index: -1

    #-stats
        position: relative
        margin-left: auto
        margin-right: $single

        p.stat
            margin-left: 2rem

            &.id-current
                position: relative

                span
                    &::after
                        content: 'Squadies'

                    svg
                        display: inline-block
                        fill: none
                        color: gold
                        height: 14px
                        margin-left: 4px
                        transform: translateY(1px)
        
    #global-filters
        margin-right: auto

        .hoverDrop
            position: relative

            p
                user-select: none

            .drop
                right: 0
                padding-top: $half

            .filter
                justify-content: right
                margin-left: $half
                margin-top: $half
                padding: $half
                border-radius: $half
                border: 1.5px solid transparent
                background: $background-light
                cursor: pointer

                &.selected
                    // 🗒️ border/padding negate like border-box in flex container?
                    // so margin-right is non-inclusive of border-thickness
                    padding: 0
                    margin-right: $half
                    margin-top: 0

                &:not(.selected):hover
                    transition: border-color $u-transition-duration
                    border-color: $theme-color

                svg
                    height: 19px
                    margin-right: $qtr

                svg, p
                    color: $theme-color

    #p-create-post
        color: grey
        cursor: pointer




//          R  O  U  T  E  S          R  O  U  T  E  S          R  O  U  T  E  S          R  O  U  T  E  S          R  O  U  T  E  S




#-HOME-
    $grid-gap: 3rem

    display: flex
    flex-direction: column
    width: 100vw
    min-height: 100vh
    box-sizing: border-box
    padding: 0 $grid-gap

    .community
        cursor: pointer
        padding: 8px
        border-radius: 999px

        img
            height: 26px
            border-radius: 50%

        p
            margin: 0
            color: #fffff5

            &.t
                font-size: $bigger

            &.sub
                // font-size: $smallest
                margin-top: 2px

            // ico's

            .-Beginner
                display: inline-block
                position: relative
                font-size: 12px

                &::after
                    content: "Starter Options"

                svg
                    height: 12px
                    color: darkseagreen

            .-Tick
                display: inline-block
                position: relative

                &::after
                    content: "Joined"

                svg
                    height: 8px
                    fill: palegreen

    .--my
        display: grid
        grid-template-columns: min-content auto
        grid-gap: 4px 12px
        margin-bottom: 8px
        border-radius: unset

        p.t
            span
                &.day-x
                    color: grey
                
                &.starter
                    color: grey

                &.starting-soon
                    font-size: $smaller
                    border-radius: 2px
                    color: palegoldenrod

                    svg
                        height: 1rem
                        color: palegoldenrod
                        transform: translateY(3px)

        p.sub
            font-size: $smallest
            margin: 0

            span
                &[class*="stat"]
                    color: grey

                    &.stat-current svg
                        color: gold

                svg
                    display: inline-block
                    transform: translateY(1px)
                    height: 12px
                    fill: none

    $input-search-color: salmon
    .-search
        position: relative
        transform: translateY(-8px)
        margin: 2vw auto 0 auto
        z-index: 1

        input.search
            @include unset-input
            box-sizing: border-box
            width: 50vw
            height: 4rem
            font-size: 1.25rem
            padding-left: calc(2.5rem + 32px)
            border-radius: 999px
            color: $input-search-color
            background: transparentize(white, .975)

            &::placeholder
                // color: $input-search-color

        .-svg-Search
            svg
                @include y
                left: 1rem
                height: 2rem
                color: grey

        .-svg-X
            @include y
            right: 2rem
            cursor: pointer

            svg
                height: 1rem
                color: grey

        .search-results
            @include x
            top: $out-8
            box-sizing: border-box
            width: 100%
            padding: 2rem
            border-radius: 1rem
            background: var(--background-accent-a)
            z-index: 999
            
            p
                color: gainsboro

                &:not(.no-results)
                    cursor: pointer

                    &:hover
                        opacity: .8

    #section-tags
        @include hide-scrollbar()
        display: none
        width: 50vw
        box-sizing: border-box
        overflow-x: scroll
        margin: 8px auto 3rem auto
        padding: 0 1rem

        p
            @include no-marj()
            font-size: $smaller
            padding: 8px
            // background: transparentize(white, .95)
            border-radius: 999px
            color: salmon !important
            white-space: nowrap
            cursor: pointer

            &:not(:last-of-type)
                margin-right: 2rem

    #bigSearch
        padding: 0 1rem

        .tag
            width: fit-content
            margin: 2em
            color: salmon
            padding: 8px
            border-radius: 999px
            cursor: pointer

            svg
                display: inline-block
                transform: translateY(-1px)
                height: 8px
                margin-right: 8px
                fill: salmon

        .community
            margin-bottom: 8px

    .grid
        display: grid
        grid-template-columns: auto 400px
        grid-template-areas: 'g-l1 g-communities' 'g-l2 g-communities' 'g-l3 g-communities'
        grid-gap: $grid-gap
        margin-top: $grid-gap
        
        .g-l1
            grid-area: g-l1
        .g-l2
            grid-area: g-l2
        .g-l3
            grid-area: g-l3
        .g-communities
            grid-area: g-communities
            // grid-row: 1 / 2

        .grid-gap-bottom
            margin-bottom: $grid-gap

        .dummy-gradient
            height: 100%

    .g-l1#News-Announcements
        height: 200px

    .g-l2#Upcoming-Month
        height: 200px

    .g-l3#Communities-Spotlight
        height: 300px

    #create-community.button
        width: fit-content
        margin: 1.5em
        padding: 8px 12px
        border-radius: 999px
        color: salmon

    #My-Communities
        .header
            font-size: 21px
            font-variant: all-petite-caps
            letter-spacing: 3px
            margin-top: 0
            color: grey
            text-align: center
            white-space: nowrap

        .communities
            margin-top: 8px
            display: grid
            grid-auto-rows: 1fr

    #-footer
        margin-top: $grid-gap

        #footer
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 1rem 2rem
            box-sizing: border-box
            padding: $grid-gap 0

            .dummy
                width: 100%
                height: 1rem
                background: var(--dim)

            p
                font-family: Consolas
                margin: 0
                color: var(--dim)

#-HOME2-
    position: relative
    box-sizing: border-box
    width: $width-major
    padding: 5rem 0 3rem 0
    margin: 0 auto

    .exhibit
        margin-bottom: 5rem
        
        .continue
            justify-self: center
            align-self: center
                        
            &.button
                margin: 0
                background: skyblue

            p
                margin: 0

            &.isJoined
                display: grid
                grid-template-columns: 1fr auto
                grid-gap: 4px
                
                p
                    font-size: $smaller
                    text-align: right
                
                    &.joined
                        font-size: $smaller
                        color: palegreen

                        .-Tick
                            display: inline-block
                            
                            svg
                                height: 10px
                                fill: palegreen
                    &.go-to-squad
                        color: turquoise

        .-credentials
            .-img
                img
                    height: 9rem
                    margin: 0 auto
                    border-radius: 50%

            .name
                position: relative
                width: fit-content
                font-size: 1.75rem
                margin: 1em auto 12px auto
                text-align: center
                color: turquoise

                span.beginner
                    @include y
                    left: 100%
                    font-size: 1.75rem
                    color: darkseagreen
                    white-space: nowrap

            .behaviours
                @include f-a
                width: fit-content
                margin: 0 auto

                .behaviour
                    position: relative

                    &:not(:last-of-type)
                        margin-right: 1rem

                    &.-mod-squad

                    &.-mod-beginner
                        cursor: pointer

                        p
                            filter: saturate(1)

                        &:hover p, &.selected p
                            filter: saturate(0)

                    svg
                        height: 18px

                    p
                        margin: 0
                        text-align: center

                        &.sub
                            padding: 3px 2px 1px 2px
                            margin-top: 4px
                            border-top: 1px solid

    .community
        padding: 1rem
        border-radius: 999px
        cursor: pointer
        background: transparentize(white, .99)

        &:not(:last-of-type)
            margin-bottom: 8px
        &:hover
            background: transparentize(white, .98)

        img
            height: 30px
            border-radius: 50%

        p
            margin: 0
            color: #fffff5

            &.t
                font-size: $bigger
                margin-left: 12px

            &.sub
                // position: absolute
                // left: 0
                // top: 100%
                // font-size: $smallest
                margin-left: 12px
                margin-top: 2px
                white-space: nowrap

    .--my
        position: relative

        p.t
            span
                &.day-x
                    color: grey
                // &.squad-id
                &.starter
                    color: grey
                &.starting-soon
                    font-size: $smaller
                    border-radius: 2px
                    color: palegoldenrod

                    svg
                        height: 1rem
                        color: palegoldenrod
                        transform: translateY(3px)
        
        p.stat-current
            color: grey
            margin-left: auto

            svg
                display: inline-block
                height: 12px
                fill: none
                color: gold
            
    #joinable-communities, #my-communities
        .header
            font-size: 21px
            font-variant: all-petite-caps
            letter-spacing: 3px
            margin-top: 0
            color: grey
            text-align: center
            white-space: nowrap

        .communities
            margin-top: 8px
            padding: 0 1rem
            display: grid
            grid-auto-rows: 1fr

    #joinable-communities
        margin-top: 3rem

    #-footer
        margin-top: 7rem
        padding: 0 2rem

        #footer
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 1rem 2rem
            box-sizing: border-box

            .dummy
                width: 100%
                height: 1rem
                background: var(--low-light)

            p
                font-family: Consolas
                margin: 0
                color: var(--dim)

#CommunityLanding
    position: relative
    box-sizing: border-box
    width: $width-major
    padding: 5rem 0
    margin: 0 auto

    .exhibit
        .continue
            justify-self: center
            align-self: center
                        
            &.button
                margin: 0
                background: skyblue

            p
                margin: 0

            &.isJoined
                display: grid
                grid-template-columns: 1fr auto
                grid-gap: 4px

                p
                    font-size: $smaller
                    text-align: right
                
                    &.joined
                        font-size: $smaller
                        color: palegreen

                        .-Tick
                            display: inline-block

                            svg
                                height: 10px
                                fill: palegreen

                    &.go-to-squad
                        color: turquoise

        .-credentials
            .-img
                display: grid
                grid-template-columns: 1fr auto 1fr 

                img
                    height: 7rem
                    margin: 0 auto
                    border-radius: 50%

            .name
                position: relative
                width: fit-content
                font-size: 1.75rem
                margin: 1em auto 2em auto
                text-align: center
                color: turquoise

                span.beginner
                    @include y
                    left: 100%
                    font-size: 1.75rem
                    color: darkseagreen
                    white-space: nowrap

    .-type
        width: fit-content
        margin: auto

        #type
            svg
                height: 2rem

            p
                font-size: 1.5rem
                margin: 0
                margin-top: 4px
                color: gold
                text-transform: capitalize

        .behaviours
            @include f-a
            width: fit-content
            margin-left: 2rem
            padding-left: 2rem
            border-left: 1px solid var(--background-accent-a)

            >:not(:last-child)
                margin-right: 2rem

            .-mod
                position: relative

                svg
                    height: 1.25rem

                p
                    font-size: 1.25rem
                    margin: 0
                    text-align: center

                    &.sub
                        padding: 3px 2px 1px 2px
                        margin-top: 4px
                        border-top: 1px solid
            #community-size
                @include no-marj()
                position: relative
                font-size: 2rem
                color: gold
                cursor: default

                &::after
                    content: "Members"

    .community-about
        margin: 5rem 0

        .about
            font-size: 1.25rem
            margin-left: 1rem
            color: transparentize(turquoise, .5)
            text-align: center

.App
    position: relative
    width: $width-major
    margin: 0 auto




//          L  O  C  A  T  I  O  N  S          L  O  C  A  T  I  O  N  S          L  O  C  A  T  I  O  N  S          L  O  C  A  T  I  O  N  S


    

#COMMUNITY
    padding-bottom: 7rem

    .-m-
        width: 100%
        background: rgba(var(--background-rgb), .975)

    .pop-up
        @include xy
        position: fixed
        padding: 1rem 6rem
        border-radius: 1rem
        background: var(--background-accent-a)

        p#confirmRESET
            font-size: $bigger
            font-weight: bold
            color: red

        > div p
            padding: 12px
            border-radius: 8px
            cursor: pointer

            &:hover
                color: $theme-color

    #content-header
        display: flex
        flex-direction: column
        align-items: center
        width: fit-content
        margin: 0 auto 4rem auto 

        img#community-img
            @include img(5rem)
            cursor: default

        p#community_name
            font-family: $font-squad
            font-weight: 500
            font-size: 2rem
            margin-top: .5em

        #actions
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 2rem

            > div
                @include f-ja
                // width: fit-content
                height: 2rem
                padding: 0 1rem
                border: 1px solid transparent
                border-radius: 1rem
                background: transparentize(white, .95)
                cursor: pointer

                &#reset
                    position: relative

                    &.isTOMORROWSquad
                        border-color: red !important
                        background: red !important
                        cursor: not-allowed

                        svg
                            color: var(--background)

                    // &::after
                    //     content: 'Restart'

                &:hover
                    border-color: red
                    background: transparentize(red, .8)

                svg
                    height: 19px
                    color: red

                p
                    color: red
                    margin: 0
                    text-align: center

    p#isTOMORROWSquad
        font-size: 1.25rem
        margin-top: 7em
        text-align: center

    p#squad_id
        font-size: $biggest

        span
            font-size: $bigger
            font-weight: bold

    .nickname
        font-size: 1.25rem
        color: $theme-color

    .section
        margin-bottom: 3rem

        &.foo-section
            svg, p
                color: var(--dim)
            svg
                height: 1.25rem

    .side-note
        font-size: $smaller
        font-style: italic

    #-suggest
        #suggest
            font-size: $biggest
            margin-top: $double

            .-input
                position: relative

                .after
                    position: absolute
                    top: $out-8
                    left: 0
                    font-size: $smallest
                    margin: 0
                    
                    span.over, span.under
                        color: indianred

                input
                    @include unset-input
                    border-bottom: 3px solid darkslategrey
                    // border-radius: 50px
                    padding: 12px
                    font-size: 1.25rem
                    color: salmon

        #propose
            @include margin-tb($a: 2em)

            span
                margin: 0 2px

    .-poll
        margin-top: $double
        padding: $single
        border-radius: 1rem
        background: $DM-accent-2
        
        .-suggestions
            display: grid
            grid-template-columns: 1fr auto auto
            grid-gap: $single $triple
            width: max-content
            padding: $double
            box-sizing: border-box

            p
                font-size: $biggest
                margin: 0
                
                &.suggestion
                    font-weight: bold

                &.vote
                    color: grey
                    cursor: pointer

                    &:hover
                        color: salmon

    #inception
        cursor: default

        // .pseudo
        //     font-size: $smaller
        //     font-weight: bold
        //     padding: 1rem
        //     border-radius: 1rem
        //     background: $theme-color

    #-stats-
        // display: grid
        // grid-template-columns: 1fr auto
        // grid-gap: $single
        width: fit-content
        margin-top: $double

        p
            font-family: 'Fira Code'
            font-size: $biggest

            span#stat-current
                color: gold
            span#stat-initial
                color: palegreen
            span#stat-resigned
                color: red

        // .label
        //     text-align: right

#GlobalRankInfo
    height: 100%

    .-divisions-
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        box-sizing: border-box
        width: 100%
        height: 100%

        .-divisions
            position: relative

            &::after
                @include xy
                content: ''
                width: 5px
                height: 100%
                background: var(--dim)
                opacity: .3
                z-index: -1

            > .division
                &.current
                    border-color: steelblue

                    p
                        color: steelblue
                &:not(:last-of-type)
                    margin-bottom: 3rem

                p
                    font-family: 'Fira Code'
                    font-weight: bold
                    font-size: 2rem
                    margin: 0
                    padding: 8px
                    background: var(--background)
                    color: var(--dim)

#CHAT, .conversation-window
    @include hide-scrollbar()
    overflow-y: scroll
    display: flex
    flex-direction: column-reverse
    box-sizing: border-box
    width: 100%

    &[expand='false']
        padding: 0 1rem

    .-chat
        position: relative

        #chat
            padding: 0 1rem

            .__msg
                margin-top: $single

                img.usr
                    @include img(1.5rem)
                    margin-right: $half
                    cursor: pointer

                ._msg
                    p
                        margin: 0

                        span.span-usn
                            color: var(--dim)
                            cursor: pointer

                            &:hover
                                color: $theme-color

                        &.msg
                            font-family: $font-txt
                            font-size: $smaller
                            color: var(--text)

                            &.special
                                color: mediumblue

                    img.img-submission
                        object-fit: contain
                        max-width: 100%
                        max-height: 50vh
                        margin-top: 8px

        .-textarea
            position: sticky
            left: 0
            bottom: 0
            width: 100%
            padding: 2rem 0

            .tool
                @include y
                width: 30px

                &#svg-chat-menu
                    left: .5rem

                    svg
                        height: 1rem

                    &::after
                        content: 'Chats'

                &#resize-chat
                    right: .5rem

                svg
                    height: 18px
                    color: grey

                    path
                        stroke-width: 2

            textarea
                display: block
                height: 21px // 00
                font-family: $font-txt
                font-size: 1rem
                border: 2px solid transparent
                border-radius: 999px
                padding: 10px
                padding-left: 1.25rem
                background: var(--background-accent-a)
                color: var(--text)
                resize: none
                opacity: .5
                transition: opacity .1s

                &:hover, &:focus
                    opacity: 1

#__SQUAD__
    $unicode-width: 28px
    min-height: 200vh

    #StartingIn
        margin-top: calc(33vh / 2)
        font-family: Consolas
        color: lightskyblue
        text-align: center
        
        #p-starting-in
            font-size: 2.25rem
            margin: .5em 0
            opacity: .5

        #countdown
            font-size: 2.5rem
            font-weight: bold
            margin: 0

    .usr
        @include img($size: 2rem)

    .sticky
        margin-bottom: 7rem

    .sticky .--header
        svg, p
            color: var(--dim)
        svg
            height: 2rem
        p
            @include unset-margin-tb()
            font-family: $font-squad
            font-size: 1.5rem

    .nothing-to-see
        margin: 9rem 0
        font-size: 1.25rem
        color: var(--dim)
        text-align: center

        &#add_status
            font-size: 1rem
            margin: 0
            cursor: pointer

        p
            color: var(--dim)

        svg
            height: 1.25rem
            color: var(--dim)

        #add_journal
            font-size: 1rem
            cursor: pointer

    #squad_overview
        margin-bottom: 4rem
        padding-top: 2rem
        // border-top: 1px solid transparentize(white, .9)

        .--header
            margin-bottom: 1.5rem

            img
                display: inline-block
                width: 37px
                height: 37px
                opacity: .1

            .squad-id
                font-size: 3rem
                font-weight: 500
                color: transparentize(white, .9) !important

            #squad_activity_global_rank
                position: relative
                padding: 0 8px
                cursor: pointer

                &:hover::after
                    display: block

                &::after
                    content: ''
                    @include xy
                    display: none
                    width: 2rem
                    height: 2rem
                    border-radius: 50%
                    background: var(--low-light)
                    cursor: pointer

        #-overview-
            display: grid
            grid-column-gap: 4rem
            width: fit-content
            justify-items: center
            margin: 0 auto

            .overview-item
                position: relative
                // padding: 0 2px
                // border-top: 3px solid transparentize(white, .9)
                @include noselect()

                &#overview-current
                    .stat
                        color: gold

                    &::after
                        content: 'Current'
                &#overview-up_24
                    .stat
                        color: cornflowerblue
                        
                    &::after
                        content: '24 Hours'
                &#overview-down_24
                    .stat
                        color: var(--resign)
                    
                    &::after
                        content: '24 Hours'
                &#overview-change_since_login
                    .stat
                        color: var(--resign)
                    
                    &::after
                        content: 'Since Last Login'

                // &:hover::before
                //     display: block
                &::before
                    content: ''
                    // display: none
                    @include xy
                    width: 3.5rem
                    height: 3.5rem
                    border-radius: 50%
                    z-index: -1
                    background: var(--low-light)
                
                .stat
                    font-family: 'Fira Code'
                    font-size: 1.75rem
                    font-weight: bold
                    margin: 0

                > div
                    position: absolute
                    top: 0
                    left: $out-qtr-rem
                    
                    svg
                        height: 14px
                        fill: none

                    p
                        @include nudge($value: -2px)
                        font-family: $font-squad
                        font-size: $smaller
                        margin: 0

    #squad_activity
        padding: 0 2rem

        .squad-activity-section
            margin-bottom: 2rem
            padding: 1rem
            // border-radius: 1rem
            // background: transparentize(white, .95)

            .tools
                // display: none !important

            .header
                svg
                    width: unset
                    // height: 1.75rem

                    // transform: translateY(-33px)
                    height: 2rem
                    // background: var(--background)

            .users-arr
                padding-left: 1.5rem

                .-user
                    position: relative

                    &:hover
                        z-index: 999 !important

                    .usr-border.isPickedUp
                        border-color: blue

                img
                    @include img($size: 2rem)

                .indicator
                    font-size: $smaller
                    margin: 0
                    color: darkgrey
                    white-space: nowrap

                    span.blip
                        position: relative
                        top: 1px

            .tools
                @include f-a
                margin-left: auto

                .ico-interactive
                    background: var(--low-light)

                    &.picked-up, &.applied
                        background: blue
                        svg
                            color: var(--background)

                .ps-top
                    &.pick-up::after
                        content: 'Pick Up'
                    &.delete::after
                        content: 'Delete'

                .-X:hover svg
                    color: red
                .join:hover svg
                    color: gold
                .pick-up:not(.picked-up):hover svg
                    color: blue
                .msg-in:hover svg
                    color: var(--resign)

        #-charged-
            .up-arr
                width: 100%

                > div:not(:last-of-type)
                    margin-bottom: 1rem

                .indicator
                    left: $out-8
                    padding-bottom: 1px
                    color: turquoise

                .tools
                    .join
                        &.joined svg
                            color: gold
                            height: 1.75rem

                        &::after
                            content: 'Join'
        #-help-
            // border-color: transparentize($help-color, .8)

            .header svg
                color: $help-color

            .-user
                cursor: pointer
                &.focused
                    z-index: 99 !important
                &.anotherIsFocused
                    opacity: .5
                // &:hover .indicator
                //     display: block
                &:hover
                    opacity: 1 !important
                    z-index: 999 !important

                .indicator
                    // display: none
                    bottom: $out-rem
                    padding: 1rem
                    border-radius: 999px
                    background: var(--low-light)
                    color: $help-color
                
                    span.blip
                        color: $help-color

            .help-dialogue-box
                position: relative
                margin-top: 1rem

                #msg-sent
                    margin: 0

                .-textarea
                    position: relative
                    width: 100%
                    padding-left: 8px

                    textarea, #foo
                        @include hide-scrollbar()
                        position: relative
                        padding: 1rem 1.5rem
                        border-radius: 2rem
                        background: var(--users-background)
                        color: lightgrey

                        &:focus-within
                            outline: 2px solid transparentize(white, .2)

                    .placeholder
                        position: absolute
                        left: 2rem
                        top: 1rem
                        opacity: .5

                        span.placeholder
                            color: darkgrey
                        span.recipient
                            color: lightgrey

                    .msg-out
                        right: 8px

                        &:hover svg
                            color: transparentize(white, .2)
                        
                        &::after
                            content: 'Send message'
                
                .ico-interactive.pick-up
                    margin: 0 4px
        #-resigned-
            // border-color: transparentize(red, .5)

            .header
                margin-bottom: 1.5rem
                
                svg
                    color: var(--resign)
                    transform: rotate(-90deg) translate(0px, -3px)
                    path
                        stroke-width: 2
            
            .users-arr
                width: 100%

                .resign-item
                    &.open
                        .ico-interactive.msg-in
                            background: var(--resign)
                            svg
                                color: var(--background)

                        .-msg
                            display: block
                    &:not(:last-child)
                        margin-bottom: 8px
                    
                    .-user-resigned
                        padding: 4px
                        padding-right: 8px
                        border-radius: 999px
                        cursor: pointer

                        &:hover
                            background: var(--low-light)

                    .username
                        @include no-marj()
                        font-size: $smaller
                        color: grey

                    .tools
                        margin-left: auto

                        .msg-in
                            margin-right: 4px
                            
                            &::after
                                content: 'See message'

                .-msg
                    display: none
                    position: relative
                    margin: 1rem 0 2rem 0
                    padding: 1em
                    background: var(--resign)
                    border-radius: 1rem

                    .-X
                        position: absolute
                        right: 0
                        top: 0
                        width: 1.5rem
                        background: transparentize(black, .7)

                        &:hover
                            background: transparentize(black, .6)

                        svg
                            color: red
                            height: 20px

                    p.msg
                        margin: 0

    #journalled_today
        padding: 1rem 1.5rem
        border-radius: 2rem

        .--header
            margin-bottom: 0

        #sticky-journals-read
            padding: 2rem

            img
                @include img(1rem)
        #sticky-journals-unread
            display: grid
            // grid-template-columns: repeat(3, 1fr)
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
            // grid-auto-rows: 200px
            grid-gap: 12px

            .journal-entry
                background: $theme-color
                
                &:hover
                    background: transparentize(white, .9)

    #recent_submissions
        .--header
            margin-bottom: 5rem

        #gallery
            display: flex
            justify-content: center
            flex-wrap: wrap
            row-gap: 8px
            column-gap: 8px
            padding: 0 $single
            margin: 0 auto 4rem auto

            &.wrapped
                justify-content: start
            
            .-media
                img, video
                    width: 100px
                    box-sizing: border-box
                    aspect-ratio: 1
                    object-fit: cover
                    // border: 2px solid transparent
                    border-radius: 4px
                    cursor: pointer

                    &:hover
                        // border-color: transparentize(white, .66)

        .entry-user
            padding: 8px 1rem 1rem 1rem

            .-submission-info
                img.usr
                    @include img($size: 1rem)

            .title
                text-overflow: ellipsis

.-user
    position: absolute
    width: fit-content
    // border-radius: 50%
    // margin-right: 12px
    cursor: pointer

    &:hover
        z-index: 99

        .username
            display: block

    img
        @include img(2rem)

    .dummy-img
        @include img(2rem)

        div
            @include img(2rem)

    .username
        display: none
        top: calc( 100% + .5rem )
        font-family: $font-squad
        // font-weight: bold
        font-size: $smallest
        margin: 0
        color: #8f4e4e
        white-space: nowrap
        background: transparentize($background-light, .1)
        z-index: 999

#_users_
    position: fixed
    bottom: 0
    box-sizing: border-box
    width: fit-content
    padding: 1rem 0
    z-index: 9

    // to do: [expand='true'] to match CHAT style, also to become _chat_

    &.expand
        #wrapper
            display: block

            #-users
                @include scrollbarY()
                overflow: hidden scroll
                height: 100% !important
                width: 100% !important

                .-user
                    position: relative
                    margin-bottom: 1rem

                    .username
                        display: block
                        top: 50%
                        transform: translateY(-50%)
                        left: $out-rem
                        font-size: 1rem
                        background: unset
                        color: turquoise

    #wrapper
        position: relative
        width: $width-major
        height: 100%
        box-sizing: border-box
        padding: $single
        border-radius: 2rem
        background: var(--users-background)

        #-users
            position: relative

            .-user
                img, .dummy-img
                    border: 2px solid transparent

                    &:hover
                        border-color: transparentize(white, .2) !important

    #squad-handles
        right: $out-rem

        .click-me
            @include noselect()
            width: 2.5rem
            height: 2.5rem
            background: transparentize(white, .9)
            cursor: pointer

            &:not(.yes):hover
                background: transparentize(white, .8)
            &.yes.squad
                background: $my-squad-color
            &.yes.global
                background: $global-squad-color

            svg
                height: 50%
                color: var(--background)
                stroke: var(--background)
                
#show-squad
    visibility: hidden
    position: fixed
    bottom: 0
    padding: .5rem 0
    // background: transparentize(black, .2)
    z-index: 1
    transition: bottom .3s

    &.squad-hidden
        visibility: visible

    &:hover
        bottom: 3px

        svg
            height: 34px

    svg
        height: 2rem
        color: gold
        fill: gold
        transition: height .3s




//  S T I M U L I
.alcohol
    @include triggers(khaki, $background-dark)
.boredom
    @include triggers(silver, $background-dark)
.depression
    @include triggers(magenta, $background-dark)
.homework
    @include triggers(mediumspringgreen, $background-dark)
.lewd
    @include triggers(deeppink, $background-dark)
.loneliness
    @include triggers(blue, $background-dark)
.social_media
    @include triggers(dodgerblue, $background-dark)
.stress
    @include triggers(red, $background-dark)
.urge
    @include triggers(fuchsia, $background-dark)
//  S T I M U L I



//          S  U  B  S          S  U  B  S          S  U  B  S          S  U  B  S          S  U  B  S          S  U  B  S          S  U  B  S




#CreateCommunity
    width: $width-major
    margin: 0 auto
    padding-bottom: 10rem

    #toolbar
        position: sticky
        top: 0
        height: 22px
        padding: 3rem 0 1rem 0
        box-sizing: unset
        // -
        display: grid
        grid-template-columns: 1fr auto 1fr
        align-content: center

    #Create-Community
        font-size: 1.75rem
        margin: 0
        color: turquoise
        justify-self: center

    .form
        $form-primary-color: turquoise
        $form-accent-color-1: rgb(138 82 255 / 54%)
        $form-accent-color-2: transparentize(palegreen, .5)
        $form-accent-color-3: transparentize(orange, .2)
        $placeholder-bright: transparentize($form-primary-color, .3)

        margin-top: 4rem

        p
            color: $form-primary-color

        p.section-label
            // font-size: $smallest
            margin: 0 0 8px 0
            color: darkgrey
            
        .checked
            color: darkgrey !important

        .info
            position: relative
            cursor: default

            &[class*='left']::after
                left: 0
                transform: none

            &#i-default::after
                content: 'A static community with no user movement.'

            &#i-launch::after
                content: "Set your challenge for launch at a future date (For example, monthly challenges can be created in advance to launch on the 1st of the month). Your challenge will be immediately joinable and users can chat in the lobby. Initing a challenge early gives more time for users to join allowing for a bigger squad size." 

            &#i-duration::after
                content: 'This is the length of time each challenge lasts after which the remaining members have won. For example, if you set the duration to "7 Days," the runtime  of the challenge will be 7 days. The community itself runs indefinitely and continues to host joinable squads unless terminated.'

            &#i-interval::after
                content: "After your challenge has started, or if a user fails and wants to start over, how soon should a new squad be available? Longer intervals gives more time for users to pool into a single squad but increases wait times."

            &#i-tags
                &::before
                    background: $form-accent-color-2

                &::after
                    content: "Make your community easier to find by adding searchable keywords (tags)."

            &#i-admin
                &::before
                    background: transparentize($form-accent-color-3, .5)

                &::after
                    content: "Admins have the power to assign and resign moderators, as well as the powers of a moderator. Only you can assign someone as an admin. Only you can change the community settings. You can pass your role of superadmin to another user."

            &#i-mods
                &::before
                    background: transparentize($form-accent-color-3, .5)

                &::after
                    content: "Mods have the power to issue warnings and remove users from the community."
     
            &:hover::before
                height: 100%
            
            &::before
                position: absolute
                content: ''
                width: 100%
                height: 40%
                left: 0
                bottom: -2px
                background: $form-accent-color-1
                z-index: -1
                transition: height .2s ease-out

            &::after
                max-width: 250px
                padding: 2rem !important
                white-space: normal

        #CommunityImage
            width: 8rem
            height: 8rem
            border-radius: 50%
            margin: 2rem auto 3rem auto
            object-fit: cover
            cursor: pointer

            &:hover
                opacity: .8

        .selected-modifiers
            display: grid
            grid-auto-flow: column
            grid-auto-columns: 19px
            grid-auto-rows: 19px
            grid-gap: 12px
            width: fit-content
            margin: 0 auto 2rem auto
            justify-content: center
            align-items: center

            svg.mod
                height: 19px

                &.type-community
                    height: 15px

        input, textarea
            color: $form-primary-color
            box-sizing: border-box

        input#name
            @include unset-input
            width: 100%
            font-size: 1.75rem
            padding: 1rem
            // border-radius: 1rem
            // background: var(--background-accent-a)
            // border-bottom: 1.5px solid transparentize($form-primary-color, .9)

            &:hover::placeholder
                color: $placeholder-bright

        .-bio
            font-size: 1rem
            // padding: 1rem
            // border-radius: 1rem
            // background: var(--background-accent-a)
            margin-bottom: 3rem

            #limiter
                @include y
                right: 1rem
                height: 4px

            input#bio
                @include unset-input
                width: 100%
                font-size: 1.25rem
                padding: 8px 1rem
                color: transparentize($form-primary-color, .3)

                &:hover::placeholder
                    color: $placeholder-bright

        .-modifiers
            box-sizing: border-box
            width: 100%
            padding: 1rem

            .group
                position: relative
                width: 100%
                // padding: 1rem 0
                border: 1px solid transparentize(grey, .9)
                border-radius: 8px
                
                &::after
                    @include xy
                    content: ''
                    width: 1px
                    height: calc(100% - 1rem)
                    background: transparentize(grey, .9)

                &:not(.hovered) .-mod.selected
                    @include highlight-mod()

                .-mod
                    @include f-ja
                    position: relative
                    width: 50%
                    height: 5rem
                    border-radius: 8px
                    cursor: pointer

                    &:hover
                        @include highlight-mod()

                    &.type-event
                        font-size: 1.5rem
                        color: var(--dim)

                    svg
                        height: 1.5rem
                        fill: var(--dim)

                    p.title
                        font-family: $font-theme
                        font-size: 1.25rem
                        font-weight: normal
                        color: var(--dim)
                        margin: .5em 0 0 0

            .info-modifiers
                padding: 1rem
                height: 5rem
                // overflow-y: scroll

                .info-title
                    font-size: 1.25rem
                    color: darkgrey
                    text-align: center
                    text-transform: capitalize
                    // text-decoration: underline

                .info-desc
                    color: darkgrey

        .PRESERVE-modifiersPRESERVE
            padding: 1rem

            p.label
                transform: translateY(50%)
                font-size: $smallest
                margin: 0
                padding: 0 3px
                color: darkgrey

            .group
                // padding: 8px
                margin-right: 2rem
                margin-bottom: 1rem
                border: 1px solid transparentize(grey, .9)
                border-radius: 8px

                .-mod
                    @include f-ja
                    position: relative
                    width: 3.5rem
                    height: 3.5rem
                    border-radius: 8px
                    cursor: pointer

                    &.selected
                        background: var(--low-light)

                    &.beginner
                        font-size: 20px

                    &.type-event
                        font-size: 1.5rem

                    svg
                        height: 1.5rem

            .info-modifiers
                .info-title
                    font-size: 1.25rem
                    color: darkgrey
                    text-align: center
                    text-transform: capitalize
                    // text-decoration: underline

                .info-desc
                    color: darkgrey

        .common
            .dummies
                [class*="dummy-line"]
                    background: transparentize(white, .9)

                .-dummy
                    margin-bottom: 2rem

                    .dummy-line-1
                        width: 5rem
                        height: 1rem

                    .dummy-line-2
                        width: 3rem
                        height: 1rem

                    .dummy-line-major
                        width: 50%
                        height: 1rem

            #common-grid
                display: grid
                grid-template-columns: 1fr auto
                grid-column-gap: 2rem 

            .-_about svg
                @include i($color: $form-primary-color)
                height: 13px

            p
                &.label
                    @include no-marj()
                    white-space: nowrap

                &.value
                    @include no-marj()

            .form-field
                height: 38px // 00
                margin-top: 2rem

                &.type-1
                    p.label
                        display: inline

                    .value
                        display: inline-block
                        cursor: pointer

                        &:hover
                            opacity: 1 !important

                        &:not(.selected)
                            opacity: .5

                    &:not(.stay-open)
                        .value:not(.selected)
                            &.always-on-display
                                visibility: hidden
                                
                            &:not(.always-on-display)
                                display: none

                    &:hover .value
                        display: inline-block !important
     
            .document-input-style
                @include unset-input
                display: inline-block
                font-size: 1rem
                width: 100px
                padding: 8px
                border: 2px solid transparent
                border-radius: 4px

                &:focus
                    border-color: $form-accent-color-1

            #launch-date
                position: relative
                width: fit-content
                color: $form-primary-color
            
                #input-date
                    @include xy
                    visibility: hidden
                    z-index: -1

                ._calendar
                    height: 1.25rem
                    color: inherit
                    opacity: .8
                    cursor: pointer

                    &:hover
                        opacity: 1

            #duration
                #infinity
                    height: 10px
                    stroke: $form-primary-color

                #error-custom
                    @include no-marj()
                    display: inline
                    color: red

                #input-custom
                    width: 133px

            #tags
                margin-top: 4rem
                
                p.label
                    display: inline

                .tag
                    @include no-marj()
                    display: inline-block
                    position: relative
                    height: fit-content

                    p
                        @include no-marj()

                    .-click-me
                        @include y
                        right: 100%
                        transform: translateY(calc(-50% + 2px))
                        padding-right: 4px

                        .click-me.-X
                            width: 1rem
                            height: 1rem
                            
                            svg
                                height: 12px
                                color: salmon

        .-moderators
            display: grid
            grid-template-columns: 1fr 1fr
            position: relative
            box-sizing: border-box
            margin-top: 5rem
            border-top: 3px solid $form-accent-color-3
            // border-radius: 8px
            padding: 1rem 2rem
            min-height: 50px

            p.section-label
                position: absolute
                left: 1rem
                top: 0
                transform: translateY(calc(-50% - 4px))
                padding: 0 4px
                margin: 0
                color: $form-accent-color-3

            p.section-label-micro
                width: fit-content
                color: $form-accent-color-3

            .moderator
                position: relative
                margin-top: 1rem
                padding-left: 1rem

                &.bzz
                    animation: bzz .3s linear forwards

                &:hover .click-me.-X
                    visibility: visible

                .click-me.-X
                    visibility: hidden
                    right: calc(100% - 1rem)
                    width: 1rem
                    height: 1rem

                    svg.X
                        height: 12px
                        color: transparentize(grey, .5)

                img
                    @include img(1.5rem)
                
                p.usn
                    @include no-marj()
                    color: grey

                    span
                        font-size: $smallest
                        opacity: .5

            .mod-group
                &#admin
                    margin-bottom: 2rem

                input
                    @include unset-input
                    box-sizing: border-box
                    font-size: 1rem
                    width: $less-rem
                    padding: 0 0 8px 8px
                    margin: 2rem 1rem
                    border-bottom: 1px solid transparent

                    &.false
                        cursor: pointer
                        
                        &:hover::placeholder
                            color: $placeholder-bright

                    &.true
                        border-color: grey

                // p.add-mod
                //     font-size: $smaller
                //     cursor: pointer

    #launch
        width: fit-content
        border-radius: 4px
        padding: 8px 1rem
        margin: 0 0 0 auto
        // margin: 5rem auto 10rem auto
        background: var(--dim)
        // background: #070707
        cursor: default
                
        &.ready
            background: palegoldenrod
            cursor: pointer

        &:not(.ready)
            opacity: .5

#CreateEntry
    width: $width-major
    padding-top: 2rem

    .form
        .type
            display: flex
            width: 100%
            box-sizing: border-box
            margin: 1rem 0 3rem 0

            .click-me
                width: 6rem

                &.current
                    background: var(--low-light)

                    svg
                        color: cornflowerblue

                .posrel
                    transform: translateY(-6px)
                        
                    svg
                        width: 2rem
                        margin: auto
                        color: grey

                    p
                        top: 100%
                        font-size: $smallest
                        margin-top: 2px

        .title, .body, .img-upload
            @include unset-input
            display: block
            width: 100%
            padding: 1rem
            background: #1e1e1e
            // border: 1px solid var(--dim)
            border-radius: 8px
            color: grey

        #p-give-title
            left: 8px
            margin: 0

        .title
            font-size: 1.5rem
            box-sizing: border-box
            margin-bottom: 1rem

            // &.hasTitle
            //     border-color: transparent

        .body
            height: 250px
            font-size: 1rem
            resize: vertical

        .img-upload:not(.preview)
            @include f-ja
            height: 150px
            box-sizing: border-box

        img#preview
            max-width: $width-major
            max-height: 50vh
            margin: auto

        .-submit
            width: fit-content
            margin-left: auto

            p#change-img-CreateEntry
                cursor: pointer

            .submit
                padding: 8px
                margin: 8px
                border-radius: 1rem
                cursor: pointer

#Farewell
    background: (transparentize($background-dark, .025))
    overflow: hidden scroll
    // re-assessing z-Index
    z-index: 99997

    .-m-
        z-index: -1

    .content
        height: fit-content
        padding-bottom: $toolbar-height

        $section-col-2: violet
        $trigger-col: rebeccapurple

        .-svg
            @include nudge(2px)
            display: inline-block
            margin-left: 8px

            &.-Spinner svg
                height: 14px
                fill: $section-col-2

            &.-Tick svg
                height: 1rem
                color: $section-col-2

        #submitted
            color: $section-col-2

            svg
                height: 1rem

            p
                color: inherit

        p.l-h
            font-size: 1.25rem
            color: turquoise

            &#p-help-the-community
                font-size: 1.4rem
                margin-top: 0
                margin-bottom: 2em
            &#p-leave-a-message
                margin-bottom: 0
            &#p-some-parting-messages
                font-size: 1.4rem
                margin: 2em 0 3em 0
                padding-top: 2em
                border-top: 1px solid var(--background-accent-a)

        #triggers-menu
            padding: 1rem
            padding-left: 0
            flex-wrap: wrap
            
            .trigger-option
                margin-left: 1rem
                margin-bottom: 1rem
                border: 1px solid $trigger-col
                border-radius: 4px
                color: $trigger-col
                cursor: pointer

                &.true
                    color: $section-col-2
                    border-color: $section-col-2

                    button
                        color: $section-col-2

                button, p
                    padding: 8px 12px

                button
                    background: none
                    border: none
                    outline: none
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
                    font-size: 1rem
                    color: $trigger-col
                    cursor: pointer

            #trigger-addcustom
                p
                    margin: 0
                    outline: none
                    color: inherit
                // input
                //     @include unset-input
                //     font-size: 1rem
                //     color: $trigger-col

        #-leave-a-message
            margin-bottom: 3rem

            .textarea-tools
                width: fit-content
                margin: 0 1rem 8px auto

                .word-limiter
                    @include nudge(2px)
                    height: 4px
                    background: $section-col-2

                #submit-resign-msg
                    font-size: $smallest
                    width: fit-content
                    margin: 0 0 0 1rem
                    color: blue

                    &:not(.msg_exceeded):hover
                        cursor: pointer

                    &.msg_exceeded
                        color: grey
                        cursor: default

            textarea
                @include hide-scrollbar()
                box-sizing: unset
                resize: vertical
                height: 22px
                padding: 1rem 1.5rem
                border-radius: 2rem
                color: lightgrey
                background: var(--background-accent-a)

                // &::placeholder
                //     color: grey
                
            .foot-note
                font-size: $smallest
                margin: .5em 0 1em 0
                opacity: .4

        #farewells
            padding: 0 1rem
            // box-sizing: border-box
            // width: calc($width-major * .8)

            .-farewell
                &:not(:last-of-type)
                    margin-bottom: 2rem

                img
                    @include img($size: 1.5rem)
                    cursor: default

                p
                    margin: 0 0 0 1rem
                    color: lightgrey

#Messages
    @include full-page
    @include x0y0
    position: fixed
    background: var(--background)
    z-index: 99995

    #navigation
        position: relative

        #newMsg.click-me
            margin: 8px 8px 8px auto
            width: 2rem

            svg
                height: 1.25rem

        .messages-menu
            // border-top: 1px solid var(--low-light)
            // padding-top: 2px

            .message
                position: relative
                padding: 1.25rem 8px
                border-radius: 8px
                cursor: pointer

                &:hover
                    background: var(--low-light)
                
                .username
                    @include no-marj()
                    font-size: $smaller
                    max-width: calc(100% - 1rem)

                .indicator-unread
                    @include y
                    right: 1rem
                    font-family: Consolas
                    font-weight: bold
                    font-size: $smaller
                    margin: 0
                    color: orangered

            .separator
                display: none
                width: 100%
                margin: 2px 0
                border-color: var(--low-light)
                    
    .conversation-window
        width: $width-major
        height: calc(100vh - $toolbar-height - 2rem )
        box-sizing: border-box
        margin: $toolbar-height auto 0 auto
        padding: 1rem
        border: 1px solid var(--low-light)
        border-radius: 2rem

        &.openMsg
            border: 1px solid var(--dim)

        .usn
            margin-bottom: 2px

        .-textarea
            padding-bottom: 0
     
#Trigger
    // @include full-parent
    // @include x0y0
    width: 100%
    background: transparentize($background-light, .02)
    box-sizing: border-box
    padding: $toolbar-height 0
    z-index: 999

    .content
        display: grid
        grid-template-columns: 1fr 2fr 1fr
        grid-column-gap: $single
        justify-items: center
        align-items: center
        width: 100%
        padding-bottom: 2rem
        border-bottom: 1px solid lightgrey

        p#trigger-id
            font-family: $font-theme-semibold
            font-size: $biggest
            margin: 0 auto

        .left, .right
            p
                position: relative
                font-family: $font-theme-bold
                text-align: center
                margin: 0

                span
                    @include x
                    top: 100%
                    font-family: $font-theme
                    font-size: 1.25rem
                    min-width: 150px
                    // opacity: .7

        .left p
            font-size: 3rem
            color: salmon

        .right p
            font-size: 3rem
            color: cornflowerblue

        p#unicode
            font-size: 10rem
            margin: 0
            width: fit-content

            &.lewd
                transform: translateY(-1rem)

    .take-aways
        margin-top: 2rem

        .take-away
            border-radius: 4px
            width: 300px
            height: 1.25rem
            margin-top: 1rem

    .-discussion
        margin-top: 2rem

        .discussion
            border-radius: 4px
            width: 100%
            height: 4rem
            margin-top: 1rem

#UserProfile
    padding-top: $toolbar-height

    #focus
        display: grid
        grid-template-rows: min-content min-content auto
        height: 100%
        box-sizing: border-box

        .-img
            padding-bottom: $double
            justify-self: center
            z-index: 999

            img, .dummy-img
                @include img(30vh)
                border: 2px solid var(--low-light)
                cursor: default

        #-user-items
            margin: 0 auto

            p
                font-family: $font-squad

                &#user-items
                    width: fit-content
                    margin: 1em auto

            span.usn
                @include usn
                font-family: $font-squad
                font-size: 20px
                cursor: default

                .drop
                    width: 100%
                    padding-top: 1rem

                p#report
                    width: fit-content
                    font-family: Consolas
                    font-size: $smaller
                    margin: 0
                    padding: 3px
                    border-radius: $u-border-radius
                    background: grey
                    cursor: pointer
                    opacity: .9
                    white-space: nowrap

                    &:hover
                        background: red !important
            span.isSquadie
                img
                    height: $smaller
            span.flag
                $flag-height: 1rem
                position: relative
                display: inline-block
                width: 1.4 * $flag-height
                height: $flag-height
                background-size: cover
                background-position: center
                background-repeat: no-repeat
                line-height: 1em
                // vertical-align: middle
                margin-left: 8px
                    
                &::before
                    content: '\00a0'
            span.iso
                position: relative
                color: $theme-color
                cursor: default

        #feed-user
            padding: $triple 0

            .UserProfile img
                max-height: calc(300px)
                object-fit: contain

            #smile
                width: 2.5rem
                color: $DM-accent-2


//


#_404
    @include full-page
    background: dimgrey

    p
        font-family: 'Consolas'
        font-size: 20vh
        font-weight: bold
        margin: 0
        color: white
        opacity: .3





//          🡇 🡇 🡇    M  E  D  I  A  -  Q  U  E  R  I  E  S    🡇 🡇 🡇



@media (orientation: landscape) // min-width: 1024px

    #entry_proper
        .entry-user.isJournal
            padding: 3rem
            border: 1px solid var(--background-accent-a)
            border-radius: 2rem
    .nav
        padding: .5rem 2rem .5rem 0

        &:hover p
            left: 4px
            opacity: 1

        p
            opacity: 0
            transition: opacity $u-transition-duration ease-out, left $u-transition-duration ease-out


    

    #-logo
        #-HomeSVG.click-me
            left: $out-rem
            width: 2.5rem
            height: 2.5rem

            &::after
                content: 'Home'

            svg
                height: 1.5rem
                fill: none
                color: indianred
    #profile-menu
        border-left: 1px solid turquoise



    
    #-HOME-
        #section-communities
            position: fixed
            width: 400px
            padding: 1rem 0
            border-radius: 1rem

            .header
                font-size: 25px
                color: var(--dim)

            .community.--my
                padding: 1rem
                margin-bottom: 1rem
                border-radius: 1.5rem
                background: var(--low-light)

                p.t
                    font-size: 19px



    
    #__SQUAD__
        padding-top: 2rem
        padding-bottom: 33vh

        #gallery
            grid-template-columns: repeat(4, 1fr)
    #_users_
        background: rgba(var(--background-rgb), 0.8)

        &.expand
            height: 100vh
        
        #squad-handles
            @include y

        .right-side-tools
            @include y
            left: $out-rem
            box-sizing: border-box
            height: 100%
            padding: 1rem 0

            p
                font-size: $smaller

            svg
                width: 15px

            .click-me-after
                svg
                    color: var(--dim)

                    path
                        stroke-width: 2

                &:hover svg
                    color: lightgrey

                &::after
                    width: 1.5rem
                    height: 1.5rem

            #squad-min-max
                position: absolute
                top: 2rem
                
                p
                    @include no-marj()
                    font-size: 21px
                    color: var(--dim)

                    &:hover
                        color: lightgrey

            .-squad-stroke svg
                width: 1rem

            #_reload
                position: absolute
                bottom: 2rem
            
        #-users.overflow
            @include scrollbarX()
            overflow: scroll hidden
            padding-bottom: calc(1rem + 6px)
    #show-squad
        left: 50%
        transform: translateX(-50%)
        cursor: pointer

    #FEED.noEntries
        display: flex
        height: 100vh
        box-sizing: border-box

        p
            font-size: 2rem
            margin: auto


@media (orientation: portrait) // max-width: 1024px
    $width-major: 100vw

    $ico-height: 24px
    $mob-navbar-height: 77px // calc(2rem + 24px)
    $mob-toolbar-height: 52.8px
    $clear-toolbars: calc($mob-navbar-height + $mob-toolbar-height)
    $u-bottom-space: 6rem

    // experimental
    $biggest: 19px
    $bigger: 18px


    body
        @include hide-scrollbar()




    [class*="ps-"]::after
        border-radius: 999px
        padding: 7px 8px 8px 8px
        font-size: 17px




    //  global styles




    .back
        display: none
    .clear-toolbars
        margin-top: $clear-toolbars !important
    .clear-navbar
        padding-top: $mob-navbar-height !important
    .clear-navbar-1
        padding-top: calc($mob-navbar-height + 1rem) !important




    //  global elements



    .-ArrowBack
            position: relative
            
            &::after
                @include xy
                // content: ''
                width: 3rem
                height: 3rem
                border-radius: 50%
                background: transparentize(white, .95)

            svg
                height: 2rem
                color: white !important
    #entry_proper
        padding: calc($mob-navbar-height + 1rem) 1rem $u-bottom-space 1rem

        .media-wrapper
            img
                width: 100vw
                max-height: unset
                object-fit: contain
            video
                width: 100vw
                max-height: calc(100vh - 36px - 48px) // 👀
                object-fit: contain
                background: black
    .global-rank
        @include global-rank(5px, 4px)
    .ico-interactive
        svg
            height: $ico-height
    .quick-view
        top: 0
        width: $width-major
        box-sizing: border-box
    .-rules
        position: relative
        flex: 1

        .rule-about
            @include x
            bottom: calc(100% - 8px)
            width: max-content
            max-width: 90vw
            font-size: $smallest
            padding: 8px
            margin: 0
            border-radius: 8px
            background: salmon

    //  planes

    .backing
        @include full-page
        @include x0y0
        background: transparentize(black, .3)
        z-index: 99998

        &.fixed
            position: fixed
    #ibox
        .info
            width: 90%



    
    $nav-spacing: 12px
    $nav-bar-logo-height: 1.75rem

    #-logo
        @include noselect()
        position: fixed
        left: 1rem
        top: calc($mob-navbar-height / 2)
        transform: translateY(-50%)
        height: $nav-bar-logo-height

        img, svg
            min-width: $nav-bar-logo-height
            width: $nav-bar-logo-height
            min-height: $nav-bar-logo-height
            height: $nav-bar-logo-height
    #mobile-navbar
        position: fixed
        left: 0
        top: 0
        width: 100vw
        height: $mob-navbar-height
        // border-bottom: 1px solid var(--dim)
        z-index: 99996
        // background: orangered !important

        .title
            @include no-marj()
            font-family: $font-theme-semibold
            font-size: $bigger
            padding: 6px 8px 8px 8px
            border-radius: 8px
            white-space: nowrap
            cursor: pointer

            &#general-chat-title
                color: $theme-color-alt !important
    #navigation
        position: fixed
        left: 0
        top: 0
        width: 100vw
        height: 100vh
        box-sizing: border-box
        background: $background-dark !important
        z-index: $zMAX

        .-squad-options
            margin: 4rem auto

            .-mod
                width: 8rem
                padding: 1rem
                border-radius: 8px
                background: transparentize(white, .98)

                &.inactive
                    p, svg
                        opacity: .5

                p
                    font-family: $font-squad
                    font-size: 1rem
                    margin-bottom: 0
                    color: var(--dim)

            .-mod.squad
                &.inactive svg
                    color: var(--dim)

                &:not(.inactive) p
                    color: $my-squad-color

            .-mod.global
                margin-left: 1rem

                &.inactive svg
                    stroke: var(--dim)

                &:not(.inactive) p
                    color: $global-squad-color

            svg
                height: 4rem

        .menu-items-wrapper
            padding-top: 2rem

            #menu_item_community img
                @include img($size: 2rem)
    #Profile
        @include noselect()
        right: 1rem
        top: calc($mob-navbar-height / 2)
        transform: translateY(-50%)
        
        img
            @include img($size: $nav-bar-logo-height)
            border: none

        #dms
            right: calc(100% + $nav-spacing)
    #-squad-option-navbar
        position: fixed
        top: 8px
        z-index: 99999

        .community img
            @include img($size: 4rem)
            @include noselect()
            
        .feed svg
            color: $theme-color
            height: 3.75rem
            margin-top: .125rem

        svg, img
            height: 4rem
    #toolbar, .filter-options
        // display: none !important
        top: $mob-navbar-height
        width: 100%
        // height: 20px
        padding: $single
        box-sizing: border-box

        .nav-pointer
            transform: translateY(-1.5px)

        .navlink
            font-size: 1rem
            transform: translateY(-1px)

        .community-svg svg
            height: 20px

        #-stats
            margin-right: 0
            
            p.stat.id-current::after
                top: calc(100% - 5px)
    #-logo-tools
        @include f-ja
        left: 1rem
        top: 12px
        width: 2rem
        height: 2rem

        #menu
            height: 1.5rem

        // #menu
        //     width: 1.5rem
        //     height: 1.25rem
        //     display: flex
        //     flex-direction: column
        //     justify-content: space-between

        //     div
        //         width: 100%
        //         height: 3px
        //         background: grey
    #profile-menu, .main-menu
        width: 100vw
        box-sizing: border-box
        padding: 0 3rem
        z-index: $zMAX

        .-X
            position: absolute
            right: 1rem
            top: 1rem

            svg
                height: 1.5rem
                color: mintcream

        .menu-items-wrapper
            padding: 0
            border-radius: unset
            background: unset !important

            > div
                @include noselect()
                padding: 1rem 1.5rem
                // border-bottom: 1px solid #333

                &:hover
                    background: none

                &#developer svg
                    height: 22px

                p
                    margin-left: 1rem




    #-ROUTERJS-
        &[theme="dark"]
            #mobile-navbar
                background: transparentize($background-dark, .01)

    #-HOME-
        $grid-gap: 2rem
        $page-padding: 8px // !! become site padding?
        $full-width: calc(calc(100vw - $page-padding * 2))

        width: $width-major
        padding: 0 $page-padding

        .-search
            margin: calc($mob-navbar-height + $grid-gap) 0 0 0

            input.search
                width: 100%
                height: 3rem
                font-size: 1.25rem
                padding-left: calc(2rem + 24px)
            
            svg
                height: 1.5rem

        #section-tags
            width: calc($width-major - 1rem)
            margin: 1rem auto $grid-gap auto

            p
                padding: 0

        #bigSearch .tag
            margin-top: 1em

        .grid
            display: grid
            grid-template-areas: 'g-communities' 'g-l1' 'g-l2' 'g-l3'
            grid-template-columns: unset
            grid-auto-flow: row
            grid-gap: $grid-gap

            .grid-gap-bottom
                margin-bottom: $grid-gap

        #-footer #footer
            box-sizing: border-box
            width: $full-width

    #-HOME2-
        margin-top: calc($mob-navbar-height + 2rem)
        padding: 0
        width: $width-major

        #pilot-logo
            // height: 5rem

        .community p.sub
            max-width: calc(100vw - 7rem)
            overflow: hidden
            text-overflow: ellipsis

        #footer
            padding: 1rem

    #CommunityLanding
        display: flex
        flex-direction: column
        width: $width-major
        padding-bottom: 0

        .exhibit .continue
            top: 0

    .App
        width: $width-major




    #CHAT
        padding: 0 1rem

        &[expand='true']
            margin: 0

        .chat-menu
            box-sizing: border-box
            width: 100%
            height: 100%
            padding: unset
            border-radius: unset
            background: unset

            .-chat-items
                .separator.first
                    margin-top: 0

                #chats-tools
                    font-size: $bigger
                    padding: 2rem 0

                .-chat-item 
                    .title
                        font-size: $biggest

                    p:not(.title)
                        margin-left: 4px

        .-chat
            #chat
                padding: 0 8px

                .__msg ._msg p.msg
                    font-size: 1rem

                    span.usn
                        font-size: 1rem

            .-textarea
                padding: 2rem 0

                textarea
                    font-size: $bigger
                    height: 24px

                    &::placeholder
                        font-size: 18px

    #__SQUAD__
        padding-top: calc($mob-navbar-height + 50px)
        padding-bottom: 33vh

        #StartingIn
            #p-starting-in
                font-size: 2rem

        .-user img
            @include img(2rem)

        .sticky .--header
            margin-bottom: 5rem

            svg
                height: 2rem

        #squad_overview
            border-top: none
            padding-top: 4rem

            .--header
                img
                    width: 2.5rem
                    height: 2.5rem

                .squad-id
                    font-size: 3rem

            #-overview-
                grid-column-gap: 4rem

                .stat
                    font-size: 2em !important

        #squad_activity
            // padding: 0 1rem
            padding-left: 3rem

            .squad-activity-section
                margin-bottom: 1rem

            .ps-top::after
                left: unset
                transform: none
                right: 0

        #journalled_today
            // background: unset !important
            padding: 1rem
            border-radius: 0

            // .--header
            //     svg, p
            //         color: var(--dim)

            #sticky-journals-unread
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
                grid-gap: .5rem 1rem

                .journal-entry
                    img
                        @include img($size: 1.75rem)
                        
                    // p
                    //     font-size: $smallest

        #recent_submissions
            padding: 0 .5rem

            #gallery
                padding: 0 1rem

            .--entry-preview.Squad
                padding: .5rem 0
    // #squad-selection
    //     top: calc($mob-navbar-height + 10vw)
    
    //     > div
    //         width: 20vw
    //         height: 20vw
    .-user
        img
            @include img(2.5rem)

        .dummy-img
            @include img(2.5rem)

            div
                @include img(2.5rem)
    #_users_
        $expand-accent-color: transparentize(white, .85)

        @include scrollbarX()
        overflow: scroll hidden
        bottom: 0
        width: $width-major
        padding: 0
        // padding-bottom: calc(2rem + 2.5rem)
        background: var(--users-background)
        transform: none
        // transition: transform .3s ease-out
        z-index: $zMAX

        &::-webkit-scrollbar
            height: 4px !important
        &::-webkit-scrollbar-thumb
            border-radius: 0
        &.squad-hidden
            transform: translateY(100%)
        &.block
            #wrapper
                height: fit-content
                position: absolute
                bottom: 7rem
        &.expand
            display: grid !important
            grid-template-rows: 1fr auto 1fr
            padding: 0
            z-index: $zMAX

            #credentials, .tools
                @include unset-position

            #credentials .squad-id
                margin-top: 1em
                margin-bottom: 1em

                &::after
                    display: none

            #wrapper
                @include scrollbarY()
                height: auto
                padding: 0 1rem
                overflow-y: scroll

            .tools
                padding: 2rem 0
                
        #credentials
            @include x
            top: 3rem
            position: fixed
            width: fit-content
            justify-self: center
            z-index: 1

            &.squad .squad-id
                color: orangered

            &.global .squad-id
                position: relative
                color: dodgerblue

                &::after
                    content: '(global)'
                    @include x
                    top: 100%
                    font-size: 1rem
                    color: var(--dim)

            img
                width: 2rem
                height: 2rem
                border-radius: 50%

            p
                @include no-marj()
                font-size: 2.25rem

        #wrapper
            min-width: 100%
            width: fit-content
            border-radius: 0
            padding: 0 1rem
            background: none

            // #-users
            //     transform: rotate(-90deg)

            //     .-user
            //         transform: rotate(90deg)

        .tools
            position: fixed
            bottom: 2rem
            width: 100%
            display: grid
            grid-template-columns: 1fr max-content 1fr
            align-items: center

            #squad-handles
                display: flex
                justify-self: center

                .click-me:not(.yes)
                    background: var(--dim)

            .click-me
                width: 2.5rem
                height: 2.5rem

                svg
                    color: var(--users-background) !important
                    stroke: var(--users-background) !important

            #_expand_
                margin: 0 auto 0 2rem

                &.active
                    background: white

                    svg
                        stroke: var(--background)

                svg
                    height: 40%
                    stroke: $expand-accent-color
            #_collapse_
                margin: 0 2rem 0 auto

                &.active
                    background: white

                    svg
                        color: var(--background)
                
                svg
                    height: 50%
                    color: $expand-accent-color
    #show-squad
        visibility: hidden
        position: fixed
        bottom: 0
        width: 100%
        padding: .5rem 0
        // background: transparentize(black, .2)
        z-index: 1
        transition: opacity .5s linear

        &.squad-hidden
            visibility: visible

        svg
            height: 2rem
            color: gold
            fill: gold

    
    #COMMUNITY
        padding: 0 1rem
        padding-bottom: 3rem




    #Trigger
        box-sizing: border-box
        width: 100vw
        padding: (calc($mob-navbar-height + 2rem)) 1rem

        .content
            grid-template-columns: 55% 45%
            grid-gap: 0
            box-sizing: border-box
            padding: 0 1rem 2rem 1rem

            .left, .right
                p
                    font-size: 2rem
                    text-align: left

                    span
                        position: unset
                        left: unset
                        transform: unset
                        display: block
                        font-size: 1rem
                        min-width: unset

            .left
                margin-top: 1rem

            p#trigger-id
                text-align: center

            p#unicode
                font-size: 8rem

                &.lewd
                    transform: unset
    
    #-LADDER-
        #container
            padding: calc($mob-navbar-height + 1rem) 0 1.5rem

            #ctr-1
                #-options
                    left: 3rem

            //     #-ladder
            //         left: unset
            //         right: $double

            //         #-triggers-group-
            //             left: unset
            //             right: 1.25rem

            //             #-stats-triggers
            //                 left: unset
            //                 right: $half

            //         .-triggers
            //             margin-left: auto

            //             .-trigger
            //                 &.first-e::after
            //                     right: unset
            //                     left: 100%

            //                 .-common-total-, .bg-ctr
            //                     left: unset
            //                     right: 0

            //             .appendum
            //                 margin-left: 0
            //                 margin-right: 2px

            //             .-common-total
            //                 right: unset
            //                 left: 0
            //                 top: 0
            //                 transform: translate(-66%, -50%)

            //             p.time.pseudo-top
            //                 left: unset
            //                 right: 0




    #UserProfile
        width: $width-major
        padding-top: $mob-navbar-height

        #focus
            .-img
                padding: $double 0

            #feed-user
                padding: 3rem 1rem
