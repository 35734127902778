// notes

// .fp ~ 06-08-2024 ~
// 	@include full-page

// deprecate txt-u etc. in place of 'underline'

@import 'colors'

$ff: translate(-50%, -50%)

$out-rem: calc( 100% + 1rem )
$out-8: calc( 100% + 8px )
$out-qtr-rem: calc( 100% + 4px )

$less-rem: calc(100% - 2rem)

@keyframes -fade-out
	0%
		opacity: 1

	100%
		opacity: 0
@keyframes -fade-in
	0%
		opacity: 0

	100%
		opacity: 100%
@keyframes -blink
	0%
		opacity: 1

	50%
		opacity: 0

	100%
		opacity: 1
@keyframes -blink-double
	0%
		opacity: 1

	25%
		opacity: 0

	50%
		opacity: 1

	75%
		opacity: 0

	100%
		opacity: 1

@mixin full-page
	width: 100vw
	height: 100vh
@mixin full-parent
	width: 100%
	height: 100%
@mixin f-ja
	display: flex
	justify-content: center
	align-items: center
@mixin f-j
	display: flex
	justify-content: center
@mixin f-a
	display: flex
	align-items: center
@mixin x
	position: absolute
	left: 50%
	transform: translateX(-50%)
@mixin y
	position: absolute
	top: 50%
	transform: translateY(-50%)
@mixin xy
	position: absolute
	left: 50%
	top: 50%
	transform: translate(-50%, -50%)
@mixin x0y0
	position: absolute
	left: 0
	top: 0

@mixin margin-tb($a)
	margin-top: $a
	margin-bottom: $a
@mixin nudge($value)
	transform: translateY($value)
@mixin rlink
	cursor: pointer

	&:hover
		text-decoration: underline

@mixin default-button
	font-size: 90%
	margin: 0 .5rem
	padding: .2rem
	border-radius: 5px
	cursor: pointer

	&:hover
		opacity: .8
@mixin hide-scrollbar()
	&::-webkit-scrollbar
		display: none
@mixin no-marj()
	margin-top: 0
	margin-bottom: 0
@mixin noselect()
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
	-webkit-tap-highlight-color: transparent
@mixin reset-input
	// font-style: 
	// font-variant-ligatures: 
	// font-variant-caps: 
	// font-variant-numeric: 
	// font-variant-east-asian: 
	// font-variant-alternates: 
	// font-variant-position: 
	// font-weight: 
	// font-stretch: 
	// font-size: 
	// font-family: 
	// font-optical-sizing: 
	// font-kerning: 
	// font-feature-settings: 
	// font-variation-settings: 
	text-rendering: auto
	color: fieldtext
	letter-spacing: normal
	word-spacing: normal
	line-height: normal
	text-transform: none
	text-indent: 0px
	text-shadow: none
	display: inline-block
	text-align: start
	appearance: auto
	-webkit-rtl-ordering: logical
	cursor: text
	background-color: field
	margin: 0em
	padding: 1px 0px
	border-width: 2px
	border-style: inset
	border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133))
	border-image: initial
	padding-block: 1px
	padding-inline: 2px
@mixin unset-input
	border: none
	border-radius: 0
	outline: none
	padding: 0
	background: none
@mixin unset-link()
	cursor: initial

	&:hover
		text-decoration: none
@mixin unset-margin-tb()
	margin-top: 0
	margin-bottom: 0
@mixin unset-position
	position: relative
	top: unset
	right: unset
	bottom: unset
	left: unset
	transform: none

.x
	@include x
.y
	@include y
.xy
	@include xy
.x0y0
	@include x0y0
.unset-input
	@include unset-input

//  📐 guides

#DevRulers
	#x
		@include y
		position: fixed
		width: 100vw
		height: 2px
		background: red
		z-index: 9999999
	#y
		@include x
		position: fixed
		width: 2px
		height: 100vh
		background: blue
		z-index: 9999999
	#custom
		// @include xy
		// width: ?
		// height: ?
		border-left: 1px solid limegreen
		border-right: 1px solid limegreen
	#drag-me
		display: flex
		justify-content: space-between
		position: absolute
		left: 0.5rem
		top: 0
		height: 100vh
		width: 22px

		.rule
			height: 100%
			width: 2px
			background: yellow

//			cursor

.cursor-default
	cursor: default !important
.pointer
	cursor: pointer
.no-select
	user-select: none

//			properties

body
	margin: 0

img, svg
	display: block

//			display

.inline-block
	display: inline-block

//			position

.posrel
	position: relative

//			sizes

.fit-content
	width: fit-content

//			containers, modals & planes

[class^="--f"]
	display: flex
	flex-direction: column

	&[class*="--f-a"]
		align-items: center
	&[class*="--f-j"]
		justify-content: center

		&[class*="-j-sb"]
			justify-content: space-between
	&[class*="--f-ja"]
		justify-content: center
		align-items: center
[class^="-f-"]
	display: flex

	&[class*="-f-a"]
		align-items: center
	&[class*="-f-j"]
		justify-content: center

		&[class*="-j-sb"]
			justify-content: space-between
	&[class*="-f-ja"]
		justify-content: center
		align-items: center
[class*="-m-"]
	@include full-page
	position: absolute
	left: 0
	top: 0
	z-index: 1

	.back
		width: 100%
		height: 100%

		&.dark
			background: transparentize(black, .8)
[class*="-p-"]
	@include xy

	&[class*="-p-block"]
		@include full-parent
		z-index: 1
	&[class*="-p-back"]
		@include full-page
		position: fixed
		z-index: -1

//			inheritance

.inherit-w
	width: inherit

//			colors

.black
	color: black !important

//			margin

.m0
	margin: 0
.mt0, .firstelm-mt0
	margin-top: 0
.mt0IMP
	margin-top: 0 !important
.mb0, .lastelm-mb0
	margin-bottom: 0
.mtb0, .nomarj
	margin-top: 0
	margin-bottom: 0

//			links

a
	display: contents
.hyperlink, .hlink, .h-link
	font-weight: normal
	color: blue !important
	cursor: pointer

	&:hover
		text-decoration: underline
.rlink // regular link
	@include rlink

//			text

.txt-u, .underline
	text-decoration: underline
.txt-i, .italic
	font-style: italic
.txt-b, .bold
	font-weight: bold
.bolder
	font-weight: bolder
.txt-u
	text-decoration: underline
.pre
	white-space: pre
.nowrap
	white-space: nowrap

//			pseudo-elements

[class*="ps-"]
	&:hover::after
		visibility: visible
	&::after
		visibility: hidden
		width: max-content
		white-space: nowrap
		z-index: 999
	&[class*="-static"]::after
		border-radius: 4px !important
		transition: none !important
	&[class*="ps-left"]
		&::after
			@include y
			right: calc( 100% + 1.5rem )
			transition: right .3s ease-out

		&:hover::after
			right: calc( 100% + 1rem )
	&[class*="ps-right"]
		&::after
			@include y
			left: calc( 100% + 1.5rem )
			transition: left .3s ease-out

		&:hover::after
			left: calc( 100% + 1rem )
	&[class*="ps-top"]
		&::after
			@include x
			bottom: calc( 100% + 1.5rem )
			transition: bottom .3s ease-out

		&:hover::after
			bottom: calc( 100% + 1rem )
	&[class*="ps-bottom"]
		&::after
			@include x
			top: calc( 100% + 1.5rem )
			transition: top .3s ease-out

		&:hover::after
			top: calc( 100% + 1rem )
	&[class*="ps-top-mini"]
		&::after
			@include x
			bottom: calc( 100% + 1rem )
			transition: bottom .3s ease-out
		&:hover::after
			bottom: calc( 100% + .5rem )
	&[class*="ps-bottom-mini"]
		&::after
			@include x
			top: calc( 100% + 1rem )
			transition: top .3s ease-out

		&:hover::after
			top: calc( 100% + .5rem )
[class*="pseudo-"]
	&:hover .pseudo
		visibility: visible
	&[class*="pseudo-top"]
		&:hover .pseudo
			bottom: calc( 100% + 1rem )
		.pseudo
			@include x
			bottom: calc( 100% + 1.5rem )
			transition: bottom .3s ease-out
	&[class*="pseudo-top-mini"]
		&:hover .pseudo
			bottom: calc( 100% + .5rem )
		.pseudo
			@include x
			bottom: calc( 100% + 1rem )
			transition: bottom .3s ease-out
	&[class*="pseudo-bottom"]
		&:hover .pseudo
			top: calc( 100% + 1rem )

		.pseudo
			@include x
			top: calc( 100% + 1.5rem )
			transition: top .3s ease-out

	.pseudo
		visibility: hidden
		white-space: nowrap

.ps-opacity
	&:hover .ps-elem
		opacity: 1

	.ps-elem
		opacity: 0

.no-overflow
	overflow: hidden

//			devices

.devices-no-tap, .noselect
	@include noselect()
