@font-face {
  font-family: Consolas;
  src: url("../font/consolas.TTF");
}
#developer-window {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(calc(100% - 1rem), -50%);
  padding-left: 1rem;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}
#developer-window.open {
  transform: translate(0, -50%);
}
#developer-window #toggle-Dev {
  position: absolute;
  left: 2rem;
  top: 0.5rem;
  font-family: Consolas;
  font-size: 1rem;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#developer-window #X-mob {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-family: Consolas;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.5);
  transform: translateY(-1rem);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#developer-window #contents {
  box-sizing: border-box;
  width: 500px;
  height: 100%;
  padding: 1rem;
  background: #1E1E1E;
  overflow-y: scroll;
}
#developer-window #contents::-webkit-scrollbar {
  display: none;
}
#developer-window #contents p {
  font-family: "Consolas";
}
#developer-window #contents p.dim {
  margin-bottom: 0;
}
#developer-window #contents p.dim#first-child {
  margin-top: 2rem;
}
#developer-window #contents p.header {
  font-family: Consolas;
  color: #555;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0.5em auto;
  text-align: center;
}
#developer-window #contents div {
  padding: 1rem;
}
#developer-window #contents div p.function {
  color: #DCDCAA;
  cursor: pointer;
}
#developer-window #contents div p.unavailable {
  text-decoration: line-through;
}
#developer-window #contents div p.unavailable:hover {
  color: indianred;
}
#developer-window #contents div p.bool {
  color: #569CD6;
  cursor: pointer;
}
#developer-window #contents div p.style {
  text-align: right;
  color: #D4D4D4;
}
#developer-window #contents .grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 650px) {
  #developer-window #contents {
    width: 100vw;
  }
}/*# sourceMappingURL=developer.css.map */