@import 'colors'
@import 'global'


$background: #171717
$color: ghostwhite
$beta-blue: #1e2a43
$beta-white: color-mix(in srgb, white 66%, #1e2a43)

$char-width: 10.45px
$char-height: 22.4px

$font-size: 19px
$bigger: 17px
$smaller: 15px
$smallest: 14px


@keyframes blink
    from
        opacity: 0
        
    to
        opacity: 100%
@keyframes bzz-login
    0%
        left: -3px
    50%
        left: 3px
    100%
        left: 0
@keyframes spin
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
@keyframes pop-up
    0%
        top: 100%

    100%
        top: 0
@keyframes spin-y
    0%
        transform: translateY(-50%) rotate(0deg)

    100%
        transform: translateY(-50%) rotate(360deg)


.color-login
    color: $color

.bigger
    font-size: 17px

p
    color: $beta-white

#intro p
    font-family: pixel
    font-size: 3.5rem
    font-weight: bold
    color: $beta-white
    margin: 0

    &.input
        position: absolute
        left: 0
        top: 0

    &.shadow
        visibility: hidden

.Login, #Join
    background: $background
    overflow: hidden

#Join

    $color: salmon

    // &[progress='_2']
    //     input:not([type='file'])
    //         margin-top: 4px

    &[progress='_3']
        .-tick svg
            height: 3rem
            color: palegreen

    .panel
        display: flex
        flex-direction: column

        .click-me
            position: absolute
            left: 1rem
            top: 1rem
            width: 2rem
            height: 2rem

    label, .label
        // color: transparentize($color, .1)
        color: grey

    .label
        position: absolute
        left: 0
        bottom: 100%
        width: fit-content
        margin: 0

        &#input-image
            margin-bottom: 2rem

    #profileImage
        width: 166px
        height: 166px
        margin: 1rem auto 4rem auto
        border-radius: 50%
        object-fit: cover
        cursor: pointer

        &:hover
            opacity: .8

    .-input
        position: relative

        &.incorrect
            // .label
            //     color: red !important

            input
                color: red !important
            //     border-color: red !important
            //     background: transparentize(red, .7)

        img.flag
            $flag-height: 1rem
            width: 1.4 * $flag-height
            height: $flag-height
            object-fit: cover

        span.flag
            $flag-height: 1rem
            $flag-width: calc($flag-height / 2 * 3)
            position: relative
            display: inline-block
            width: $flag-width
            min-width: $flag-width
            height: $flag-height
            min-height: $flag-height
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            line-height: 1em
            // vertical-align: middle
                
            &::before
                content: '\00a0'

        .err
            font-size: $smallest
            margin: 0
            color: red

        .shadow
            position: absolute
            left: 0
            top: 0
            padding: 10px 0 10px 12px
            z-index: -1

            p#p-shadow
                visibility: hidden
                font-family: "Segoe UI"
                font-size: 20px
                margin: 0

            .-check
                position: absolute
                top: 50%
                transform: translateY(calc(-50% + 2px))
                left: $out-8

                .-spinner svg
                    height: 12px
                    fill: lightgrey
                    animation: spin .66s linear infinite

                .-tick
                    &.tick-fill svg
                        height: 10px
                        fill: palegreen

                    &.tick-stroke svg
                        height: 18px
                        color: palegreen

                p#unavailable
                    font-size: $smallest
                    margin: 0
                    color: salmon
                    white-space: nowrap

    input
        @include unset-input
        font-family: "Segoe UI"
        font-size: 1rem
        display: block
        box-sizing: border-box
        width: 100%
        color: lightgrey

        // &.mb1
        //     margin-bottom: 1rem

        // &[type='file']
        //     padding-top: 12px

        &:not([type='file'])
            font-size: 20px
            padding: 10px
            // border-bottom: 1px solid transparentize($color, .5)

    .button
        box-sizing: border-box
        // width: 50vw
        font-family: Consolas
        font-size: 18px
        color: $background
        background: $color
        padding: 12px 0
        margin-top: auto
        border-radius: 8px
        text-align: center
        cursor: pointer



@media (orientation: landscape)
    #logo-svg
        position: absolute
        left: 1.5rem
        top: 1.5rem
        height: 1.5rem
        fill: transparentize(white, .66)
        z-index: 9

    #signIn
        position: absolute
        right: 1.5rem
        top: 1.5rem
        font-family: Consolas
        width: fit-content
        color: transparentize(white, .66)
        // padding: 12px
        // color: $background
        // background: $color
        // border-radius: 5px
        z-index: 9
        cursor: pointer

        &:hover
            opacity: .8

    .-spinner
        &.loggingIn svg
            fill: palegreen

        svg
            height: 1rem
            fill: $color
            animation: spin .66s linear infinite

    .Login, #Join
        position: relative
        width: 100vw
        height: 100vh

    .Login
        #-login
            // left: calc(50% - 41.8px) // 00
            // height: $char-height

            &.awaiting span.usr
                opacity: .5

            &.incorrect
                span.usr, input
                    color: red
                    font-weight: bold
                    animation: bzz-login .2s linear forwards

                #cursor
                    background: red

            .-spinner
                @include y
                left: $out-8

        p
            max-width: 500px
            height: $char-height
            font-family: Fira Code
            font-size: $font-size
            margin: 0
            color: $beta-white
            white-space: pre

            span
                position: relative
                width: fit-content

                &.sys
                    // color: color-mix(in srgb, $color 66%, $background)
                    color: $color
                    user-select: none

                &.usr
                    position: relative
                    user-select: none

                &.rlink
                    user-select: unset !important
                    color: $color
                    cursor: pointer

                    &:hover
                        text-decoration: underline

                &.new-line
                    display: block
                    height: $char-height

        #cursor
            position: absolute
            width: $char-width
            height: $char-height
            background: $color
            top: 0

        input
            @include unset-input
            position: relative
            font-size: $font-size
            color: $beta-white

            &::placeholder
                color: $color

    #Join
        .panel
            width: 350px
            height: 466px
            padding: 2rem
            border-radius: 2rem
            background: transparentize(grey, .9)

            #entryMethod
                // width: 100%
                margin: 1em auto 3rem auto
                // border-bottom: 3px solid dodgerblue

                p
                    margin: 0
                    padding: 8px
                    border-bottom: 3px solid transparent
                    color: grey
                    cursor: pointer

                    &.selected
                        color: dodgerblue
                        border-color: dodgerblue

            p.progressHandle
                margin: 0
                padding: 12px
                color: dodgerblue
                text-align: center
                cursor: pointer

                &#fin
                    margin-top: auto

            // by api

            .sign-in-api
                border-radius: 999px
                overflow: hidden
                cursor: pointer

                &.Google
                    &.html-button
                        .gsi-material-button
                            -moz-user-select: none
                            -webkit-user-select: none
                            -ms-user-select: none
                            -webkit-appearance: none
                            background-color: #f2f2f2
                            background-image: none
                            border: none
                            -webkit-border-radius: 20px
                            border-radius: 20px
                            -webkit-box-sizing: border-box
                            box-sizing: border-box
                            color: #1f1f1f
                            cursor: pointer
                            font-family: 'Roboto', arial, sans-serif
                            font-size: 14px
                            width: 100%
                            height: 40px
                            letter-spacing: 0.25px
                            outline: none
                            overflow: hidden
                            padding: 0 12px
                            position: relative
                            text-align: center
                            -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s
                            transition: background-color .218s, border-color .218s, box-shadow .218s
                            vertical-align: middle
                            white-space: nowrap
                            width: auto
                            max-width: 400px
                            min-width: min-content

                        .gsi-material-button .gsi-material-button-icon
                            height: 20px
                            margin-right: 12px
                            min-width: 20px
                            width: 20px

                        .gsi-material-button .gsi-material-button-content-wrapper
                            -webkit-align-items: center
                            align-items: center
                            display: flex
                            -webkit-flex-direction: row
                            flex-direction: row
                            -webkit-flex-wrap: nowrap
                            flex-wrap: nowrap
                            height: 100%
                            justify-content: space-between
                            position: relative
                            width: 100%

                        .gsi-material-button .gsi-material-button-contents
                            -webkit-flex-grow: 1
                            flex-grow: 1
                            font-family: 'Roboto', arial, sans-serif
                            font-weight: 500
                            overflow: hidden
                            text-overflow: ellipsis
                            vertical-align: top

                        .gsi-material-button .gsi-material-button-state
                            -webkit-transition: opacity .218s
                            transition: opacity .218s
                            bottom: 0
                            left: 0
                            opacity: 0
                            position: absolute
                            right: 0
                            top: 0

                        .gsi-material-button:disabled
                            cursor: default
                            background-color: #ffffff61

                        .gsi-material-button:disabled .gsi-material-button-state
                            background-color: #1f1f1f1f

                        .gsi-material-button:disabled .gsi-material-button-contents
                            opacity: 38%

                        .gsi-material-button:disabled .gsi-material-button-icon
                            opacity: 38%

                        .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
                            .gsi-material-button:not(:disabled):focus .gsi-material-button-state
                            background-color: #001d35
                            opacity: 12%

                        .gsi-material-button:not(:disabled):hover
                            -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)
                            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)

                        .gsi-material-button:not(:disabled):hover .gsi-material-button-state
                            background-color: #001d35
                            opacity: 8%

                    div
                        margin: 0

                    .nsm7Bb-HzV7m-LgbsSe
                        border-color: white

                &.Facebook
                    height: 40px
                    margin-top: 1rem
                    padding: 0 12px
                    background: #3578e5

                    .-icon
                        @include f-a
                        width: 18px
                        height: 18px

                        img, svg
                            height: 20px

                    p
                        font-family: Arial, sans-serif
                        font-size: 14px
                        font-weight: 500
                        margin: 0 auto

            // by email

            .sign-in-email
                margin-bottom: 2rem
                padding-bottom: 2rem
                border-bottom: 1px solid transparentize(grey, .66)

                input
                    @include unset-input
                    height: 50px
                    font-size: 1rem
                    padding: 6px 12px
                    background: #ffffff0d
                    border-radius: 4px

                    &.error
                        color: red

                    &::-ms-reveal
                        filter: invert(100%)

                .sub
                    position: absolute
                    left: 0
                    top: 100%
                    font-size: $smallest
                    color: red
                    margin: .5em 0

                    &.forgot
                        color: dodgerblue
                        left: unset
                        right: 0

            #wrapper-sign-in-up
                margin: 1rem auto 0 auto

                .progressHandle#sign-in-up
                    width: fit-content

                .-spinner
                    @include y
                    transform: translateY(calc(-50% + 1px))
                    left: 100%

                    svg
                        height: 15px

            // progress=2

            .-input
                input:not([type='file']), .shadow
                    padding: 10px 0

                .drop-menu
                    @include hide-scrollbar()
                    position: absolute
                    left: 0
                    top: 100%
                    box-sizing: border-box
                    height: 150px
                    min-width: 100%
                    // padding: 0 1rem
                    border-radius: 8px
                    background: $background
                    overflow: hidden scroll

                    p
                        padding: 1em
                        margin: 0
                        color: grey
                        white-space: nowrap

                        &.menu-item
                            border: 1px solid transparent
                            border-radius: 8px
                            cursor: pointer

                            // &.overseas-territory:hover span
                            //     color: lightgrey
                            //     opacity: 1

                            // &:not(.overseas-territory):hover
                            //     color: lightgrey
                            
                            &:hover, &.highlight
                                border-color: salmon

                            span
                                opacity: .5

@media (orientation: portrait)
    $font-size: 22px
    $color: $beta-white
    $background: $background

    .Login, #Join
        background: $background

    .Login
        p.logging-in-status
            @include xy
            font-size: 1.25rem
            margin: 0
            color: $beta-white
            white-space: nowrap

            span.dots
                left: 100%

            .Spinner
                @include y
                left: $out-8
                height: 1rem
                fill: $beta-white
                animation: spin-y 1s linear infinite
        
        #KEY
            #-keySVGmacro
                @include xy

                .Spinner
                    margin-top: 2px
                
                #keySVGmacro
                    height: 3rem
                    fill: $beta-white

        #PIN
            display: flex
            flex-direction: column
            height: 100%

            p
                font-family: Fira Code

            #enter-PIN
                // font-family: 'Segoe UI'
                font-size: 1.5rem
                margin: auto
                color: $beta-white

            .-user-PIN
                position: relative
                margin: auto auto 15vw auto

                .user-PIN
                    position: relative
                    display: grid
                    grid-auto-columns: 1fr
                    grid-auto-flow: column
                    grid-gap: 7vw
                    width: fit-content
                    margin: auto
                    overflow: hidden

                    &.login-false
                        animation: bzz-login .2s linear forwards

                        p
                            color: red

                    p
                        position: relative
                        font-family: Consolas
                        font-size: 7vw
                        font-weight: bold
                        margin: 0
                        color: $beta-white

                        &.placeholder
                            color: var(--dim)
                            opacity: .5

                        &.animate
                            animation: pop-up .2s ease forwards

            .dial-pad
                // @include x
                // position: fixed
                // bottom: 10vw
                margin-bottom: 10vw
                box-sizing: border-box
                display: grid
                grid-template-columns: repeat(3, 1fr)
                width: 100%
                padding: 0 1rem

                .pressed
                    animation: blink .1s steps(1)

                .button
                    position: relative

                    &::after
                        @include xy
                        transform: translate(-50%, -55%)
                        content: ''
                        width: 5rem
                        height: 5rem
                        border-radius: 50%
                        background: $beta-white
                        z-index: -1

                .-num
                    @include f-ja
                    position: relative
                    aspect-ratio: 1.3
                    cursor: pointer
                    z-index: 1

                    p.dig
                        font-family: Consolas
                        font-size: 2.75rem
                        margin: 0
                        transform: translateY(4px)
                        color: $background

                        span.alpha
                            top: 100%
                            font-size: $bigger
                            margin: 0
                            letter-spacing: 2px
                            opacity: .5

                #del img
                    transform: translate(2px, -3px)
                    height: 1.75rem
