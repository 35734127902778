@font-face
    font-family: Consolas
    src: url('../font/consolas.TTF')


$function: #DCDCAA
$bool: #569CD6
$other: #D4D4D4



#developer-window
    position: fixed
    right: 0
    top: 50%
    transform: translate(calc(100% - 1rem), -50%)
    padding-left: 1rem
    z-index: 99999
    display: flex
    flex-direction: column

    &.open
        transform: translate(0, -50%)

    #toggle-Dev
        position: absolute
        left: 2rem
        top: .5rem
        font-family: Consolas
        font-size: 1rem
        margin: 0
        user-select: none

    #X-mob
        position: absolute
        right: 1rem
        top: .5rem
        font-family: Consolas
        font-size: 3rem
        color: transparentize(white, .5)
        transform: translateY(-1rem)
        user-select: none
     
    #contents
        box-sizing: border-box
        width: 500px
        height: 100%
        padding: 1rem
        background: #1E1E1E
        overflow-y: scroll

        &::-webkit-scrollbar
            display: none

        p
            font-family: 'Consolas'

            &.dim
                margin-bottom: 0

                &#first-child
                    margin-top: 2rem

            &.header
                font-family: Consolas
                color: #555
                user-select: none
                margin: .5em auto
                text-align: center

        div
            padding: 1rem

            p
                &.function
                    color: $function
                    cursor: pointer

                &.unavailable
                    text-decoration: line-through

                    &:hover
                        color: indianred
                
                &.bool
                    color: $bool
                    cursor: pointer

                &.style
                    text-align: right
                    color: $other

        .grid
            display: grid
            grid-template-columns: auto auto
            grid-gap: 1rem
            width: fit-content


    
@media (max-width: 650px)
    #developer-window
        #contents
            width: 100vw

