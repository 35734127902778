@keyframes -fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes -fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes -blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes -blink-double {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.x0y0 {
  position: absolute;
  left: 0;
  top: 0;
}

.unset-input {
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  background: none;
}

#DevRulers #x {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  width: 100vw;
  height: 2px;
  background: red;
  z-index: 9999999;
}
#DevRulers #y {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: 2px;
  height: 100vh;
  background: blue;
  z-index: 9999999;
}
#DevRulers #custom {
  border-left: 1px solid limegreen;
  border-right: 1px solid limegreen;
}
#DevRulers #drag-me {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0.5rem;
  top: 0;
  height: 100vh;
  width: 22px;
}
#DevRulers #drag-me .rule {
  height: 100%;
  width: 2px;
  background: yellow;
}

.cursor-default {
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

body {
  margin: 0;
}

img, svg {
  display: block;
}

.inline-block {
  display: inline-block;
}

.posrel {
  position: relative;
}

.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

[class^="--f"] {
  display: flex;
  flex-direction: column;
}
[class^="--f"][class*="--f-a"] {
  align-items: center;
}
[class^="--f"][class*="--f-j"] {
  justify-content: center;
}
[class^="--f"][class*="--f-j"][class*=-j-sb] {
  justify-content: space-between;
}
[class^="--f"][class*="--f-ja"] {
  justify-content: center;
  align-items: center;
}

[class^=-f-] {
  display: flex;
}
[class^=-f-][class*=-f-a] {
  align-items: center;
}
[class^=-f-][class*=-f-j] {
  justify-content: center;
}
[class^=-f-][class*=-f-j][class*=-j-sb] {
  justify-content: space-between;
}
[class^=-f-][class*=-f-ja] {
  justify-content: center;
  align-items: center;
}

[class*=-m-] {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
[class*=-m-] .back {
  width: 100%;
  height: 100%;
}
[class*=-m-] .back.dark {
  background: rgba(0, 0, 0, 0.2);
}

[class*=-p-] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
[class*=-p-][class*=-p-block] {
  width: 100%;
  height: 100%;
  z-index: 1;
}
[class*=-p-][class*=-p-back] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.inherit-w {
  width: inherit;
}

.black {
  color: black !important;
}

.m0 {
  margin: 0;
}

.mt0, .firstelm-mt0 {
  margin-top: 0;
}

.mt0IMP {
  margin-top: 0 !important;
}

.mb0, .lastelm-mb0 {
  margin-bottom: 0;
}

.mtb0, .nomarj {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  display: contents;
}

.hyperlink, .hlink, .h-link {
  font-weight: normal;
  color: blue !important;
  cursor: pointer;
}
.hyperlink:hover, .hlink:hover, .h-link:hover {
  text-decoration: underline;
}

.rlink {
  cursor: pointer;
}
.rlink:hover {
  text-decoration: underline;
}

.txt-u, .underline {
  text-decoration: underline;
}

.txt-i, .italic {
  font-style: italic;
}

.txt-b, .bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.txt-u {
  text-decoration: underline;
}

.pre {
  white-space: pre;
}

.nowrap {
  white-space: nowrap;
}

[class*=ps-]:hover::after {
  visibility: visible;
}
[class*=ps-]::after {
  visibility: hidden;
  width: -moz-max-content;
  width: max-content;
  white-space: nowrap;
  z-index: 999;
}
[class*=ps-][class*=-static]::after {
  border-radius: 4px !important;
  transition: none !important;
}
[class*=ps-][class*=ps-left]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 1.5rem);
  transition: right 0.3s ease-out;
}
[class*=ps-][class*=ps-left]:hover::after {
  right: calc(100% + 1rem);
}
[class*=ps-][class*=ps-right]::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 1.5rem);
  transition: left 0.3s ease-out;
}
[class*=ps-][class*=ps-right]:hover::after {
  left: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top]:hover::after {
  bottom: calc(100% + 1rem);
}
[class*=ps-][class*=ps-bottom]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom]:hover::after {
  top: calc(100% + 1rem);
}
[class*=ps-][class*=ps-top-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=ps-][class*=ps-top-mini]:hover::after {
  bottom: calc(100% + 0.5rem);
}
[class*=ps-][class*=ps-bottom-mini]::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1rem);
  transition: top 0.3s ease-out;
}
[class*=ps-][class*=ps-bottom-mini]:hover::after {
  top: calc(100% + 0.5rem);
}

[class*=pseudo-]:hover .pseudo {
  visibility: visible;
}
[class*=pseudo-][class*=pseudo-top]:hover .pseudo {
  bottom: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-top] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1.5rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-top-mini]:hover .pseudo {
  bottom: calc(100% + 0.5rem);
}
[class*=pseudo-][class*=pseudo-top-mini] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 1rem);
  transition: bottom 0.3s ease-out;
}
[class*=pseudo-][class*=pseudo-bottom]:hover .pseudo {
  top: calc(100% + 1rem);
}
[class*=pseudo-][class*=pseudo-bottom] .pseudo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 1.5rem);
  transition: top 0.3s ease-out;
}
[class*=pseudo-] .pseudo {
  visibility: hidden;
  white-space: nowrap;
}

.ps-opacity:hover .ps-elem {
  opacity: 1;
}
.ps-opacity .ps-elem {
  opacity: 0;
}

.no-overflow {
  overflow: hidden;
}

.devices-no-tap, .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes dip {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 100%);
  }
}
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  25%, 75% {
    transform: scale(1.1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#x {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  height: 2px;
  background: yellow;
}

.b-background {
  background: #1e2a43;
}

.b-color {
  color: #1e2a43;
}

.bool {
  color: orange !important;
}

.other {
  color: #569CD6 !important;
}

.tiny {
  font-size: 13px !important;
}

.b-ui-element {
  position: fixed;
  cursor: pointer;
}
.b-ui-element#bArrowBack {
  left: 1rem;
  top: 1rem;
}
.b-ui-element#bArrowBack svg {
  fill: none !important;
}
.b-ui-element#bX, .b-ui-element#bX-Landing {
  right: 1rem;
  top: 1rem;
}
.b-ui-element#bX-Landing {
  transform: rotate(180deg);
}
.b-ui-element svg {
  height: 1.5rem;
  color: white !important;
}

.meta p {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0.5em 0;
  color: rgba(248, 248, 255, 0.56);
  text-align: center;
  cursor: pointer;
}
.meta p:hover {
  -webkit-text-decoration: dotted underline;
          text-decoration: dotted underline;
}

#project_id {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: rgba(248, 248, 255, 0.56);
  text-align: center;
}

.ui-handle.--fill svg {
  fill: rgba(248, 248, 255, 0.56) !important;
}

#BetaLanding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: #1e2a43;
  overflow-y: scroll;
  z-index: 999999;
}
#BetaLanding::-webkit-scrollbar {
  width: 4px;
}
#BetaLanding::-webkit-scrollbar-track {
  background: none;
}
#BetaLanding::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
#BetaLanding #-About {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 1rem;
}
#BetaLanding #-About svg {
  height: 1.5rem;
  color: white;
}
#BetaLanding .content {
  width: 600px;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}
#BetaLanding .content p {
  font-family: "Fira Code", monospace;
}
#BetaLanding .content p.section-h {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700;
  font-size: 1.5rem;
}
#BetaLanding .content p.sub {
  font-size: 15px;
  margin: 2em 0;
  color: color-mix(in srgb, white 66%, #1e2a43);
}
#BetaLanding .content p.sub span {
  font-weight: bold;
}
#BetaLanding .content p.sub .svg-triangle {
  display: inline-block;
  height: 8px;
  fill: color-mix(in srgb, white 66%, #1e2a43);
}
#BetaLanding .content #FVH p {
  color: color-mix(in srgb, white 66%, #1e2a43);
}
#BetaLanding .content #FVH p.version {
  color: white;
  mix-blend-mode: overlay;
  font-weight: bold;
  margin-top: 3em;
}
#BetaLanding .content #FVH p.version span {
  font-size: 15px;
  font-weight: normal;
}

#BetaPortal {
  position: absolute;
  left: 0;
  top: 0;
  position: fixed;
  display: none;
  width: 100vw;
  background: #1e2a43;
  overflow-y: scroll;
  z-index: 999999;
}
#BetaPortal.open {
  display: block;
}
#BetaPortal::-webkit-scrollbar {
  width: 4px;
}
#BetaPortal::-webkit-scrollbar-track {
  background: none;
}
#BetaPortal::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
#BetaPortal .-ui-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
#BetaPortal .-ui-handle#-back {
  position: absolute;
  left: 1rem;
  top: calc(1rem + 4px);
  width: 2.5rem;
  height: 2.5rem;
}
#BetaPortal .-ui-handle#-back svg {
  fill: #1e2a43;
  height: 1.25rem;
}
#BetaPortal .-ui-handle:hover {
  background: rgba(255, 255, 255, 0.2);
}
#BetaPortal .-ui-handle svg {
  height: 1.5rem;
  color: inherit;
}
#BetaPortal p {
  font-family: "Fira Code", monospace;
}
#BetaPortal p span.inline-report {
  color: salmon;
}
#BetaPortal .-tick {
  position: relative;
}
#BetaPortal .-tick::after {
  content: "saved";
  color: #1e2a43;
  background: indianred;
}
#BetaPortal ._tick_ {
  height: 10px;
  fill: palegreen;
}
#BetaPortal .-Spinner svg {
  vertical-align: middle;
}
#BetaPortal .Spinner {
  height: 1rem;
  fill: palegreen;
  animation: spin 1s linear infinite;
}
#BetaPortal .response.--left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 8px);
}
#BetaPortal .response p {
  white-space: nowrap;
  font-size: 14px;
  margin-left: 4px;
  color: palegreen;
}
#BetaPortal .beta-button {
  font-size: 15px;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  color: #1e2a43;
  opacity: 0.33;
  cursor: default;
}
#BetaPortal .beta-button.ready {
  opacity: 1;
  cursor: pointer;
}
#BetaPortal .beta-button.small {
  font-size: 13px;
  padding: 8px;
}
#BetaPortal textarea {
  display: block;
  box-sizing: border-box;
  height: 250px;
  min-height: 250px;
  font-family: "Fira Code", monospace;
  font-size: 14.5px;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: darkgrey;
  resize: vertical;
}
#BetaPortal #thank-you {
  top: 0;
  transform: translate(-50%, -100%);
  animation: dip 1s ease-out forwards;
  font-family: "pixel";
  font-size: 1.5rem;
  margin: 0;
  padding: 1.25rem;
  border-radius: 1.25rem;
  color: #1e2a43;
  background: rgba(64, 224, 208, 0.9);
}
#BetaPortal #PatchNotes {
  height: 100%;
  box-sizing: border-box;
  padding: 5rem 2rem 1rem 2rem;
  background: black;
}
#BetaPortal #PatchNotes p {
  color: color-mix(in srgb, white 66%, #1e2a43);
}
#BetaPortal .content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}
#BetaPortal .content #project_id {
  font-family: "pixel" !important;
  font-size: 3rem;
}
#BetaPortal .content #love p {
  white-space: nowrap;
}
#BetaPortal .content #love #heart {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
#BetaPortal .content #love #heart.loved {
  animation: heartbeat 1s ease-in-out 2;
}
#BetaPortal .content #love #heart svg {
  height: 5rem;
  fill: red;
}
#BetaPortal .content .feedback-handles {
  bottom: 2rem;
  text-align: center;
}
#BetaPortal .content .bugs-meta {
  margin-top: 2rem;
  padding: 0 2rem;
  color: color-mix(in srgb, white 66%, #1e2a43);
}
#BetaPortal .content .bugs-meta #patch-notes {
  width: -moz-fit-content;
  width: fit-content;
}
#BetaPortal .content textarea {
  background: rgba(255, 255, 255, 0.0588235294);
  padding: 2rem;
  border-radius: 2rem;
  color: ghostwhite;
}
#BetaPortal .content textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.34);
}
#BetaPortal .content textarea::placeholder {
  color: rgba(255, 255, 255, 0.34);
}
#BetaPortal .content p.submit {
  position: relative;
  font-size: 15px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 8px auto 0 auto;
  color: white;
  opacity: 0.3;
  cursor: pointer;
}
#BetaPortal .content p.submit:hover {
  opacity: 0.6;
}
#BetaPortal .content #Feedback_Suggestions {
  padding-bottom: 1rem;
}
#BetaPortal .content #Feedback_Suggestions .handleSection {
  color: cornflowerblue;
}
#BetaPortal .content #Report_Bugs #-report-bugs {
  position: absolute;
  right: 2rem;
  top: 0;
}
#BetaPortal .content #Report_Bugs #-report-bugs .report-bugs {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  padding: 8px;
  margin: 0;
  border-radius: 8px;
  background: yellow;
  cursor: pointer;
}
#BetaPortal .content #Report_Bugs #-report-bugs .report-bugs p {
  margin-top: 0;
  margin-bottom: 0;
}
#BetaPortal .content #Report_Bugs .beta-button {
  background: red;
}
#BetaPortal .content #Report_Bugs #caught-bugs {
  margin: 1.5rem 0 2.5rem 1rem;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug:hover .-trash- {
  visibility: visible;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .caught-bug {
  padding: 0 0.5rem;
  border-radius: 8px;
  cursor: pointer;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .caught-bug svg {
  height: 18px;
  color: inherit;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .caught-bug p {
  white-space: nowrap;
  max-width: 300px;
  color: color-mix(in srgb, white 66%, #1e2a43);
  margin: 0.5em 0 0.5em 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .-trash- {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  padding-left: 8px;
  visibility: hidden;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .-trash- .-trash {
  position: relative;
  cursor: pointer;
}
#BetaPortal .content #Report_Bugs #caught-bugs .-caught-bug .-trash- .-trash svg {
  height: 18px;
  color: rgba(255, 255, 255, 0.34);
}
#BetaPortal .content #Report_Bugs p.submit {
  opacity: 0.6;
  color: #ff7c7c;
}
#BetaPortal .content #Report_Bugs p.submit:hover {
  opacity: 1;
}

@media (orientation: landscape) {
  #BetaLanding .content .section .section-body {
    padding-left: 8px;
  }
  #BetaPortal .wrapper {
    width: 600px;
    margin: 0 auto;
    padding-top: 4rem;
  }
  #BetaPortal .wrapper .content .section .section-start {
    padding: 0 2rem;
    cursor: pointer;
  }
  #BetaPortal .wrapper .content .section .section-body {
    padding: 0 2rem;
  }
  #BetaPortal .wrapper .content #project_id {
    margin-bottom: 4rem;
  }
  #BetaPortal .wrapper .content #Rate .-ratings {
    padding: 1rem 3rem;
  }
  #BetaPortal .wrapper .content #Report_Bugs #-textarea-bugs #bug-prompt {
    background: #1e2a43;
  }
}
@media (orientation: portrait) {
  .beta-y-scroll::-webkit-scrollbar {
    width: 4px;
  }
  .beta-y-scroll::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
  #BetaLanding {
    width: 100vw;
    overflow-x: hidden;
  }
  #BetaLanding .content {
    width: 100vw;
    box-sizing: border-box;
    padding: 2rem;
  }
  #BetaLanding .content .section#announcement p:not(.section-h) {
    font-size: 15px;
  }
  #BetaLanding .content .meta p {
    font-size: 18px;
  }
  #BetaLanding .content #devices-rating .-device svg {
    height: 21px;
  }
  #BetaLanding .content #devices-rating .-device .p-rating {
    font-size: 18px;
  }
  #BetaLanding .content .user-cred p#tester {
    font-size: 18px;
  }
  #BetaLanding .content #continue {
    position: fixed;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%);
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    margin: 0;
    border-radius: 999px;
    background: white;
    z-index: 999;
  }
  #BetaPortal {
    box-sizing: border-box;
    width: 100vw;
  }
  #BetaPortal .content {
    padding: 3rem 1rem 0 1rem;
  }
  #BetaPortal .content .meta #project_id {
    font-size: 1.5rem;
  }
  #BetaPortal .content .meta #devices-rating {
    margin: 1rem 0 3rem 0;
  }
  #BetaPortal .content .meta #devices-rating > div .-device-svg svg {
    height: 21px;
  }
  #BetaPortal .content .meta #devices-rating > div .p-rating {
    font-size: 18px;
  }
  #BetaPortal .content .section {
    margin-top: 4rem;
  }
  #BetaPortal .content #love p {
    font-size: 18px;
  }
  #BetaPortal .content #love #heart svg {
    height: 7rem;
  }
  #BetaPortal .content .feedback-handles p {
    font-size: 18px;
  }
  #BetaPortal .content #Report_Bugs #handleReportBugs {
    margin: 3rem auto 1rem auto;
  }
  #BetaPortal .content .-feedback textarea {
    height: 33vh;
  }
}/*# sourceMappingURL=beta.css.map */