$logo-width: 2.5rem // handle

$bottom: calc( $logo-width * -1 )
	

@keyframes beyond
	0%
		bottom: 0

	100%
		bottom: 100%
@keyframes up
	0%
		bottom: $bottom

	100%
		bottom: 0


#Circle, #logo-negative
	position: relative
	min-width: $logo-width
	width: $logo-width
	min-height: $logo-width
	height: $logo-width
	// border-radius: 50%
	// background: gold
	// overflow: hidden

	&.init .-arrow
		animation: up 1s ease .5s forwards

	&.flip
		transform: scaleX(-1)

		.-arrow
			animation: beyond .6s ease-in forwards

	.-arrow
		// border: 1px solid red
		bottom: $bottom
		animation: up 1s ease forwards

		svg
			width: $logo-width
			fill: gold




@media (orientation: portrait)
	
	$logo-width: 2rem
	
	#Circle
		min-width: $logo-width
		width: $logo-width
		min-height: $logo-width
		height: $logo-width

		.-arrow svg
			width: $logo-width