@keyframes beyond {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 100%;
  }
}
@keyframes up {
  0% {
    bottom: -2.5rem;
  }
  100% {
    bottom: 0;
  }
}
#Circle, #logo-negative {
  position: relative;
  min-width: 2.5rem;
  width: 2.5rem;
  min-height: 2.5rem;
  height: 2.5rem;
}
#Circle.init .-arrow, #logo-negative.init .-arrow {
  animation: up 1s ease 0.5s forwards;
}
#Circle.flip, #logo-negative.flip {
  transform: scaleX(-1);
}
#Circle.flip .-arrow, #logo-negative.flip .-arrow {
  animation: beyond 0.6s ease-in forwards;
}
#Circle .-arrow, #logo-negative .-arrow {
  bottom: -2.5rem;
  animation: up 1s ease forwards;
}
#Circle .-arrow svg, #logo-negative .-arrow svg {
  width: 2.5rem;
  fill: gold;
}

@media (orientation: portrait) {
  #Circle {
    min-width: 2rem;
    width: 2rem;
    min-height: 2rem;
    height: 2rem;
  }
  #Circle .-arrow svg {
    width: 2rem;
  }
}/*# sourceMappingURL=logo.css.map */